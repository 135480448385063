import { some, value } from "../fable-library-js.4.22.0/Option.js";

export function tuple(a, b) {
    let matchResult, a_1, b_1;
    if (a != null) {
        if (b != null) {
            matchResult = 0;
            a_1 = value(a);
            b_1 = value(b);
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return [a_1, b_1];
        default:
            return undefined;
    }
}

export function ofFunc(f, arg) {
    try {
        return some(f(arg));
    }
    catch (matchValue) {
        return undefined;
    }
}

