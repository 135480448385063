import { Record, Union } from "../../fable_modules/fable-library-js.4.22.0/Types.js";
import { int64_type, class_type, record_type, list_type, string_type, bool_type, option_type, union_type } from "../../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { empty, cons, ofArray, singleton, forAll } from "../../fable_modules/fable-library-js.4.22.0/List.js";
import { fromInt32, toInt64, compare } from "../../fable_modules/fable-library-js.4.22.0/BigInt.js";
import * as flex_form from "../../../src/javascript/flex-form.js";
import { Cmd_none, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { some } from "../../fable_modules/fable-library-js.4.22.0/Option.js";
import { singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.22.0/AsyncBuilder.js";
import { FlexFormBase$1__Render_2B595, FlexFormBase$1__SetState, FlexForm$1_$ctor_2ABDDB55, setProfileId } from "./FlexForm.js";
import { Session__GetCurrentProfileId } from "../SharedView.js";
import { exnToDisplayString } from "../Utils.js";
import { Router_modifyLocation } from "../Router.js";
import { div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { singleton as singleton_2, append, delay, toList } from "../../fable_modules/fable-library-js.4.22.0/Seq.js";
import { Class, closeable } from "./Notification.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.22.0/Util.js";
import { container } from "../../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { h1 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { createElement } from "react";

export class DataExpected extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Record", "EditHelper"];
    }
}

export function DataExpected_$reflection() {
    return union_type("Omnicv.Client.Common.Components.AddEditSimpleFlexFormBase.DataExpected", [], DataExpected, () => [[], []]);
}

export class Model$2 extends Record {
    constructor(Record, EditHelperData, InProgress, Error$, DataExpected) {
        super();
        this.Record = Record;
        this.EditHelperData = EditHelperData;
        this.InProgress = InProgress;
        this.Error = Error$;
        this.DataExpected = DataExpected;
    }
}

export function Model$2_$reflection(gen0, gen1) {
    return record_type("Omnicv.Client.Common.Components.AddEditSimpleFlexFormBase.Model`2", [gen0, gen1], Model$2, () => [["Record", gen0], ["EditHelperData", option_type(gen1)], ["InProgress", bool_type], ["Error", option_type(string_type)], ["DataExpected", list_type(DataExpected_$reflection())]]);
}

function Model$2__IsItemReady_Z4CD6E166(this$, dataExpectedItem) {
    if (dataExpectedItem.tag === 1) {
        return this$.EditHelperData != null;
    }
    else {
        return Model$2__IsEditing(this$);
    }
}

export function Model$2__IsReady(this$) {
    return forAll((dataExpectedItem) => Model$2__IsItemReady_Z4CD6E166(this$, dataExpectedItem), this$.DataExpected);
}

export function Model$2__IsEditing(this$) {
    return compare(flex_form.getId(this$.Record), toInt64(fromInt32(0))) > 0;
}

export class Msg$2 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RecordUpdated", "FetchSuccess", "FetchEditHelperDataSuccess", "Failure", "Submit", "SubmitSuccess"];
    }
}

export function Msg$2_$reflection(gen0, gen1) {
    return union_type("Omnicv.Client.Common.Components.AddEditSimpleFlexFormBase.Msg`2", [gen0, gen1], Msg$2, () => [[["Item", gen0]], [["Item", gen0]], [["Item", gen1]], [["Item", class_type("System.Exception")]], [], [["Item", int64_type]]]);
}

export class SimpleFormView$2 {
    constructor(buildForm, title, submitNewAction, submitEditAction, successAction, mainView) {
        this.buildForm = buildForm;
        this.title = title;
        this.submitNewAction = submitNewAction;
        this.submitEditAction = submitEditAction;
        this.successAction = successAction;
        this.mainView = mainView;
    }
}

export function SimpleFormView$2_$reflection(gen0, gen1) {
    return class_type("Omnicv.Client.Common.Components.AddEditSimpleFlexFormBase.SimpleFormView`2", [gen0, gen1], SimpleFormView$2);
}

export function SimpleFormView$2_$ctor_68D4BBB0(buildForm, title, submitNewAction, submitEditAction, successAction, mainView) {
    return new SimpleFormView$2(buildForm, title, submitNewAction, submitEditAction, successAction, mainView);
}

export function SimpleFormView$2__InitNewCore_Z292EED19(this$, defaultValue, fetchAction) {
    const patternInput = SimpleFormView$2__initCommon(this$, undefined, defaultValue, singleton(new DataExpected(1, [])));
    const newModel = patternInput[0];
    return [new Model$2(newModel.Record, newModel.EditHelperData, true, newModel.Error, newModel.DataExpected), Cmd_batch(ofArray([patternInput[1], Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, fetchAction, undefined, (Item) => (new Msg$2(2, [Item])), (Item_1) => (new Msg$2(3, [Item_1])))]))];
}

export function SimpleFormView$2__InitEditCore_Z466B74A6(this$, recordId, defaultValue, fetchAction) {
    const patternInput = SimpleFormView$2__initCommon(this$, recordId, defaultValue, singleton(new DataExpected(0, [])));
    const newModel = patternInput[0];
    return [new Model$2(newModel.Record, newModel.EditHelperData, true, newModel.Error, newModel.DataExpected), Cmd_batch(ofArray([patternInput[1], Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, fetchAction, recordId, (Item) => (new Msg$2(1, [Item])), (Item_1) => (new Msg$2(3, [Item_1])))]))];
}

export function SimpleFormView$2__InitEditWithHelperData_3AAD8C48(this$, recordId, defaultValue, fetchAction, fetchHelperDataAction) {
    const patternInput = SimpleFormView$2__InitEditCore_Z466B74A6(this$, recordId, defaultValue, fetchAction);
    const newModel = patternInput[0];
    return [new Model$2(newModel.Record, newModel.EditHelperData, true, newModel.Error, cons(new DataExpected(1, []), newModel.DataExpected)), Cmd_batch(ofArray([patternInput[1], Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, fetchHelperDataAction, undefined, (Item) => (new Msg$2(2, [Item])), (Item_1) => (new Msg$2(3, [Item_1])))]))];
}

export function SimpleFormView$2__Update(this$, session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Model$2(msg.fields[0], model.EditHelperData, false, model.Error, model.DataExpected), Cmd_none()];
        case 2:
            return [new Model$2(model.Record, some(msg.fields[0]), false, model.Error, model.DataExpected), Cmd_none()];
        case 4:
            return [new Model$2(model.Record, model.EditHelperData, true, model.Error, model.DataExpected), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (record_1) => {
                if (Model$2__IsEditing(model)) {
                    return singleton_1.Delay(() => singleton_1.Bind(this$.submitEditAction(record_1), () => singleton_1.Return(flex_form.getId(record_1))));
                }
                else {
                    return this$.submitNewAction(setProfileId(record_1, Session__GetCurrentProfileId(session)));
                }
            }, model.Record, (Item) => (new Msg$2(5, [Item])), (Item_1) => (new Msg$2(3, [Item_1])))];
        case 5:
            return [new Model$2(model.Record, model.EditHelperData, false, model.Error, model.DataExpected), this$.successAction()];
        case 3:
            return [new Model$2(model.Record, model.EditHelperData, false, exnToDisplayString(msg.fields[0]), model.DataExpected), Cmd_none()];
        default:
            return [new Model$2(msg.fields[0], model.EditHelperData, model.InProgress, model.Error, model.DataExpected), Cmd_none()];
    }
}

export function SimpleFormView$2__ViewForm_Z44A2099C(this$, session, model, dispatch) {
    let form_1;
    const form = FlexForm$1_$ctor_2ABDDB55((arg) => {
        dispatch(new Msg$2(0, [arg]));
    }, (_arg) => {
        dispatch(new Msg$2(4, []));
    }, () => {
        Router_modifyLocation(this$.mainView);
    });
    this$.buildForm(form, session, model, dispatch);
    FlexFormBase$1__SetState(form, model.InProgress, model.Error);
    form_1 = form;
    return div(empty(), toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_2(closeable(`Error: ${matchValue}`, new Class(4, []))) : singleton_2(defaultOf())), delay(() => append(singleton_2(container(empty(), singleton(h1(empty())(singleton(this$.title))))), delay(() => (!Model$2__IsReady(model) ? singleton_2(createElement("p", {
            children: "Loading...",
        })) : singleton_2(FlexFormBase$1__Render_2B595(form_1, model.Record)))))));
    })));
}

export function SimpleFormView$2__initCommon(this$, id, defaultValue, dataExpected) {
    return [new Model$2(defaultValue, undefined, id != null, undefined, dataExpected), Cmd_none()];
}

