import { Union } from "../../../fable-library-js.4.22.0/Types.js";
import { list_type, lambda_type, unit_type, class_type, bool_type, string_type, union_type } from "../../../fable-library-js.4.22.0/Reflection.js";
import { Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddProp_ZCC5A61F, Color_ofColor, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_Z2B8EEFE, Modifier_IModifier_$reflection, Color_IColor_$reflection, Size_ISize_$reflection } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library-js.4.22.0/MapUtil.js";
import { Reflection_getCaseName } from "../../Common.fs.js";
import { DOMAttr, Prop, HTMLAttr } from "../../../Fable.React.9.4.0/Fable.React.Props.fs.js";
import { equals } from "../../../fable-library-js.4.22.0/Util.js";

export class IInputType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Text", "Password", "DatetimeLocal", "Date", "Month", "Time", "Week", "Number", "Email", "Url", "Search", "Tel", "ColorType"];
    }
}

export function IInputType_$reflection() {
    return union_type("Fulma.Input.IInputType", [], IInputType, () => [[], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Size", "Type", "Color", "Id", "Disabled", "IsReadOnly", "is-static", "is-rounded", "Value", "Key", "DefaultValue", "ValueOrDefault", "Placeholder", "OnChange", "Ref", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Input.Option", [], Option, () => [[["Item", Size_ISize_$reflection()]], [["Item", IInputType_$reflection()]], [["Item", Color_IColor_$reflection()]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.Event", undefined), unit_type)]], [["Item", lambda_type(class_type("Browser.Types.Element", undefined), unit_type)]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

function ofType(typ) {
    switch (typ.tag) {
        case 1:
            return "password";
        case 2:
            return "datetime-local";
        case 3:
            return "date";
        case 4:
            return "month";
        case 5:
            return "time";
        case 6:
            return "week";
        case 7:
            return "number";
        case 8:
            return "email";
        case 9:
            return "url";
        case 10:
            return "search";
        case 11:
            return "tel";
        case 12:
            return "color";
        default:
            return "text";
    }
}

/**
 * Generate <input class="input" />
 */
export function input(options) {
    return Common_GenericOptions__ToReactElement_Z2B8EEFE(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 7:
                return Common_GenericOptions__AddCaseName_1505(result, option);
            case 0:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            case 1:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(159, [ofType(option.fields[0])]));
            case 3:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(99, [option.fields[0]]));
            case 4:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(79, [option.fields[0]]));
            case 5:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(132, [option.fields[0]]));
            case 8:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(161, [option.fields[0]]));
            case 10:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(1, [option.fields[0]]));
            case 11: {
                const valueOrDefault = option.fields[0];
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new Prop(1, [(e) => {
                    if (!(e == null) && !equals(e.value, valueOrDefault)) {
                        e.value = valueOrDefault;
                    }
                }]));
            }
            case 12:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new HTMLAttr(128, [option.fields[0]]));
            case 13:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new DOMAttr(9, [option.fields[0]]));
            case 14:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new Prop(1, [option.fields[0]]));
            case 15:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 16:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 17:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            case 9:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new Prop(0, [option.fields[0]]));
            default:
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
        }
    }, "input"), (props_1) => react.createElement("input", keyValueList(props_1, 1)));
}

