import { Union } from "../../fable-library-js.4.22.0/Types.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library-js.4.22.0/Reflection.js";
import { Common_parseOptions, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.22.0/MapUtil.js";

export class Level_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Props", "is-mobile", "CustomClass", "Modifiers"];
    }
}

export function Level_Option_$reflection() {
    return union_type("Fulma.Level.Level.Option", [], Level_Option, () => [[["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

export class Item_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Props", "has-text-centered", "CustomClass", "Modifiers"];
    }
}

export function Item_Option_$reflection() {
    return union_type("Fulma.Level.Item.Option", [], Item_Option, () => [[["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <nav class="level"></nav>
 */
export function level(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 0:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "level"), (props_1, children_1) => react.createElement("nav", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <div class="level-left"></div>
 */
export function left(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "level-left"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="level-right"></div>
 */
export function right(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "level-right"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="level-item"></div>
 */
export function item(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 0:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "level-item"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <p class="heading"></p>
 */
export function heading(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "heading"), (props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <p class="title"></p>
 */
export function title(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "title"), (props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), children);
}

