import { value, some } from "../fable_modules/fable-library-js.4.22.0/Option.js";
import * as common from "../../src/javascript/common.js";

export const isDevelopment = false;

/**
 * Logs error to the console during development
 */
export function Log_developmentError(error) {
    if (isDevelopment) {
        console.error(some(error));
    }
}

export const Config_placeLookupId = "placeLookupInput";

export function Config_variable(name) {
    return common.getConfigVariable(name);
}

function Config_demoUsers() {
    return Config_variable("DEMO_USER");
}

export function Config_releaseVersion() {
    return Config_variable("RELEASE_VERSION");
}

export function Config_isDemoUser(session) {
    const username = value(session.UserInfo).Name;
    return Config_demoUsers() === username;
}

export const Config_defaultDateForNow = "now";

export function Config_getDefaultDiaryDate(session) {
    if (Config_isDemoUser(session)) {
        const fromDate = Config_variable("DEMO_USER_RANGE_FROM");
        if (fromDate.length > 0) {
            return fromDate;
        }
        else {
            const toDate = Config_variable("DEMO_USER_RANGE_TO");
            if (toDate.length > 0) {
                return toDate;
            }
            else {
                return Config_defaultDateForNow;
            }
        }
    }
    else {
        return Config_defaultDateForNow;
    }
}

export function Config_getUserTimeZone(session) {
    if (Config_isDemoUser(session)) {
        return "UTC";
    }
    else {
        return common.getTimezoneName();
    }
}

