import { Fa_IconOption, Fa_i, Fa_stack } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { cons, filter, tryFind, head, find, ofArray, empty } from "../fable_modules/fable-library-js.4.22.0/List.js";
import { Record, Union } from "../fable_modules/fable-library-js.4.22.0/Types.js";
import { record_type, int32_type, option_type, bool_type, union_type } from "../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { Response_UserInfo, Response_ProfileInfo, Response_ProfileInfo__DisplayName, Response_UserInfo_$reflection } from "../Omnicv.Shared/API.js";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.22.0/String.js";
import { parse } from "../fable_modules/fable-library-js.4.22.0/Int32.js";
import { int32ToString } from "../fable_modules/fable-library-js.4.22.0/Util.js";
import { value as value_1, map } from "../fable_modules/fable-library-js.4.22.0/Option.js";

export function stack2FaIcons(first, second) {
    return Fa_stack(empty(), [Fa_i(ofArray([first, new Fa_IconOption(18, [])]), []), Fa_i(ofArray([second, new Fa_IconOption(17, []), new Fa_IconOption(4, [])]), [])]);
}

export class Icons extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Add", "Copy", "Delete", "Unlink", "Edit", "Reload", "ReloadSpinning", "Search", "Settings", "Login", "Logout", "Tick", "Details", "View", "Up", "Down", "Top", "Bottom", "Favourite", "Stop"];
    }
}

export function Icons_$reflection() {
    return union_type("Omnicv.Client.SharedView.Icons", [], Icons, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function Icons__ToIcon(this$) {
    switch (this$.tag) {
        case 1:
            return new Fa_IconOption(11, ["fas fa-copy"]);
        case 2:
            return new Fa_IconOption(11, ["fas fa-trash-alt"]);
        case 3:
            return new Fa_IconOption(11, ["fas fa-unlink"]);
        case 4:
            return new Fa_IconOption(11, ["fas fa-edit"]);
        case 5:
            return new Fa_IconOption(11, ["fas fa-sync-alt"]);
        case 6:
            return new Fa_IconOption(11, ["fas fa-spinner"]);
        case 7:
            return new Fa_IconOption(11, ["fas fa-search"]);
        case 8:
            return new Fa_IconOption(11, ["fas fa-cog"]);
        case 9:
            return new Fa_IconOption(11, ["fas fa-sign-in-alt"]);
        case 10:
            return new Fa_IconOption(11, ["fas fa-sign-out-alt"]);
        case 11:
            return new Fa_IconOption(11, ["fas fa-check"]);
        case 12:
            return new Fa_IconOption(11, ["fas fa-info"]);
        case 13:
            return new Fa_IconOption(11, ["fas fa-eye"]);
        case 14:
            return new Fa_IconOption(11, ["fas fa-arrow-up"]);
        case 15:
            return new Fa_IconOption(11, ["fas fa-arrow-down"]);
        case 16:
            return new Fa_IconOption(11, ["fas fa-arrow-alt-circle-up"]);
        case 17:
            return new Fa_IconOption(11, ["fas fa-arrow-alt-circle-down"]);
        case 18:
            return new Fa_IconOption(11, ["fas fa-bookmark"]);
        case 19:
            return new Fa_IconOption(11, ["fas fa-stop"]);
        default:
            return new Fa_IconOption(11, ["fas fa-plus"]);
    }
}

export class CompositeIcons extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["RemoveFavourite"];
    }
}

export function CompositeIcons_$reflection() {
    return union_type("Omnicv.Client.SharedView.CompositeIcons", [], CompositeIcons, () => [[]]);
}

export function CompositeIcons__ToElement(this$) {
    return stack2FaIcons(new Fa_IconOption(11, ["fas fa-bookmark"]), new Fa_IconOption(11, ["fas fa-tint-slash"]));
}

const lastProfileIdKey = "lastProfileId";

export class Session extends Record {
    constructor(LoggedIn, UserInfo, CurrentProfile) {
        super();
        this.LoggedIn = LoggedIn;
        this.UserInfo = UserInfo;
        this.CurrentProfile = CurrentProfile;
    }
}

export function Session_$reflection() {
    return record_type("Omnicv.Client.SharedView.Session", [], Session, () => [["LoggedIn", bool_type], ["UserInfo", option_type(Response_UserInfo_$reflection())], ["CurrentProfile", option_type(int32_type)]]);
}

export function Session_get_Default() {
    return new Session(false, undefined, undefined);
}

export function Session__GetCurrentName(this$) {
    const matchValue = this$.UserInfo;
    if (matchValue == null) {
        return "<error>";
    }
    else {
        const userInfo = matchValue;
        const profileId = Session__GetCurrentProfileId(this$) | 0;
        return Response_ProfileInfo__DisplayName(find((pr) => (pr.Id === profileId), userInfo.Profiles));
    }
}

export function Session__GetCurrentProfile(this$) {
    const matchValue = this$.UserInfo;
    if (matchValue == null) {
        return undefined;
    }
    else {
        const userInfo = matchValue;
        const matchValue_1 = this$.CurrentProfile;
        if (matchValue_1 == null) {
            return head(userInfo.Profiles);
        }
        else {
            const profileId = matchValue_1 | 0;
            return tryFind((p) => (p.Id === profileId), userInfo.Profiles);
        }
    }
}

export function Session__GetCurrentProfileId(this$) {
    const matchValue = Session__GetCurrentProfile(this$);
    if (matchValue == null) {
        const result = localStorage.getItem(lastProfileIdKey);
        if (isNullOrWhiteSpace(result)) {
            return -1;
        }
        else {
            return parse(result, 511, false, 32) | 0;
        }
    }
    else {
        return matchValue.Id | 0;
    }
}

export function Session__UpdateCurrentProvile(this$) {
    const matchValue = this$.UserInfo;
    if (matchValue == null) {
    }
    else {
        const userInfo = matchValue;
        localStorage.setItem(lastProfileIdKey, int32ToString(Session__GetCurrentProfileId(this$)));
    }
}

export function Session__GetActiveWorkoutRecord(this$) {
    const matchValue = Session__GetCurrentProfile(this$);
    if (matchValue == null) {
        return undefined;
    }
    else {
        return matchValue.ActiveWorkout;
    }
}

export function Session__GetActiveWorkoutId(this$) {
    return map((record) => record.Id, map((tuple) => tuple[1], Session__GetActiveWorkoutRecord(this$)));
}

export function Session__SetActiveWorkoutRecord_Z2D8A2414(this$, activeRecord) {
    let bind$0040;
    let newProfile;
    const matchValue = Session__GetCurrentProfile(this$);
    if (matchValue == null) {
        throw new Error("No current profile");
    }
    else {
        const profile = matchValue;
        newProfile = (new Response_ProfileInfo(profile.Id, profile.IsPrimary, profile.FullName, profile.DateOfBirth, profile.Gender, profile.Country_IsoCode, activeRecord));
    }
    const newProfiles = filter((profile_1) => (newProfile.Id === profile_1.Id), value_1(this$.UserInfo).Profiles);
    return new Session(this$.LoggedIn, (bind$0040 = value_1(this$.UserInfo), new Response_UserInfo(bind$0040.Id, bind$0040.Name, bind$0040.Email, bind$0040.ReadOnly, cons(newProfile, newProfiles), bind$0040.SiteSettings, bind$0040.ServerVersion, bind$0040.ClientConfigVariables)), this$.CurrentProfile);
}

export function Session__GetDisplayCurrency(this$) {
    return "USD";
}

export function Session__SiteSettings(this$) {
    return value_1(this$.UserInfo).SiteSettings;
}

