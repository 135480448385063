import { FSharpRef, Union } from "../fable-library-js.4.22.0/Types.js";
import { union_type, class_type } from "../fable-library-js.4.22.0/Reflection.js";
import { ofArray, singleton } from "../fable-library-js.4.22.0/List.js";
import { Cmd_map } from "../Fable.Elmish.4.2.0/cmd.fs.js";
import { ProgramModule_map } from "../Fable.Elmish.4.2.0/program.fs.js";
import { Sub_map, Sub_batch } from "../Fable.Elmish.4.2.0/sub.fs.js";

export class Navigable$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Change", "UserMsg"];
    }
}

export function Navigable$1_$reflection(gen0) {
    return union_type("Elmish.Navigation.Navigable`1", [gen0], Navigable$1, () => [[["Item", class_type("Browser.Types.Location", undefined)]], [["Item", gen0]]]);
}

/**
 * Modify current location
 */
export function Navigation_modifyUrl(newUrl) {
    return singleton((_arg) => {
        history.replaceState(undefined, "", newUrl);
    });
}

/**
 * Push new location into history and navigate there
 */
export function Navigation_newUrl(newUrl) {
    return singleton((_arg) => {
        history.pushState(undefined, "", newUrl);
        const ev = new CustomEvent("NavigatedEvent");
        window.dispatchEvent(ev);
    });
}

/**
 * Jump to some point in history (positve=forward, nagative=backward)
 */
export function Navigation_jump(n) {
    return singleton((_arg) => {
        history.go(n);
    });
}

/**
 * Add the navigation to a program made with `mkProgram` or `mkSimple`.
 * urlUpdate: similar to `update` function, but receives parsed url instead of message as an input.
 */
export function ProgramModule_toNavigable(parser, urlUpdate, program) {
    const onChangeRef = new FSharpRef((_arg) => {
        throw new Error("`onChangeRef` has not been initialized.\nThis should not happen, please open an issue if the problem persist");
    });
    const map = (tupledArg) => [tupledArg[0], Cmd_map((Item) => (new Navigable$1(1, [Item])), tupledArg[1])];
    return ProgramModule_map((userInit, unitVar_1) => map(userInit(parser(window.location))), (userUpdate, msg, model_1) => map((msg.tag === 1) ? userUpdate(msg.fields[0])(model_1) : urlUpdate(parser(msg.fields[0]), model_1)), (userView, model_4, dispatch_2) => userView(model_4)((arg_1) => {
        dispatch_2(new Navigable$1(1, [arg_1]));
    }), (userSetState, model_3, dispatch_1) => {
        userSetState(model_3)((arg) => {
            dispatch_1(new Navigable$1(1, [arg]));
        });
    }, (userSubscribe, model_2) => Sub_batch(ofArray([singleton([singleton("onLocationChange"), (dispatch) => {
        let lastLocation = undefined;
        onChangeRef.contents = ((_arg_1) => {
            let matchResult_1, href_1;
            if (lastLocation != null) {
                if (lastLocation === window.location.href) {
                    matchResult_1 = 0;
                    href_1 = lastLocation;
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0: {
                    break;
                }
                case 1: {
                    lastLocation = window.location.href;
                    dispatch(new Navigable$1(0, [window.location]));
                    break;
                }
            }
        });
        window.addEventListener("popstate", onChangeRef.contents);
        window.addEventListener("hashchange", onChangeRef.contents);
        window.addEventListener("NavigatedEvent", onChangeRef.contents);
        return {
            Dispose() {
                window.removeEventListener("popstate", onChangeRef.contents);
                window.removeEventListener("hashchange", onChangeRef.contents);
                window.removeEventListener("NavigatedEvent", onChangeRef.contents);
            },
        };
    }]), Sub_map("user", (Item_1) => (new Navigable$1(1, [Item_1])), userSubscribe(model_2))])), (tupledArg_1) => [(_arg_2) => {
        if (_arg_2.tag === 1) {
            return tupledArg_1[0](_arg_2.fields[0]);
        }
        else {
            return false;
        }
    }, (model_5) => {
        window.removeEventListener("popstate", onChangeRef.contents);
        window.removeEventListener("hashchange", onChangeRef.contents);
        window.removeEventListener("NavigatedEvent", onChangeRef.contents);
        tupledArg_1[1](model_5);
    }], program);
}

