import { Union, Record } from "../fable_modules/fable-library-js.4.22.0/Types.js";
import { ReminderRecord_$reflection } from "../modules/Omnicv.Diary.Shared/Data.js";
import { union_type, unit_type, int64_type, class_type, record_type, bool_type, option_type, string_type, list_type } from "../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { map, ofArray, singleton, empty } from "../fable_modules/fable-library-js.4.22.0/List.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createObj, curry3, curry2 } from "../fable_modules/fable-library-js.4.22.0/Util.js";
import { reminderService } from "../modules/Omnicv.Diary.Client/Server.js";
import { Session__GetCurrentProfileId } from "../Omnicv.Client.Common/SharedView.js";
import { addDays } from "../fable_modules/fable-library-js.4.22.0/Date.js";
import * as common from "../../src/javascript/common.js";
import { Config_variable, Config_getUserTimeZone } from "../Omnicv.Client.Common/Extensions.js";
import { Registry$1__get_Modules } from "../Omnicv.Shared/ModulesRegistration.js";
import { clientModuleRegistry } from "../ClientRegistration.js";
import { body, hero } from "../fable_modules/Fulma.3.0.0/Layouts/Hero.fs.js";
import { collect, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.22.0/Seq.js";
import { box$0027 } from "../fable_modules/Fulma.3.0.0/Elements/Box.fs.js";
import { Option, h2, h1 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { Class, closeable } from "../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.22.0/Util.js";
import { container } from "../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { displayReminders } from "../modules/Omnicv.Diary.Client/DiaryClientUtils.js";
import { createElement } from "react";
import * as react from "react";
import { isNullOrWhiteSpace } from "../fable_modules/fable-library-js.4.22.0/String.js";
import { content as content_2, Header_title, Header_icon, header, card } from "../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { content as content_3 } from "../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import { getModuleNewNowButtons, IconType__ToIcon } from "../Omnicv.Client.Common/ClientModule.js";
import { Router_href } from "../Omnicv.Client.Common/Router.js";
import { value as value_5 } from "../fable_modules/fable-library-js.4.22.0/Option.js";
import { Markdown_ToHtml_1BFEAEDB } from "../fable_modules/Fable.Formatting.Markdown.1.0.1/Markdown.fs.js";

export class Model extends Record {
    constructor(Reminders, Error$, InProgress) {
        super();
        this.Reminders = Reminders;
        this.Error = Error$;
        this.InProgress = InProgress;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.Index.Component.Model", [], Model, () => [["Reminders", list_type(ReminderRecord_$reflection())], ["Error", option_type(string_type)], ["InProgress", bool_type]]);
}

export function Model_Default() {
    return new Model(empty(), undefined, false);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestFetch", "RemindersFetchSucceeded", "FetchFailed", "MarkDismissed", "MarkDismissedSucceeded"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Index.Component.Msg", [], Msg, () => [[], [["Item", list_type(ReminderRecord_$reflection())]], [["Item", class_type("System.Exception")]], [["Item", int64_type]], [["Item", unit_type]]]);
}

export function init(session) {
    const model = Model_Default();
    if (session.LoggedIn) {
        return [model, singleton((dispatch) => {
            dispatch(new Msg(0, []));
        })];
    }
    else {
        return [model, Cmd_none()];
    }
}

function demoText(user, password) {
    return `
Welcome to OmniCV demo site! To access most of the functionality, please log in. You have two options:

<ul>
<li>Log in with your Google account <a href="#login">here</a>. Then you can use all the features of the demo</li>

<li>Log in as a demo user. The account is read only. It is populated with entries from the diary of the 17th century
English civil servant Samuel Pepys.  He rubbed shoulders with most of the important people of Britain of his time and
wrote about the events in his journal for ten years.  Feel free to browse his notes, that luckily survived to this day.
Use these credentials to log in:

  <ul>
  <li>User name: ${user}</li>
  <li>Password: ${password}</li>
  </ul>
</li>
</ul>

As always, feel free to <a href="#contact">get in touch</a> if you have any problems, questions, suggestions or general thoughts.
`;
}

export function update(session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new Model(msg.fields[0], model.Error, false), Cmd_none()];
        case 2:
            return [new Model(model.Reminders, exnToDisplayString(msg.fields[0]), false), Cmd_none()];
        case 3:
            return [new Model(model.Reminders, model.Error, true), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, curry2(reminderService.MarkReminderDismissed)(Session__GetCurrentProfileId(session)), msg.fields[0], () => (new Msg(4, [undefined])), (Item_3) => (new Msg(2, [Item_3])))];
        case 4:
            return [new Model(model.Reminders, model.Error, false), singleton((dispatch) => {
                dispatch(new Msg(0, []));
            })];
        default: {
            const profileId = Session__GetCurrentProfileId(session) | 0;
            const targetDate = addDays(common.getNow(), 2);
            const timezone = Config_getUserTimeZone(session);
            return [new Model(model.Reminders, model.Error, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry3(reminderService.GetReminders)(profileId)(targetDate), timezone, (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])))];
        }
    }
}

export function view(session, model, dispatch) {
    const modules = Registry$1__get_Modules(clientModuleRegistry);
    return hero(empty(), singleton(body(empty(), toList(delay(() => append(singleton_1(box$0027(empty(), ofArray([h1(empty())(singleton("OmniCV")), h2(singleton(new Option(6, [])))(singleton("Nulla dies sine linea"))]))), delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't fetch information: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => singleton_1(container(empty(), toList(delay(() => {
            let children;
            return append(singleton_1((children = displayReminders(model.Reminders, model.InProgress, (arg) => {
                dispatch(new Msg(3, [arg]));
            }), react.createElement("div", {}, ...children))), delay(() => {
                let demoPassword, text;
                const demoUser = Config_variable("DEMO_USER");
                return append(!isNullOrWhiteSpace(demoUser) ? ((demoPassword = Config_variable("DEMO_USER_PASSWORD"), (text = demoText(demoUser, demoPassword).trim(), singleton_1(card(empty(), ofArray([header(empty(), ofArray([Header_icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-film"])), []))), Header_title(empty(), singleton("Welcome to the demo site!"))])), content_2(empty(), singleton(content_3(empty(), singleton(createElement("div", {
                    dangerouslySetInnerHTML: {
                        __html: text,
                    },
                })))))])))))) : empty_1(), delay(() => collect((clientModule) => {
                    const descriptor = clientModule.Descriptor();
                    return singleton_1(card(empty(), ofArray([header(empty(), ofArray([Header_icon(empty(), singleton(IconType__ToIcon(descriptor.Icon))), Header_title(empty(), toList(delay(() => ((session.LoggedIn && (descriptor.View != null)) ? singleton_1(createElement("a", createObj(ofArray([["children", clientModule.Details.Title], Router_href(value_5(descriptor.View)(session))])))) : singleton_1(clientModule.Details.Title)))))])), header(empty(), singleton(Header_title(empty(), singleton(createElement("div", {
                        dangerouslySetInnerHTML: {
                            __html: Markdown_ToHtml_1BFEAEDB(clientModule.Details.Description),
                        },
                    }))))), content_2(empty(), session.LoggedIn ? singleton(getModuleNewNowButtons(clientModule, value_5(session.UserInfo).ReadOnly)) : map((des) => container(empty(), singleton(des.Caption)), clientModule.EntryDescriptors()))])));
                }, modules)));
            }));
        }))))));
    })))))));
}

