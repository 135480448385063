import * as common from "../../../src/javascript/common.js";
import { Union, Record } from "../../fable_modules/fable-library-js.4.22.0/Types.js";
import { unit_type, list_type, tuple_type, enum_type, int32_type, union_type, int64_type, bool_type, array_type, uint8_type, record_type, class_type, option_type, string_type, decimal_type } from "../../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { FinancialHierarchy__BuildMapTree_Z59A20369, EntryFilter, CashflowInfo__IsActive_7F9DDECF, AutomaticCashflow__IsActive_7F9DDECF, CashflowInfo, CashflowInfo_Default_7F9DDECF, AssetInfo, AssetInfo_Default_7F9DDECF, Group, Group_Default_7F9DDECF, AssetValueRecord, AssetValueRecord_Default, validateInstrument, validateIdentifier, validateInterestRate, validateCurrency, validateUnitPrice, needsUnitPrice, validateQuantity, validateName, validateDisplayCurrency, validateNotes, EntryFilter_get_Empty, CurrencyValue_$reflection, GroupNode_$reflection, FinancialHierarchy_$reflection, CashflowInfo_$reflection, AssetInfo_$reflection, Group_$reflection, EntryFilter_$reflection } from "../../Omnicv.Shared/FinancialAPI.js";
import { length, tryFind, isEmpty, cons, collect, append as append_1, singleton as singleton_1, empty, map, ofArray } from "../../fable_modules/fable-library-js.4.22.0/List.js";
import { empty as empty_2, tryFind as tryFind_1, FSharpMap__get_Item, ofList } from "../../fable_modules/fable-library-js.4.22.0/Map.js";
import { curry2, createObj, equals, int32ToString, comparePrimitives } from "../../fable_modules/fable-library-js.4.22.0/Util.js";
import { supportedCurrenciesForEditingOptions, supportedCurrenciesForEditing } from "../../Omnicv.Client.Common/CurrencyUtils.js";
import { defaultArg, map as map_1, value as value_6, defaultArgWith } from "../../fable_modules/fable-library-js.4.22.0/Option.js";
import { Icons, Session__GetCurrentProfileId, Session__GetDisplayCurrency } from "../../Omnicv.Client.Common/SharedView.js";
import { fromInt32, toInt64, compare } from "../../fable_modules/fable-library-js.4.22.0/BigInt.js";
import { FlexFormFields$1__AddTextOpt_Z44636648, FlexFormFields$1__AddDecimalOpt_Z2D27E1FA, noValidation, FlexFormFields$1__AddDateTime_2636DDCF, FlexFormFields$1__AddDecimal_7B82A45, FlexFormBase$1__Render_2B595, FlexFormBase$1__SetState, FlexForm$1__get_Fields, FlexFormFields$1__AddText_Z44011C05, FlexFormFields$1__AddSelection_Z70244BEA, FlexFormFields$1__AddTextAreaOpt_Z44636648, FlexForm$1_$ctor_2ABDDB55 } from "../../Omnicv.Client.Common/Components/FlexForm.js";
import { Option as Option_4, div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { container } from "../../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { h6, h5, h4, h3, Option, h2, h1 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { empty as empty_1, append, singleton, delay, toList } from "../../fable_modules/fable-library-js.4.22.0/Seq.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { assetValueService, financialService } from "./FinancialCommon.js";
import { Cmd_map, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { handleFileEvent, busyModal, valuesToSelectOptions, exnToDisplayString } from "../../Omnicv.Client.Common/Utils.js";
import { EventInfo, EventInfo_Default_7F9DDECF } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { singleton as singleton_2 } from "../../fable_modules/fable-library-js.4.22.0/AsyncBuilder.js";
import { get_Zero, get_One } from "../../fable_modules/fable-library-js.4.22.0/Decimal.js";
import { utcNow } from "../../fable_modules/fable-library-js.4.22.0/Date.js";
import { List_Option, list as list_3, Option as Option_1, button } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Size_ISize, Common_GenericOption, TextTransform_Option, Modifier_IModifier, Screen, Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { FSharpChoice$2 } from "../../fable_modules/fable-library-js.4.22.0/Choice.js";
import { Option as Option_6, icon } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../Omnicv.Client.Common/SharedView.js";
import { createElement } from "react";
import * as react from "react";
import { box$0027 } from "../../fable_modules/Fulma.3.0.0/Elements/Box.fs.js";
import { Fa_IconOption, Fa_i as Fa_i_1 } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.9.0/Interop.fs.js";
import { isNullOrWhiteSpace, replicate } from "../../fable_modules/fable-library-js.4.22.0/String.js";
import { Option as Option_2, columns } from "../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_3, ISize, column } from "../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { Route, Router_modifyLocation } from "../../Omnicv.Client.Common/Router.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.22.0/Util.js";
import { content } from "../../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import { HTMLAttr, DOMAttr } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { tab, Option as Option_7, tabs, Tab_Option } from "../../fable_modules/Fulma.3.0.0/Components/Tabs.fs.js";
import { input } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { Option as Option_5, IInputType } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Input.fs.js";
import { select } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Select.fs.js";
import { keyValueList } from "../../fable_modules/fable-library-js.4.22.0/MapUtil.js";
import { Option as Option_8, div as div_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Field.fs.js";
import { name as name_12, icon as icon_1, cta, input as input_1, Label_label, Option as Option_9, file as file_1 } from "../../fable_modules/Fulma.3.0.0/Elements/Form/File.fs.js";
import { Class, closeable } from "../../Omnicv.Client.Common/Components/Notification.js";

export function Omnicv_Shared_FinancialAPI_CurrencyValue__CurrencyValue_ToDisplayString(this$) {
    return common.formatCurrencyValue(this$.Currency, this$.Value);
}

export class AssetValueData extends Record {
    constructor(Quantity, UnitPrice, Notes, EventTimestamp) {
        super();
        this.Quantity = Quantity;
        this.UnitPrice = UnitPrice;
        this.Notes = Notes;
        this.EventTimestamp = EventTimestamp;
    }
}

export function AssetValueData_$reflection() {
    return record_type("Omnicv.Client.Pages.Financial.MainFinancialView.Component.AssetValueData", [], AssetValueData, () => [["Quantity", decimal_type], ["UnitPrice", decimal_type], ["Notes", option_type(string_type)], ["EventTimestamp", class_type("System.DateTime")]]);
}

export class FileUploadData extends Record {
    constructor(CurrentFileName, Data, InProgress, Error$) {
        super();
        this.CurrentFileName = CurrentFileName;
        this.Data = Data;
        this.InProgress = InProgress;
        this.Error = Error$;
    }
}

export function FileUploadData_$reflection() {
    return record_type("Omnicv.Client.Pages.Financial.MainFinancialView.Component.FileUploadData", [], FileUploadData, () => [["CurrentFileName", option_type(string_type)], ["Data", option_type(array_type(uint8_type))], ["InProgress", bool_type], ["Error", option_type(string_type)]]);
}

export function FileUploadData_Default() {
    return new FileUploadData(undefined, undefined, false, undefined);
}

export function FileUploadData__CanSubmit(this$) {
    if (this$.Data != null) {
        return !this$.InProgress;
    }
    else {
        return false;
    }
}

export class EditModel$1 extends Record {
    constructor(Data, IsLoading, Error$) {
        super();
        this.Data = Data;
        this.IsLoading = IsLoading;
        this.Error = Error$;
    }
}

export function EditModel$1_$reflection(gen0) {
    return record_type("Omnicv.Client.Pages.Financial.MainFinancialView.Component.EditModel`1", [gen0], EditModel$1, () => [["Data", gen0], ["IsLoading", bool_type], ["Error", option_type(string_type)]]);
}

function createEditModel(data) {
    return new EditModel$1(data, false, undefined);
}

export class EditMsg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RecordUpdated", "Submit", "SuccessAdd", "SuccessEdit", "Failure"];
    }
}

export function EditMsg$1_$reflection(gen0) {
    return union_type("Omnicv.Client.Pages.Financial.MainFinancialView.Component.EditMsg`1", [gen0], EditMsg$1, () => [[["Item", gen0]], [], [["Item", int64_type]], [["Item", gen0]], [["Item", class_type("System.Exception")]]]);
}

export class State extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DisplayAssets", "DisplayCashflows", "AddEditGroup", "AddEditAsset", "AddEditCashflow", "AddValue", "ChooseAdd", "ImportData"];
    }
}

export function State_$reflection() {
    return union_type("Omnicv.Client.Pages.Financial.MainFinancialView.Component.State", [], State, () => [[["Item", EntryFilter_$reflection()]], [["Item", EntryFilter_$reflection()]], [["Item", EditModel$1_$reflection(Group_$reflection())]], [["Item", EditModel$1_$reflection(AssetInfo_$reflection())]], [["Item", EditModel$1_$reflection(CashflowInfo_$reflection())]], [["Item1", AssetInfo_$reflection()], ["Item2", EditModel$1_$reflection(AssetValueData_$reflection())]], [["Item", Group_$reflection()]], [["Item", FileUploadData_$reflection()]]]);
}

export class Model extends Record {
    constructor(FinancialHierarchy, FinancialTree, State, InProgress, Error$, DisplayCurrency) {
        super();
        this.FinancialHierarchy = FinancialHierarchy;
        this.FinancialTree = FinancialTree;
        this.State = State;
        this.InProgress = InProgress;
        this.Error = Error$;
        this.DisplayCurrency = DisplayCurrency;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.Financial.MainFinancialView.Component.Model", [], Model, () => [["FinancialHierarchy", option_type(FinancialHierarchy_$reflection())], ["FinancialTree", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [enum_type("Omnicv.Shared.FinancialAPI.GroupType", int32_type, [["Assets", 1], ["Cashflows", 2]]), tuple_type(list_type(tuple_type(GroupNode_$reflection(), CurrencyValue_$reflection())), CurrencyValue_$reflection())])], ["State", State_$reflection()], ["InProgress", bool_type], ["Error", option_type(string_type)], ["DisplayCurrency", option_type(string_type)]]);
}

export function Model__GetCurrentFilters(this$) {
    const matchValue = this$.State;
    switch (matchValue.tag) {
        case 0:
            return matchValue.fields[0];
        case 1:
            return matchValue.fields[0];
        default:
            return EntryFilter_get_Empty();
    }
}

export class FileUploadMsg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StartLoadFile", "LoadSuccess", "UploadFile", "UploadSuccess", "Failure"];
    }
}

export function FileUploadMsg_$reflection() {
    return union_type("Omnicv.Client.Pages.Financial.MainFinancialView.Component.FileUploadMsg", [], FileUploadMsg, () => [[["Item", class_type("Browser.Types.Event", undefined)]], [["Item1", array_type(uint8_type)], ["Item2", string_type]], [["Item", array_type(uint8_type)]], [["Item", unit_type]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestHierarchy", "HierarchySuccess", "SetState", "AddSuccess", "Failure", "GroupMsg", "AssetMsg", "AssetValueMsg", "CashflowMsg", "FileUploadMsg", "UpdateDisplayCurrency"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Financial.MainFinancialView.Component.Msg", [], Msg, () => [[], [["Item", FinancialHierarchy_$reflection()]], [["Item", State_$reflection()]], [["Item", unit_type]], [["Item", class_type("System.Exception")]], [["Item", EditMsg$1_$reflection(Group_$reflection())]], [["Item", EditMsg$1_$reflection(AssetInfo_$reflection())]], [["Item", EditMsg$1_$reflection(AssetValueData_$reflection())]], [["Item", EditMsg$1_$reflection(CashflowInfo_$reflection())]], [["Item", FileUploadMsg_$reflection()]], [["Item", option_type(string_type)]]]);
}

const assetTypes = ofArray([[0, "Physical cash"], [1, "Account"], [2, "Financial instrument"], [7, "Real estate"], [9, "Loan"], [100, "Other debt"]]);

const assetTypesLookup = ofList(assetTypes, {
    Compare: comparePrimitives,
});

const cashflowTypes = ofArray([[1, "Income"], [2, "Expense"]]);

const cashflowTypesLookup = ofList(cashflowTypes, {
    Compare: comparePrimitives,
});

const frequencies = ofArray([[1, "Daily"], [2, "Weekly"], [3, "Monthly"], [4, "Quarterly"], [5, "Yearly"]]);

const optionalCurrencyChoices = map((x) => [x[0], x[1]], supportedCurrenciesForEditing);

const frequenciesLookup = ofList(frequencies, {
    Compare: comparePrimitives,
});

const frequenciesOption = map((tupledArg) => [int32ToString(tupledArg[0]), tupledArg[1]], frequencies);

function getDisplayCurrency(session, model) {
    return defaultArgWith(model.DisplayCurrency, () => Session__GetDisplayCurrency(session));
}

export function Groups_viewForAddEdit(model, dispatch) {
    const editing = compare(model.Data.Id, toInt64(fromInt32(0))) > 0;
    const form = FlexForm$1_$ctor_2ABDDB55((arg_1) => {
        dispatch(new Msg(5, [new EditMsg$1(0, [arg_1])]));
    }, (_arg) => {
        dispatch(new Msg(5, [new EditMsg$1(1, [])]));
    }, undefined);
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddSelection_Z70244BEA(FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Name", validateName, "Group name"), "DisplayCurrency", optionalCurrencyChoices, validateDisplayCurrency, "Display currency"), "Notes", validateNotes, "Notes");
    FlexFormBase$1__SetState(form, model.IsLoading, model.Error);
    return div(empty(), ofArray([container(empty(), singleton_1(h1(empty())(toList(delay(() => (editing ? singleton("Edit group") : singleton("Add group"))))))), FlexFormBase$1__Render_2B595(form, model.Data)]));
}

export function Groups_update(session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new EditModel$1(model.Data, true, model.Error), (compare(model.Data.Id, toInt64(fromInt32(0))) > 0) ? Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, financialService.EditGroup, model.Data, (Item) => (new EditMsg$1(3, [Item])), (Item_1) => (new EditMsg$1(4, [Item_1]))) : Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, financialService.AddGroup, model.Data, (Item_2) => (new EditMsg$1(2, [Item_2])), (Item_3) => (new EditMsg$1(4, [Item_3])))];
        case 2:
        case 3:
            return [new EditModel$1(model.Data, false, undefined), Cmd_none()];
        case 4:
            return [new EditModel$1(model.Data, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new EditModel$1(msg.fields[0], model.IsLoading, model.Error), Cmd_none()];
    }
}

export function Assets_viewAddValue(asset, model, dispatch) {
    const form = FlexForm$1_$ctor_2ABDDB55((arg_1) => {
        dispatch(new Msg(7, [new EditMsg$1(0, [arg_1])]));
    }, (_arg) => {
        dispatch(new Msg(7, [new EditMsg$1(1, [])]));
    }, undefined);
    FlexFormFields$1__AddDecimal_7B82A45(FlexForm$1__get_Fields(form), "Quantity", validateQuantity, "Quantity");
    if (needsUnitPrice(asset.AssetType)) {
        FlexFormFields$1__AddDecimal_7B82A45(FlexForm$1__get_Fields(form), "UnitPrice", validateUnitPrice, "Unit price");
    }
    FlexFormFields$1__AddDateTime_2636DDCF(FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexForm$1__get_Fields(form), "Notes", validateNotes, "Notes"), "EventTimestamp", noValidation, "Timestamp");
    FlexFormBase$1__SetState(form, model.IsLoading, model.Error);
    return div(empty(), ofArray([container(empty(), ofArray([h1(empty())(singleton_1("Add value to asset")), h2(singleton_1(new Option(6, [])))(singleton_1(asset.Name))])), FlexFormBase$1__Render_2B595(form, model.Data)]));
}

export function Assets_viewForAddEdit(model, dispatch) {
    const editing = compare(model.Data.Id, toInt64(fromInt32(0))) > 0;
    const form = FlexForm$1_$ctor_2ABDDB55((arg_1) => {
        dispatch(new Msg(6, [new EditMsg$1(0, [arg_1])]));
    }, (_arg) => {
        dispatch(new Msg(6, [new EditMsg$1(1, [])]));
    }, undefined);
    FlexFormFields$1__AddSelection_Z70244BEA(FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Name", validateName, "Name"), "Currency", supportedCurrenciesForEditing, validateCurrency, "Currency");
    FlexFormBase$1__SetState(form, model.IsLoading, model.Error);
    const matchValue = model.Data.AssetType | 0;
    switch (matchValue) {
        case 0:
        case 7: {
            FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexForm$1__get_Fields(form), "Notes", validateNotes, "Notes");
            break;
        }
        case 1:
        case 9:
        case 100: {
            FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddDecimalOpt_Z2D27E1FA(FlexFormFields$1__AddTextOpt_Z44636648(FlexForm$1__get_Fields(form), "Identifier", validateIdentifier, "Identifier"), "InterestRate", validateInterestRate, "Interest rate"), "Notes", validateNotes, "Notes");
            break;
        }
        case 2: {
            FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddTextOpt_Z44636648(FlexFormFields$1__AddTextOpt_Z44636648(FlexForm$1__get_Fields(form), "Identifier", validateIdentifier, "Identifier"), "Instrument", validateInstrument, "Instrument"), "Notes", validateNotes, "Notes");
            break;
        }
        default:
            throw new Error("Asset type not supported");
    }
    return div(empty(), ofArray([container(empty(), singleton_1(h1(empty())(toList(delay(() => (editing ? singleton("Edit asset") : singleton("Add asset"))))))), FlexFormBase$1__Render_2B595(form, model.Data)]));
}

export function Assets_update(session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new EditModel$1(model.Data, true, model.Error), (compare(model.Data.Id, toInt64(fromInt32(0))) > 0) ? Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, financialService.EditAsset, model.Data, (Item) => (new EditMsg$1(3, [Item])), (Item_1) => (new EditMsg$1(4, [Item_1]))) : Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, financialService.AddAsset, model.Data, (Item_2) => (new EditMsg$1(2, [Item_2])), (Item_3) => (new EditMsg$1(4, [Item_3])))];
        case 2:
        case 3:
            return [new EditModel$1(model.Data, false, undefined), Cmd_none()];
        case 4:
            return [new EditModel$1(model.Data, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new EditModel$1(msg.fields[0], model.IsLoading, model.Error), Cmd_none()];
    }
}

export function Assets_updateValue(session, msg, asset, model) {
    switch (msg.tag) {
        case 1: {
            let eventInfo;
            const bind$0040 = EventInfo_Default_7F9DDECF(model.Data.EventTimestamp);
            eventInfo = (new EventInfo(bind$0040.Id, bind$0040.ContentTypeId, Session__GetCurrentProfileId(session), bind$0040.EventTimestamp, bind$0040.CreatedAt, bind$0040.UpdatedAt, bind$0040.PlaceId, bind$0040.PlaceName, bind$0040.Latitude, bind$0040.Longitude, bind$0040.LocationTz, bind$0040.LocationTzOffsetMin, bind$0040.ClientTz, bind$0040.ClientTzOffsetMin));
            return [new EditModel$1(model.Data, true, model.Error), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (isLatest) => singleton_2.Delay(() => {
                const unitPrice = needsUnitPrice(asset.AssetType) ? model.Data.UnitPrice : get_One;
                const assetValueRecord = new AssetValueRecord(AssetValueRecord_Default().Id, asset.Id, model.Data.Quantity, unitPrice, model.Data.Notes);
                return singleton_2.Bind(assetValueService.Add(eventInfo, assetValueRecord, isLatest), (_arg) => singleton_2.Return(_arg.Id));
            }), true, (Item) => (new EditMsg$1(2, [Item])), (Item_1) => (new EditMsg$1(4, [Item_1])))];
        }
        case 2:
        case 3:
            return [new EditModel$1(model.Data, false, undefined), Cmd_none()];
        case 4:
            return [new EditModel$1(model.Data, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new EditModel$1(msg.fields[0], model.IsLoading, model.Error), Cmd_none()];
    }
}

export function Cashflows_viewForAddEdit(model, dispatch) {
    const form = FlexForm$1_$ctor_2ABDDB55((arg_1) => {
        dispatch(new Msg(8, [new EditMsg$1(0, [arg_1])]));
    }, (_arg) => {
        dispatch(new Msg(8, [new EditMsg$1(1, [])]));
    }, undefined);
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddDecimal_7B82A45(FlexFormFields$1__AddSelection_Z70244BEA(FlexFormFields$1__AddSelection_Z70244BEA(FlexFormFields$1__AddSelection_Z70244BEA(FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Name", validateName, "Cashflow name"), "CashflowType", cashflowTypes, noValidation, "Cashflow type"), "Frequency", frequencies, noValidation, "Frequency"), "Currency", supportedCurrenciesForEditing, validateCurrency, "Currency"), "Quantity", validateQuantity, "Quantity"), "Notes", validateNotes, "Notes");
    FlexFormBase$1__SetState(form, model.IsLoading, model.Error);
    return div(empty(), ofArray([container(empty(), singleton_1(h1(empty())(singleton_1("Add cashflow")))), FlexFormBase$1__Render_2B595(form, model.Data)]));
}

export function Cashflows_update(_session, msg, model) {
    switch (msg.tag) {
        case 1:
            return [new EditModel$1(model.Data, true, model.Error), (compare(model.Data.Id, toInt64(fromInt32(0))) > 0) ? Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, financialService.EditCashflow, model.Data, (Item) => (new EditMsg$1(3, [Item])), (Item_1) => (new EditMsg$1(4, [Item_1]))) : Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, financialService.AddCashflow, model.Data, (Item_2) => (new EditMsg$1(2, [Item_2])), (Item_3) => (new EditMsg$1(4, [Item_3])))];
        case 2:
        case 3:
            return [new EditModel$1(model.Data, false, undefined), Cmd_none()];
        case 4:
            return [new EditModel$1(model.Data, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new EditModel$1(msg.fields[0], model.IsLoading, model.Error), Cmd_none()];
    }
}

function DisplayHierarchy_addGroup(session, dispatch, parentOrType, _arg) {
    let newGroup;
    const start = Group_Default_7F9DDECF(utcNow());
    if (parentOrType.tag === 1) {
        newGroup = (new Group(start.Id, parentOrType.fields[0], start.ProfileId, start.ParentId, start.CreatedAt, start.UpdatedAt, start.Name, start.Notes, start.Weight, start.DisplayCurrency));
    }
    else {
        const parent = parentOrType.fields[0];
        newGroup = (new Group(start.Id, parent.GroupType, start.ProfileId, parent.Id, start.CreatedAt, start.UpdatedAt, start.Name, start.Notes, start.Weight, start.DisplayCurrency));
    }
    dispatch(new Msg(2, [new State(2, [createEditModel(new Group(newGroup.Id, newGroup.GroupType, Session__GetCurrentProfileId(session), newGroup.ParentId, newGroup.CreatedAt, newGroup.UpdatedAt, newGroup.Name, newGroup.Notes, newGroup.Weight, newGroup.DisplayCurrency))])]));
}

export function DisplayHierarchy_groupTypeToState(groupType, filter) {
    switch (groupType) {
        case 1:
            return new State(0, [filter]);
        case 2:
            return new State(1, [filter]);
        default:
            throw new Error(`Bad group type ${groupType}`);
    }
}

function DisplayHierarchy_viewByGroupType(targetGroupType, dispatch) {
    dispatch(new Msg(2, [DisplayHierarchy_groupTypeToState(targetGroupType, EntryFilter_get_Empty())]));
}

export function DisplayHierarchy_viewSelectAdd(session, group, dispatch) {
    let states;
    const matchValue = group.GroupType | 0;
    switch (matchValue) {
        case 1: {
            states = map((t) => {
                let patternInput;
                const tupledArg = t;
                const model = AssetInfo_Default_7F9DDECF(utcNow());
                patternInput = [createEditModel(new AssetInfo(model.Id, group.ProfileId, group.Id, tupledArg[0], model.CreatedAt, model.UpdatedAt, model.Name, model.Notes, model.Weight, model.Identifier, model.LatestAssetValueId, (group.DisplayCurrency == null) ? "" : value_6(group.DisplayCurrency), model.Instrument, model.InterestRate, model.ActiveFrom, model.ActiveTo)), tupledArg[1]];
                return [new State(3, [patternInput[0]]), patternInput[1]];
            }, assetTypes);
            break;
        }
        case 2: {
            states = map((t_1) => {
                let patternInput_1;
                const tupledArg_1 = t_1;
                const model_1 = CashflowInfo_Default_7F9DDECF(utcNow());
                patternInput_1 = [createEditModel(new CashflowInfo(model_1.Id, group.ProfileId, tupledArg_1[0], group.Id, model_1.CreatedAt, model_1.UpdatedAt, model_1.Name, model_1.Notes, model_1.Weight, (group.DisplayCurrency == null) ? "" : value_6(group.DisplayCurrency), model_1.Quantity, model_1.Frequency, model_1.AssetId, model_1.ActiveFrom, model_1.ActiveTo)), tupledArg_1[1]];
                return [new State(4, [patternInput_1[0]]), patternInput_1[1]];
            }, cashflowTypes);
            break;
        }
        default:
            throw new Error("Unsupported group type");
    }
    const children = toList(delay(() => {
        const coreButtons = singleton_1(button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(18, [(arg30$0040) => {
            DisplayHierarchy_addGroup(session, dispatch, new FSharpChoice$2(0, [group]), arg30$0040);
        }])]), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(0, []))), []))), createElement("span", {
            children: "Add group",
        })])));
        const extraButtons = map((tupledArg_2) => button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(5, []), new Option_1(18, [(_arg) => {
            dispatch(new Msg(2, [tupledArg_2[0]]));
        }])]), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(0, []))), []))), createElement("span", {
            children: `Add ${tupledArg_2[1]}`,
        })])), states);
        return append(singleton(box$0027(empty(), ofArray([h3(empty())(singleton_1("Choose what to add to group")), h4(singleton_1(new Option(6, [])))(singleton_1(group.Name))]))), delay(() => append(singleton(box$0027(empty(), singleton_1(list_3(singleton_1(new List_Option(1, [])), append_1(coreButtons, extraButtons))))), delay(() => singleton(button(ofArray([new Option_1(0, [new Color_IColor(7, [])]), new Option_1(18, [(_arg_1) => {
            DisplayHierarchy_viewByGroupType(group.GroupType, dispatch);
        }])]), ofArray([icon(empty(), singleton_1(Fa_i_1(singleton_1(new Fa_IconOption(11, ["fas fa-undo-alt"])), []))), createElement("span", {
            children: "Cancel",
        })])))))));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function DisplayHierarchy_getTotalsItem(totals, isActive) {
    return box$0027(empty(), singleton_1(h5(empty())(singleton_1(`Total: ${Omnicv_Shared_FinancialAPI_CurrencyValue__CurrencyValue_ToDisplayString(totals)}`))));
}

function DisplayHierarchy_depthToPrefix(depth) {
    if (depth > 0) {
        return replicate(depth, "-") + " ";
    }
    else {
        return "";
    }
}

function DisplayHierarchy_processAsset(depth, asset, value, dispatch) {
    return box$0027(empty(), ofArray([h6(empty())(singleton_1(`${DisplayHierarchy_depthToPrefix(depth)}${asset.Name}: ${Omnicv_Shared_FinancialAPI_CurrencyValue__CurrencyValue_ToDisplayString(value)}`)), columns(ofArray([new Option_2(3, []), new Option_2(4, []), new Option_2(1, [])]), ofArray([column(singleton_1(new Option_3(0, [new Screen(0, []), new ISize(21, [])])), toList(delay(() => {
        let children;
        return append((asset.Notes != null) ? singleton((children = singleton_1(value_6(asset.Notes)), createElement("p", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))) : empty_1(), delay(() => {
            let children_2;
            return append(singleton((children_2 = singleton_1(FSharpMap__get_Item(assetTypesLookup, asset.AssetType)), createElement("span", {
                children: reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => append(singleton(createElement("span", {
                children: reactApi.Children.toArray([" | Currency: "]),
            })), delay(() => singleton(asset.Currency)))));
        }));
    }))), column(empty(), singleton_1(list_3(singleton_1(new List_Option(2, [])), ofArray([button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(5, []), new Option_1(18, [(_arg) => {
        dispatch(new Msg(2, [new State(5, [asset, createEditModel(new AssetValueData(get_Zero, get_Zero, undefined, common.getNow()))])]));
    }])]), singleton_1(icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(0, []))), []))))), button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(5, []), new Option_1(18, [(_arg_1) => {
        Router_modifyLocation(new Route(30, [asset.Id]));
    }])]), singleton_1(icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(13, []))), []))))), button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(5, []), new Option_1(18, [(_arg_2) => {
        dispatch(new Msg(2, [new State(3, [createEditModel(asset)])]));
    }])]), singleton_1(icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(4, []))), [])))))]))))]))]));
}

function DisplayHierarchy_processCashflow(depth, cashflow, value, dispatch) {
    const now = utcNow();
    let patternInput;
    if (cashflow.tag === 1) {
        const auto = cashflow.fields[0];
        patternInput = [auto.Name, undefined, AutomaticCashflow__IsActive_7F9DDECF(auto, now), auto.CashflowType, auto.Frequency];
    }
    else {
        const cashflowInfo = cashflow.fields[0];
        patternInput = [cashflowInfo.Name, cashflowInfo.Notes, CashflowInfo__IsActive_7F9DDECF(cashflowInfo, now), cashflowInfo.CashflowType, cashflowInfo.Frequency];
    }
    const notes = patternInput[1];
    const cashflowType = patternInput[3] | 0;
    const modifiers = !patternInput[2] ? ofArray([new Modifier_IModifier(0, [new Color_IColor(14, [])]), new Modifier_IModifier(7, [new TextTransform_Option(3, [])])]) : ((cashflowType === 2) ? singleton_1(new Modifier_IModifier(1, [new Color_IColor(8, [])])) : empty());
    return box$0027(singleton_1(new Common_GenericOption(2, [modifiers])), ofArray([h6(singleton_1(new Option(10, [modifiers])))(singleton_1(`${DisplayHierarchy_depthToPrefix(depth)}${patternInput[0]}: ${Omnicv_Shared_FinancialAPI_CurrencyValue__CurrencyValue_ToDisplayString(value)}`)), columns(ofArray([new Option_2(3, []), new Option_2(4, []), new Option_2(1, [])]), ofArray([column(singleton_1(new Option_3(0, [new Screen(0, []), new ISize(21, [])])), toList(delay(() => {
        let children;
        return append((notes != null) ? singleton((children = singleton_1(value_6(notes)), createElement("p", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))) : empty_1(), delay(() => {
            let children_2;
            return append(singleton((children_2 = singleton_1(FSharpMap__get_Item(cashflowTypesLookup, cashflowType)), createElement("span", {
                children: reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => append(singleton(createElement("span", {
                children: reactApi.Children.toArray([" | Currency: "]),
            })), delay(() => singleton(value.Currency)))));
        }));
    }))), column(empty(), toList(delay(() => ((cashflow.tag === 0) ? singleton(button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(5, []), new Option_1(18, [(_arg) => {
        dispatch(new Msg(2, [new State(4, [createEditModel(cashflow.fields[0])])]));
    }])]), singleton_1(icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(4, []))), [])))))) : singleton(defaultOf())))))]))]));
}

function DisplayHierarchy_processGroupNode(depth, inProgress, node, value, dispatch) {
    let depth_1;
    return cons(box$0027(singleton_1(new Common_GenericOption(2, [singleton_1(new Modifier_IModifier(0, [new Color_IColor(6, [])]))])), ofArray([h6(empty())(singleton_1(`${DisplayHierarchy_depthToPrefix(depth)}${node.Data.Name}: ${Omnicv_Shared_FinancialAPI_CurrencyValue__CurrencyValue_ToDisplayString(value)}`)), columns(ofArray([new Option_2(3, []), new Option_2(4, []), new Option_2(1, [])]), ofArray([column(empty(), toList(delay(() => append((node.Data.Notes != null) ? singleton(content(empty(), singleton_1(value_6(node.Data.Notes)))) : empty_1(), delay(() => ((node.Data.DisplayCurrency != null) ? singleton(content(empty(), ofArray([createElement("span", {
        children: reactApi.Children.toArray(["Currency: "]),
    }), value_6(node.Data.DisplayCurrency)]))) : empty_1())))))), column(empty(), singleton_1(list_3(singleton_1(new List_Option(2, [])), ofArray([button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(5, []), new Option_1(16, [inProgress]), new Option_1(18, [(_arg) => {
        dispatch(new Msg(2, [new State(6, [node.Data])]));
    }])]), singleton_1(icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(0, []))), []))))), button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(5, []), new Option_1(18, [(_arg_1) => {
        dispatch(new Msg(2, [new State(2, [createEditModel(node.Data)])]));
    }])]), singleton_1(icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(4, []))), [])))))]))))]))])), collect((depth_1 = ((depth + 1) | 0), (node_1) => DisplayHierarchy_processChildNode(dispatch, depth_1, inProgress, node_1)), node.Children));
}

export function DisplayHierarchy_processChildNode(dispatch, depth, inProgress, node) {
    switch (node.tag) {
        case 1:
            return singleton_1(DisplayHierarchy_processAsset(depth, node.fields[0], node.fields[1], dispatch));
        case 2:
            return singleton_1(DisplayHierarchy_processCashflow(depth, node.fields[0], node.fields[1], dispatch));
        default:
            return DisplayHierarchy_processGroupNode(depth, inProgress, node.fields[0], node.fields[1], dispatch);
    }
}

export function DisplayHierarchy_buildTree(inProgress, tree_, tree__1, dispatch) {
    const tree = [tree_, tree__1];
    const totalItem = DisplayHierarchy_getTotalsItem(tree[1], false);
    return append_1(cons(totalItem, collect((tupledArg) => DisplayHierarchy_processGroupNode(0, inProgress, tupledArg[0], tupledArg[1], dispatch), tree[0])), singleton_1(totalItem));
}

export function DisplayHierarchy_treeToList(inProgress, _arg, tree, dispatch) {
    let matchResult, value;
    if (tree != null) {
        if (!isEmpty(tree[0])) {
            matchResult = 0;
            value = tree;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return DisplayHierarchy_buildTree(inProgress, value[0], value[1], dispatch);
        default:
            return singleton_1(createElement("div", {
                children: reactApi.Children.toArray(["No data yet"]),
            }));
    }
}

export function DisplayHierarchy_view(filter, groupType, session, model, dispatch) {
    let children_12, children_10, elems;
    const updateFilter = (newFilter) => {
        if (!equals(newFilter, filter)) {
            dispatch(new Msg(2, [DisplayHierarchy_groupTypeToState(groupType, newFilter)]));
        }
    };
    const preamble = toList(delay(() => {
        let children_4;
        const switchTabTrigger = (targetGroup) => (new Tab_Option(2, [singleton_1(new DOMAttr(40, [(_arg_2) => {
            DisplayHierarchy_viewByGroupType(targetGroup, dispatch);
        }]))]));
        let patternInput;
        const matchValue_1 = model.State;
        patternInput = ((matchValue_1.tag === 0) ? [valuesToSelectOptions(assetTypes, "asset type"), map_1((a) => {
            let copyOfStruct = a;
            return int32ToString(copyOfStruct);
        }, matchValue_1.fields[0].AssetType)] : ((matchValue_1.tag === 1) ? [valuesToSelectOptions(cashflowTypes, "cashflow type"), map_1((a_1) => {
            let copyOfStruct_1 = a_1;
            return int32ToString(copyOfStruct_1);
        }, matchValue_1.fields[0].CashflowType)] : [empty(), undefined]));
        const typeOptions = patternInput[0];
        const currentValue = patternInput[1];
        return append(singleton((children_4 = toList(delay(() => append(singleton(div(singleton_1(new Option_4(1, [])), ofArray([input(ofArray([new Option_5(1, [new IInputType(0, [])]), new Option_5(0, [new Size_ISize(0, [])]), new Option_5(12, ["Search"]), new Option_5(8, [defaultArg(filter.Content, "")]), new Option_5(13, [(event) => {
            const elem = event.target;
            updateFilter(new EntryFilter(filter.Currency, isNullOrWhiteSpace(elem.value) ? undefined : elem.value, filter.AssetType, filter.CashflowType));
        }])])), icon(ofArray([new Option_6(0, [new Size_ISize(0, [])]), new Option_6(1, [])]), singleton_1(Fa_i_1(singleton_1(new Fa_IconOption(11, ["fas fa-search"])), [])))]))), delay(() => {
            let props, children;
            return append(singleton(div(singleton_1(new Option_4(1, [])), ofArray([select(empty(), singleton_1((props = [new DOMAttr(9, [(event_2) => {
                const elem_2 = event_2.target;
                dispatch(new Msg(10, [isNullOrWhiteSpace(elem_2.value) ? undefined : elem_2.value]));
            }]), new HTMLAttr(161, [getDisplayCurrency(session, model)])], (children = supportedCurrenciesForEditingOptions("display currency"), react.createElement("select", keyValueList(props, 1), ...children))))), icon(ofArray([new Option_6(0, [new Size_ISize(0, [])]), new Option_6(1, [])]), singleton_1(Fa_i_1(singleton_1(new Fa_IconOption(11, ["fas fa-money-bill-alt"])), [])))]))), delay(() => {
                let props_2;
                return !isEmpty(typeOptions) ? singleton(div(singleton_1(new Option_4(1, [])), ofArray([select(empty(), singleton_1((props_2 = toList(delay(() => append(singleton(new DOMAttr(9, [(event_1) => {
                    let matchValue;
                    const elem_1 = event_1.target;
                    if (!isNullOrWhiteSpace(elem_1.value)) {
                        updateFilter((matchValue = model.State, (matchValue.tag === 0) ? (new EntryFilter(filter.Currency, filter.Content, map_1((tuple) => tuple[0], tryFind((tupledArg) => (int32ToString(tupledArg[0]) === elem_1.value), assetTypes)), filter.CashflowType)) : ((matchValue.tag === 1) ? (new EntryFilter(filter.Currency, filter.Content, filter.AssetType, map_1((tuple_1) => tuple_1[0], tryFind((tupledArg_1) => (int32ToString(tupledArg_1[0]) === elem_1.value), cashflowTypes)))) : filter)));
                    }
                }])), delay(() => ((currentValue != null) ? singleton(new HTMLAttr(161, [value_6(currentValue)])) : empty_1()))))), react.createElement("select", keyValueList(props_2, 1), ...typeOptions)))), icon(ofArray([new Option_6(0, [new Size_ISize(0, [])]), new Option_6(1, [])]), singleton_1(Fa_i_1(singleton_1(new Fa_IconOption(11, ["fas fa-list-ol"])), [])))]))) : empty_1();
            }));
        })))), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        }))), delay(() => singleton(tabs(singleton_1(new Option_7(1, [])), [tab(ofArray([new Tab_Option(0, [groupType === 1]), switchTabTrigger(1)]), singleton_1(createElement("a", {
            children: reactApi.Children.toArray(["Assets"]),
        }))), tab(ofArray([new Tab_Option(0, [groupType === 2]), switchTabTrigger(2)]), singleton_1(createElement("a", {
            children: reactApi.Children.toArray(["Cashflows"]),
        })))]))));
    }));
    const inProgress = model.InProgress;
    const mainControlButtons = singleton_1((children_12 = singleton_1(list_3(empty(), ofArray([button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(16, [inProgress]), new Option_1(18, [(_arg_3) => {
        dispatch(new Msg(0, []));
    }])]), ofArray([icon(empty(), toList(delay(() => (inProgress ? singleton(Fa_i(ofArray([Icons__ToIcon(new Icons(6, [])), new Fa_IconOption(12, [])]), [])) : singleton(Fa_i(singleton_1(Icons__ToIcon(new Icons(5, []))), [])))))), (children_10 = toList(delay(() => (inProgress ? singleton("Loading...") : singleton("Reload")))), createElement("span", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    }))])), button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(16, [inProgress]), new Option_1(4, []), new Option_1(18, [(arg30$0040) => {
        DisplayHierarchy_addGroup(session, dispatch, new FSharpChoice$2(1, [groupType]), arg30$0040);
    }])]), singleton_1("Add group")), button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(16, [inProgress]), new Option_1(4, []), new Option_1(18, [(_arg_4) => {
        dispatch(new Msg(2, [new State(7, [FileUploadData_Default()])]));
    }])]), singleton_1("Import")), button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(16, [inProgress]), new Option_1(4, [])]), singleton_1("Export")), button(ofArray([new Option_1(0, [new Color_IColor(6, [])]), new Option_1(16, [inProgress]), new Option_1(18, [(_arg_5) => {
        Router_modifyLocation(new Route(31, []));
    }])]), singleton_1("Asset values"))]))), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_12)),
    })));
    const progressModal = inProgress ? singleton_1(busyModal(inProgress, "Please wait")) : empty();
    const mainViewElements = DisplayHierarchy_treeToList(inProgress, groupType, tryFind_1(groupType, model.FinancialTree), dispatch);
    return createElement("div", createObj(singleton_1((elems = append_1(progressModal, append_1((length(mainControlButtons) < 2) ? preamble : append_1(preamble, mainControlButtons), append_1(mainViewElements, mainControlButtons))), ["children", reactApi.Children.toArray(Array.from(elems))]))));
}

export function DisplayHierarchy_updateImport(session, msg, data) {
    switch (msg.tag) {
        case 1:
            return [new FileUploadData(msg.fields[1], msg.fields[0], false, undefined), Cmd_none()];
        case 2: {
            const bytes_1 = msg.fields[0];
            return [new FileUploadData(data.CurrentFileName, bytes_1, true, undefined), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, curry2(financialService.ImportHierarchyData)(Session__GetCurrentProfileId(session)), bytes_1, () => (new FileUploadMsg(3, [undefined])), (Item_4) => (new FileUploadMsg(4, [Item_4])))];
        }
        case 3:
            return [new FileUploadData(undefined, undefined, false, undefined), Cmd_none()];
        case 4:
            return [new FileUploadData(undefined, undefined, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new FileUploadData(undefined, undefined, true, undefined), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, handleFileEvent, msg.fields[0], (tupledArg) => (new FileUploadMsg(1, [tupledArg[0], tupledArg[1]])), (Item_2) => (new FileUploadMsg(4, [Item_2])))];
    }
}

export function DisplayHierarchy_viewImport(inProgress, data, dispatch) {
    const caption = defaultArg(data.CurrentFileName, "Choose a file");
    return content(empty(), ofArray([h2(empty())(singleton_1("Import file")), div_1(singleton_1(new Option_8(0, [])), ofArray([file_1(singleton_1(new Option_9(10, [])), singleton_1(Label_label(empty(), ofArray([input_1(singleton_1(new Common_GenericOption(1, [singleton_1(new DOMAttr(9, [(arg_1) => {
        dispatch(new Msg(9, [new FileUploadMsg(0, [arg_1])]));
    }]))]))), cta(empty(), ofArray([icon_1(empty(), singleton_1(icon(empty(), singleton_1(Fa_i_1(singleton_1(new Fa_IconOption(11, ["fas fa-upload"])), []))))), Label_label(empty(), singleton_1("Import: "))])), name_12(empty(), singleton_1(caption))])))), div(empty(), singleton_1(button(ofArray([new Option_1(18, [(_arg) => {
        const matchValue = data.Data;
        if (matchValue == null) {
        }
        else {
            dispatch(new Msg(9, [new FileUploadMsg(2, [matchValue])]));
        }
    }]), new Option_1(0, [new Color_IColor(5, [])]), new Option_1(16, [inProgress ? true : !FileUploadData__CanSubmit(data)])]), singleton_1("Import"))))]))]));
}

const Main_service = financialService;

export function Main_init() {
    return [new Model(undefined, empty_2({
        Compare: comparePrimitives,
    }), new State(0, [EntryFilter_get_Empty()]), false, undefined, undefined), singleton_1((dispatch) => {
        dispatch(new Msg(0, []));
    })];
}

export function Main_update(session, msg, model) {
    let matchResult, newCurrency, result, state, record, subMsg, subMsg_1, subMsg_2, subMsg_3, subMsg_4, ex;
    switch (msg.tag) {
        case 10: {
            matchResult = 1;
            newCurrency = msg.fields[0];
            break;
        }
        case 1: {
            matchResult = 2;
            result = msg.fields[0];
            break;
        }
        case 2: {
            matchResult = 3;
            state = msg.fields[0];
            break;
        }
        case 3: {
            matchResult = 4;
            break;
        }
        case 5: {
            switch (msg.fields[0].tag) {
                case 2: {
                    matchResult = 5;
                    break;
                }
                case 3: {
                    matchResult = 6;
                    record = msg.fields[0].fields[0];
                    break;
                }
                default: {
                    matchResult = 7;
                    subMsg = msg.fields[0];
                }
            }
            break;
        }
        case 6: {
            switch (msg.fields[0].tag) {
                case 2:
                case 3: {
                    matchResult = 8;
                    break;
                }
                default: {
                    matchResult = 9;
                    subMsg_1 = msg.fields[0];
                }
            }
            break;
        }
        case 7: {
            if (msg.fields[0].tag === 2) {
                matchResult = 10;
            }
            else {
                matchResult = 11;
                subMsg_2 = msg.fields[0];
            }
            break;
        }
        case 8: {
            switch (msg.fields[0].tag) {
                case 2:
                case 3: {
                    matchResult = 12;
                    break;
                }
                default: {
                    matchResult = 13;
                    subMsg_3 = msg.fields[0];
                }
            }
            break;
        }
        case 9: {
            if (msg.fields[0].tag === 3) {
                matchResult = 14;
            }
            else {
                matchResult = 15;
                subMsg_4 = msg.fields[0];
            }
            break;
        }
        case 4: {
            matchResult = 16;
            ex = msg.fields[0];
            break;
        }
        default:
            matchResult = 0;
    }
    switch (matchResult) {
        case 0:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, model.State, true, model.Error, model.DisplayCurrency), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry2(Main_service.GetHierarchy)(Session__GetCurrentProfileId(session)), getDisplayCurrency(session, model), (Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1])))];
        case 1:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, model.State, model.InProgress, model.Error, newCurrency), singleton_1((dispatch) => {
                dispatch(new Msg(0, []));
            })];
        case 2:
            return [new Model(result, FinancialHierarchy__BuildMapTree_Z59A20369(result, Model__GetCurrentFilters(model)), model.State, false, undefined, model.DisplayCurrency), Cmd_none()];
        case 3:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, state, model.InProgress, model.Error, model.DisplayCurrency), singleton_1((dispatch_1) => {
                dispatch_1(new Msg(0, []));
            })];
        case 4:
            return [model, singleton_1((dispatch_2) => {
                dispatch_2(new Msg(0, []));
            })];
        case 5: {
            const matchValue = model.State;
            if (matchValue.tag === 2) {
                return [new Model(model.FinancialHierarchy, model.FinancialTree, DisplayHierarchy_groupTypeToState(matchValue.fields[0].Data.GroupType, EntryFilter_get_Empty()), model.InProgress, model.Error, model.DisplayCurrency), singleton_1((dispatch_3) => {
                    dispatch_3(new Msg(0, []));
                })];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 6:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, DisplayHierarchy_groupTypeToState(record.GroupType, EntryFilter_get_Empty()), model.InProgress, model.Error, model.DisplayCurrency), singleton_1((dispatch_4) => {
                dispatch_4(new Msg(0, []));
            })];
        case 7: {
            const matchValue_1 = model.State;
            if (matchValue_1.tag === 2) {
                const patternInput = Groups_update(session, subMsg, matchValue_1.fields[0]);
                return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(2, [patternInput[0]]), model.InProgress, model.Error, model.DisplayCurrency), Cmd_map((Item_2) => (new Msg(5, [Item_2])), patternInput[1])];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 8:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(0, [EntryFilter_get_Empty()]), model.InProgress, model.Error, model.DisplayCurrency), singleton_1((dispatch_5) => {
                dispatch_5(new Msg(0, []));
            })];
        case 9: {
            const matchValue_2 = model.State;
            if (matchValue_2.tag === 3) {
                const patternInput_1 = Assets_update(session, subMsg_1, matchValue_2.fields[0]);
                return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(3, [patternInput_1[0]]), model.InProgress, model.Error, model.DisplayCurrency), Cmd_map((Item_3) => (new Msg(6, [Item_3])), patternInput_1[1])];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 10:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(0, [EntryFilter_get_Empty()]), model.InProgress, model.Error, model.DisplayCurrency), singleton_1((dispatch_6) => {
                dispatch_6(new Msg(0, []));
            })];
        case 11: {
            const matchValue_3 = model.State;
            if (matchValue_3.tag === 5) {
                const asset = matchValue_3.fields[0];
                const patternInput_2 = Assets_updateValue(session, subMsg_2, asset, matchValue_3.fields[1]);
                return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(5, [asset, patternInput_2[0]]), model.InProgress, model.Error, model.DisplayCurrency), Cmd_map((Item_4) => (new Msg(7, [Item_4])), patternInput_2[1])];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 12:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(1, [EntryFilter_get_Empty()]), model.InProgress, model.Error, model.DisplayCurrency), singleton_1((dispatch_7) => {
                dispatch_7(new Msg(0, []));
            })];
        case 13: {
            const matchValue_4 = model.State;
            if (matchValue_4.tag === 4) {
                const patternInput_3 = Cashflows_update(session, subMsg_3, matchValue_4.fields[0]);
                return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(4, [patternInput_3[0]]), model.InProgress, model.Error, model.DisplayCurrency), Cmd_map((Item_5) => (new Msg(8, [Item_5])), patternInput_3[1])];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 14:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(0, [EntryFilter_get_Empty()]), false, undefined, model.DisplayCurrency), singleton_1((dispatch_8) => {
                dispatch_8(new Msg(0, []));
            })];
        case 15: {
            const matchValue_5 = model.State;
            if (matchValue_5.tag === 7) {
                const patternInput_4 = DisplayHierarchy_updateImport(session, subMsg_4, matchValue_5.fields[0]);
                const resultModel = patternInput_4[0];
                return [new Model(model.FinancialHierarchy, model.FinancialTree, new State(7, [resultModel]), resultModel.InProgress, resultModel.Error, model.DisplayCurrency), Cmd_map((Item_6) => (new Msg(9, [Item_6])), patternInput_4[1])];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        default:
            return [new Model(model.FinancialHierarchy, model.FinancialTree, model.State, false, exnToDisplayString(ex), model.DisplayCurrency), Cmd_none()];
    }
}

export function Main_view(session, model, dispatch) {
    const viewHierarchy = (groupType, filter) => DisplayHierarchy_view(filter, groupType, session, model, dispatch);
    const children = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton(closeable(`Financial error: ${matchValue}`, new Class(4, []))) : singleton(defaultOf())), delay(() => {
            const matchValue_1 = model.State;
            return (matchValue_1.tag === 1) ? singleton(viewHierarchy(2, matchValue_1.fields[0])) : ((matchValue_1.tag === 2) ? singleton(Groups_viewForAddEdit(matchValue_1.fields[0], dispatch)) : ((matchValue_1.tag === 6) ? singleton(DisplayHierarchy_viewSelectAdd(session, matchValue_1.fields[0], dispatch)) : ((matchValue_1.tag === 3) ? singleton(Assets_viewForAddEdit(matchValue_1.fields[0], dispatch)) : ((matchValue_1.tag === 4) ? singleton(Cashflows_viewForAddEdit(matchValue_1.fields[0], dispatch)) : ((matchValue_1.tag === 5) ? singleton(Assets_viewAddValue(matchValue_1.fields[0], matchValue_1.fields[1], dispatch)) : ((matchValue_1.tag === 7) ? singleton(DisplayHierarchy_viewImport(model.InProgress, matchValue_1.fields[0], dispatch)) : singleton(viewHierarchy(1, matchValue_1.fields[0]))))))));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

