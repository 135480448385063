import { map as map_1, empty, find, ofArray } from "../../fable_modules/fable-library-js.4.22.0/List.js";
import { comparePrimitives, int32ToString } from "../../fable_modules/fable-library-js.4.22.0/Util.js";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.22.0/Option.js";
import { validateInstructions, Exercise, Exercise_Default_7F9DDECF, Workout, Workout_Default_7F9DDECF, validateName, validateDescription, TrainingPlan, TrainingPlan_Default_7F9DDECF, FitnessEditHelperData_$reflection, FitnessEditHelperData__GetFrequentTags } from "../../Omnicv.Shared/FitnessAPI.js";
import { Msg$2, SimpleFormView$2__ViewForm_Z44A2099C, Model$2__IsEditing, SimpleFormView$2__InitEditWithHelperData_3AAD8C48, SimpleFormView$2__InitNewCore_Z292EED19, SimpleFormView$2_$reflection, SimpleFormView$2 } from "../../Omnicv.Client.Common/Components/AddEditSimpleFlexFormBase.js";
import { Route, Router_newUrl } from "../../Omnicv.Client.Common/Router.js";
import { class_type } from "../../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { utcNow } from "../../fable_modules/fable-library-js.4.22.0/Date.js";
import { div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.22.0/Seq.js";
import { ofList } from "../../fable_modules/fable-library-js.4.22.0/Set.js";
import { validateServerTags, toClientTreeView, addTag as addTag_1, toClientListView } from "../../Omnicv.Shared.Common/TagUtils.js";
import { renderTagTree } from "../../modules/Omnicv.Diary.Client/DiaryClientUtils.js";
import { empty as empty_1 } from "../../fable_modules/fable-library-js.4.22.0/Map.js";
import { Session__SiteSettings, Session__GetCurrentProfileId } from "../../Omnicv.Client.Common/SharedView.js";
import { fitnessService } from "./FitnessCommon.js";
import { FlexFormFields$1__AddSelection_158231B6, FlexFormBase$1__set_SubmitCaption_Z721C83C5, FlexForm$1__get_Fields, FlexFormFields$1__AddText_Z44011C05, FlexFormFields$1__AddTextArea_Z44011C05, FlexFormFields$1__AddTags_Z63D88B1E } from "../../Omnicv.Client.Common/Components/FlexForm.js";
import { parse } from "../../fable_modules/fable-library-js.4.22.0/Int32.js";

export const workoutTypes = ofArray([[1, "Regular"], [2, "Circuit"]]);

export function stringToWorkoutType(input) {
    return find((pair) => {
        let copyOfStruct;
        return input === ((copyOfStruct = pair[0], int32ToString(copyOfStruct)));
    }, workoutTypes)[0];
}

function extractFrequentTags(model) {
    return defaultArg(map(FitnessEditHelperData__GetFrequentTags, model.EditHelperData), empty());
}

export class AddEditFitnessView$1 extends SimpleFormView$2 {
    constructor(getDefault, fetchEntry, fetchEditHelperData, submitNewAction, submitEditAction, mainView, tagsGetter, tagsSetter, addTitle, editTitle, buildForm) {
        super(buildForm, addTitle, submitNewAction, submitEditAction, () => Router_newUrl(mainView), mainView);
        this.getDefault = getDefault;
        this.fetchEntry = fetchEntry;
        this.fetchEditHelperData = fetchEditHelperData;
        this.tagsGetter = tagsGetter;
        this.tagsSetter = tagsSetter;
        this.addTitle = addTitle;
        this.editTitle = editTitle;
    }
}

export function AddEditFitnessView$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Pages.Fitness.AddEditFitnessView.AddEditFitness.AddEditFitnessView`1", [gen0], AddEditFitnessView$1, SimpleFormView$2_$reflection(gen0, FitnessEditHelperData_$reflection()));
}

export function AddEditFitnessView$1_$ctor_Z7DF71A99(getDefault, fetchEntry, fetchEditHelperData, submitNewAction, submitEditAction, mainView, tagsGetter, tagsSetter, addTitle, editTitle, buildForm) {
    return new AddEditFitnessView$1(getDefault, fetchEntry, fetchEditHelperData, submitNewAction, submitEditAction, mainView, tagsGetter, tagsSetter, addTitle, editTitle, buildForm);
}

export function AddEditFitnessView$1__InitNew_5D846BF8(this$, session) {
    return SimpleFormView$2__InitNewCore_Z292EED19(this$, this$.getDefault(utcNow()), () => AddEditFitnessView$1__fetchAddHelperData(this$, session, undefined));
}

export function AddEditFitnessView$1__InitEdit(this$, session, id) {
    return SimpleFormView$2__InitEditWithHelperData_3AAD8C48(this$, id, this$.getDefault(utcNow()), (id_1) => AddEditFitnessView$1__fetchData(this$, session, id_1), () => AddEditFitnessView$1__fetchAddHelperData(this$, session, undefined));
}

export function AddEditFitnessView$1__View(this$, session, model, dispatch) {
    const title = Model$2__IsEditing(model) ? this$.editTitle : this$.addTitle;
    return div(empty(), toList(delay(() => append(singleton(SimpleFormView$2__ViewForm_Z44A2099C(this$, session, model, dispatch)), delay(() => {
        let record;
        const currentTags = ofList(toClientListView(this$.tagsGetter(model.Record)), {
            Compare: comparePrimitives,
        });
        return singleton(renderTagTree((record = model.Record, (tag) => {
            dispatch(new Msg$2(0, [this$.tagsSetter(addTag_1(this$.tagsGetter(record), tag), record)]));
        }), toClientTreeView(defaultArg(map((data) => data.FrequentTags, model.EditHelperData), empty_1({
            Compare: comparePrimitives,
        }))), currentTags));
    })))));
}

export function AddEditFitnessView$1__fetchData(this$, session, id) {
    return this$.fetchEntry(Session__GetCurrentProfileId(session), id);
}

export function AddEditFitnessView$1__fetchAddHelperData(this$, session, _arg) {
    return this$.fetchEditHelperData(Session__GetCurrentProfileId(session));
}

export const trainingPlanEditing = AddEditFitnessView$1_$ctor_Z7DF71A99(TrainingPlan_Default_7F9DDECF, fitnessService.GetTrainingPlan, fitnessService.GetFitnessEditHelperDataTrainingPlan, fitnessService.AddTrainingPlan, fitnessService.EditTrainingPlan, new Route(32, []), (record) => record.Categories, (newTags, record_1) => (new TrainingPlan(record_1.Id, record_1.ProfileId, record_1.Name, record_1.Description, record_1.CreatedAt, record_1.UpdatedAt, newTags)), "Add new training plan", "Edit training plan", (form, _session, model, _dispatch) => {
    FlexFormFields$1__AddTags_Z63D88B1E(FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Name", validateName), "Description", validateDescription), "Categories", validateServerTags, extractFrequentTags(model), undefined, undefined, "Categories for the training plan. To separate, use \",\".");
    if (Model$2__IsEditing(model)) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update plan");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add plan");
    }
    return form;
});

export const workoutEditing = AddEditFitnessView$1_$ctor_Z7DF71A99(Workout_Default_7F9DDECF, fitnessService.GetWorkout, fitnessService.GetFitnessEditHelperDataWorkout, fitnessService.AddWorkout, fitnessService.EditWorkout, new Route(32, []), (record) => record.Categories, (newTags, record_1) => (new Workout(record_1.Id, record_1.ProfileId, record_1.Name, record_1.WorkoutType, record_1.Description, record_1.Schedule, record_1.CreatedAt, record_1.UpdatedAt, newTags)), "Add new workout", "Edit workout", (form, _session, model, _dispatch) => {
    FlexFormFields$1__AddTags_Z63D88B1E(FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddText_Z44011C05(FlexFormFields$1__AddSelection_158231B6(FlexForm$1__get_Fields(form), "WorkoutType", workoutTypes, stringToWorkoutType, (_arg) => undefined, "Workout type", undefined, "Circuit type implies one repetition of each exercise in a circuit"), "Name", validateName), "Description", validateDescription), "Categories", validateServerTags, extractFrequentTags(model), undefined, undefined, "Categories for the workout. To separate, use \",\".");
    if (Model$2__IsEditing(model)) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update workout");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add workout");
    }
    return form;
});

export const exerciseEditing = AddEditFitnessView$1_$ctor_Z7DF71A99(Exercise_Default_7F9DDECF, fitnessService.GetExercise, fitnessService.GetFitnessEditHelperDataExercise, fitnessService.AddExercise, fitnessService.EditExercise, new Route(32, []), (record) => record.Categories, (newTags, record_1) => (new Exercise(record_1.Id, record_1.ProfileId, record_1.Name, record_1.Description, record_1.Instructions, record_1.ContentTypeId, record_1.CreatedAt, record_1.UpdatedAt, newTags)), "Add new exercise", "Edit exercise", (form, session, model, _dispatch) => {
    const contentTypes = map_1((t) => [t.Id, t.Name], Session__SiteSettings(session).FitnessContentTypes);
    FlexFormFields$1__AddTags_Z63D88B1E(FlexFormFields$1__AddSelection_158231B6(FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Name", validateName), "Description", validateDescription), "Instructions", validateInstructions), "ContentTypeId", contentTypes, (s) => parse(s, 511, false, 32), (_arg) => undefined, "Exercise type"), "Categories", validateServerTags, extractFrequentTags(model), undefined, undefined, "Categories for the exercise. To separate, use \",\".");
    if (Model$2__IsEditing(model)) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update exercise");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add exercise");
    }
    return form;
});

