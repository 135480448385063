import { Record } from "../fable_modules/fable-library-js.4.22.0/Types.js";
import { decimal_type, bool_type, array_type, uint8_type, enum_type, float64_type, option_type, int64_type, record_type, class_type, string_type, int32_type } from "../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { Constraints_collectConstraintsErrors } from "./Validation.js";
import { last as last_1, empty } from "../fable_modules/fable-library-js.4.22.0/List.js";
import { tryFind } from "../fable_modules/fable-library-js.4.22.0/Map.js";
import { value as value_1, defaultArg, map } from "../fable_modules/fable-library-js.4.22.0/Option.js";
import { offsetToUtcZone } from "./TimeUtils.js";
import { addDays, utcNow, toUniversalTime } from "../fable_modules/fable-library-js.4.22.0/Date.js";

export class DataModule extends Record {
    constructor(Id, Name, CurrentVersion, CreatedAt, UpdatedAt) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.CurrentVersion = CurrentVersion;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function DataModule_$reflection() {
    return record_type("Omnicv.DataModule.CoreData.DataModule", [], DataModule, () => [["Id", int32_type], ["Name", string_type], ["CurrentVersion", string_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class ContentType extends Record {
    constructor(Id, Name, DataModuleId, CreatedAt, UpdatedAt) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
        this.DataModuleId = (DataModuleId | 0);
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
    }
}

export function ContentType_$reflection() {
    return record_type("Omnicv.DataModule.CoreData.ContentType", [], ContentType, () => [["Id", int32_type], ["Name", string_type], ["DataModuleId", int32_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")]]);
}

export class EventInfo extends Record {
    constructor(Id, ContentTypeId, ProfileId, EventTimestamp, CreatedAt, UpdatedAt, PlaceId, PlaceName, Latitude, Longitude, LocationTz, LocationTzOffsetMin, ClientTz, ClientTzOffsetMin) {
        super();
        this.Id = Id;
        this.ContentTypeId = (ContentTypeId | 0);
        this.ProfileId = (ProfileId | 0);
        this.EventTimestamp = EventTimestamp;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.PlaceId = PlaceId;
        this.PlaceName = PlaceName;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.LocationTz = LocationTz;
        this.LocationTzOffsetMin = LocationTzOffsetMin;
        this.ClientTz = ClientTz;
        this.ClientTzOffsetMin = ClientTzOffsetMin;
    }
}

export function EventInfo_$reflection() {
    return record_type("Omnicv.DataModule.CoreData.EventInfo", [], EventInfo, () => [["Id", int64_type], ["ContentTypeId", int32_type], ["ProfileId", int32_type], ["EventTimestamp", class_type("System.DateTime")], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["PlaceId", option_type(string_type)], ["PlaceName", option_type(string_type)], ["Latitude", option_type(float64_type)], ["Longitude", option_type(float64_type)], ["LocationTz", option_type(string_type)], ["LocationTzOffsetMin", option_type(int32_type)], ["ClientTz", option_type(string_type)], ["ClientTzOffsetMin", option_type(int32_type)]]);
}

export class MimeType extends Record {
    constructor(Id, MimeType, Description) {
        super();
        this.Id = (Id | 0);
        this.MimeType = MimeType;
        this.Description = Description;
    }
}

export function MimeType_$reflection() {
    return record_type("Omnicv.DataModule.CoreData.MimeType", [], MimeType, () => [["Id", int32_type], ["MimeType", string_type], ["Description", option_type(string_type)]]);
}

export class Attachment extends Record {
    constructor(Id, RecordId, AttachmentType, CreatedAt, UpdatedAt, MimeTypeId, Data, Weight) {
        super();
        this.Id = Id;
        this.RecordId = RecordId;
        this.AttachmentType = (AttachmentType | 0);
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.MimeTypeId = (MimeTypeId | 0);
        this.Data = Data;
        this.Weight = (Weight | 0);
    }
}

export function Attachment_$reflection() {
    return record_type("Omnicv.DataModule.CoreData.Attachment", [], Attachment, () => [["Id", int64_type], ["RecordId", int64_type], ["AttachmentType", enum_type("Omnicv.DataModule.CoreData.AttachmentType", int32_type, [["EventInfoAttachment", 1], ["FitnessAttachment", 2]])], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["MimeTypeId", int32_type], ["Data", array_type(uint8_type)], ["Weight", int32_type]]);
}

export class PageBoundary extends Record {
    constructor(Id, Timestamp) {
        super();
        this.Id = Id;
        this.Timestamp = Timestamp;
    }
}

export function PageBoundary_$reflection() {
    return record_type("Omnicv.DataModule.CoreData.PageBoundary", [], PageBoundary, () => [["Id", int64_type], ["Timestamp", class_type("System.DateTime")]]);
}

export class PagingOptions extends Record {
    constructor(LastBoundary, PageSize, Ascending, From, To) {
        super();
        this.LastBoundary = LastBoundary;
        this.PageSize = (PageSize | 0);
        this.Ascending = Ascending;
        this.From = From;
        this.To = To;
    }
}

export function PagingOptions_$reflection() {
    return record_type("Omnicv.DataModule.CoreData.PagingOptions", [], PagingOptions, () => [["LastBoundary", option_type(PageBoundary_$reflection())], ["PageSize", int32_type], ["Ascending", bool_type], ["From", option_type(class_type("System.DateTime"))], ["To", option_type(class_type("System.DateTime"))]]);
}

export class DataPoint extends Record {
    constructor(Id, Timestamp, Value) {
        super();
        this.Id = Id;
        this.Timestamp = Timestamp;
        this.Value = Value;
    }
}

export function DataPoint_$reflection() {
    return record_type("Omnicv.DataModule.CoreData.DataPoint", [], DataPoint, () => [["Id", int64_type], ["Timestamp", class_type("System.DateTime")], ["Value", decimal_type]]);
}

export function EventInfo_Default_7F9DDECF(timestamp) {
    return new EventInfo(0n, 0, 0, timestamp, timestamp, timestamp, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function EventInfo_Validator_1505(record) {
    return Constraints_collectConstraintsErrors(empty());
}

export function EventInfo__Validate(this$) {
    return EventInfo_Validator_1505(this$);
}

export function EventInfo__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, EventInfo__Validate(this$));
}

export function EventInfo__BestLocation(this$) {
    return CoreUtils_bestLocation(this$.PlaceId, this$.PlaceName, this$.Latitude, this$.Longitude);
}

export function EventInfo__GetClientTz(this$) {
    if (this$.ClientTz != null) {
        return this$.ClientTz;
    }
    else {
        return map(offsetToUtcZone, this$.ClientTzOffsetMin);
    }
}

export function EventInfo__GetLocationTz(this$) {
    if (this$.LocationTz != null) {
        return this$.LocationTz;
    }
    else {
        return map(offsetToUtcZone, this$.LocationTzOffsetMin);
    }
}

export function EventInfo__DisplayTimeZone(this$) {
    const getTz = (tz) => defaultArg(tz, "N/A");
    const matchValue = EventInfo__GetClientTz(this$);
    const matchValue_1 = EventInfo__GetLocationTz(this$);
    let matchResult, clientTz, locationTz, clientTz_1, locationTz_1;
    if (matchValue == null) {
        if (matchValue_1 == null) {
            matchResult = 1;
        }
        else {
            matchResult = 2;
            clientTz_1 = matchValue;
            locationTz_1 = matchValue_1;
        }
    }
    else if (matchValue_1 != null) {
        matchResult = 0;
        clientTz = matchValue;
        locationTz = matchValue_1;
    }
    else {
        matchResult = 2;
        clientTz_1 = matchValue;
        locationTz_1 = matchValue_1;
    }
    switch (matchResult) {
        case 0:
            if (clientTz === locationTz) {
                return clientTz;
            }
            else {
                return `${clientTz}/${locationTz}`;
            }
        case 1:
            return "N/A";
        default:
            return `${getTz(clientTz_1)}/${getTz(locationTz_1)}`;
    }
}

export function EventInfo__ClearLocation(this$) {
    return new EventInfo(this$.Id, this$.ContentTypeId, this$.ProfileId, this$.EventTimestamp, this$.CreatedAt, this$.UpdatedAt, undefined, undefined, undefined, undefined, undefined, undefined, this$.ClientTz, this$.ClientTzOffsetMin);
}

export function PagingOptionsModule_basicPagingOptions(fromDate, toDate) {
    return new PagingOptions(undefined, 20, false, fromDate, toDate);
}

export function PagingOptionsModule_basicPagingOptionsForDays(days) {
    const now = toUniversalTime(utcNow());
    return PagingOptionsModule_basicPagingOptions(addDays(now, -days), now);
}

export const PagingOptionsModule_defaultPagingOptions = PagingOptionsModule_basicPagingOptions(undefined, undefined);

export function PagingOptionsModule_optionsForNextPage(entries, pagingOptions) {
    const last = last_1(entries)[0];
    return new PagingOptions(new PageBoundary(last.Id, last.EventTimestamp), pagingOptions.PageSize, pagingOptions.Ascending, pagingOptions.From, pagingOptions.To);
}

export function CoreUtils_bestLocation(placeId, placeName, latitude, longitude) {
    if (placeName != null) {
        return value_1(placeName);
    }
    else if (placeId != null) {
        return value_1(placeId);
    }
    else if ((latitude != null) && (longitude != null)) {
        return `${latitude}/${longitude}`;
    }
    else {
        return "";
    }
}

