import { Union } from "../../fable-library-js.4.22.0/Types.js";
import { lambda_type, unit_type, union_type, string_type, bool_type, list_type, class_type } from "../../fable-library-js.4.22.0/Reflection.js";
import { Common_parseOptions, Common_GenericOptions__AddProp_ZCC5A61F, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Size_ISize_$reflection, Modifier_IModifier_$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.22.0/MapUtil.js";
import { some } from "../../fable-library-js.4.22.0/Option.js";
import { Reflection_getCaseName } from "../Common.fs.js";
import { DOMAttr } from "../../Fable.React.9.4.0/Fable.React.Props.fs.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Props", "is-active", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Modal.Option", [], Option, () => [[["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", bool_type]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

export class Close_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Size", "OnClick", "Props", "CustomClass", "Modifiers"];
    }
}

export function Close_Option_$reflection() {
    return union_type("Fulma.Modal.Close.Option", [], Close_Option, () => [[["Item", Size_ISize_$reflection()]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent", undefined), unit_type)]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="modal"></div>
 */
export function modal(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 0:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
        }
    }, "modal"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <button class="modal-close"></button>
 */
export function close(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        let matchResult, size, cb, props, customClass, modifiers;
        switch (option.tag) {
            case 1: {
                matchResult = 2;
                cb = option.fields[0];
                break;
            }
            case 2: {
                matchResult = 3;
                props = option.fields[0];
                break;
            }
            case 3: {
                matchResult = 4;
                customClass = option.fields[0];
                break;
            }
            case 4: {
                matchResult = 5;
                modifiers = option.fields[0];
                break;
            }
            default:
                switch (option.fields[0].tag) {
                    case 0:
                    case 1: {
                        matchResult = 0;
                        break;
                    }
                    default: {
                        matchResult = 1;
                        size = option.fields[0];
                    }
                }
        }
        switch (matchResult) {
            case 0: {
                console.warn(some("`is-small` and `is-medium` are not valid sizes for \'modal close\'"));
                return result;
            }
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(size));
            case 2:
                return Common_GenericOptions__AddProp_ZCC5A61F(result, new DOMAttr(40, [cb]));
            case 3:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, props);
            case 4:
                return Common_GenericOptions__AddClass_Z721C83C5(result, customClass);
            default:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, modifiers);
        }
    }, "modal-close"), (props_1, children_1) => react.createElement("button", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <div class="modal-background"></div>
 */
export function background(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "modal-background"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="modal-content"></div>
 */
export function content(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "modal-content"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="modal-card"></div>
 */
export function Card_card(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "modal-card"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <header class="modal-card-head"></header>
 */
export function Card_head(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "modal-card-head"), (props, children_1) => react.createElement("header", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <footer class="modal-card-foot"></footer>
 */
export function Card_foot(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "modal-card-foot"), (props, children_1) => react.createElement("footer", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="modal-card-title"></div>
 */
export function Card_title(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "modal-card-title"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <div class="modal-card-body"></div>
 */
export function Card_body(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "modal-card-body"), (props, children_1) => react.createElement("section", keyValueList(props, 1), ...children_1), children);
}

