import { Union } from "../../../fable-library-js.4.22.0/Types.js";
import { union_type, list_type, class_type, string_type } from "../../../fable-library-js.4.22.0/Reflection.js";
import { Color_ofColor, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Color_IColor_$reflection } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library-js.4.22.0/MapUtil.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CustomClass", "Props", "Color", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Help.Option", [], Option, () => [[["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", Color_IColor_$reflection()]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <p class="help"></p>
 */
export function help(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 1:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 0:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 3:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
        }
    }, "help"), (props_1, children_1) => react.createElement("p", keyValueList(props_1, 1), ...children_1), children);
}

