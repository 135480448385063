import { secondsToHuman, responsiveChart, getBasicEventInfoData, isNight } from "../../Omnicv.Client.Common/Utils.js";
import { StringBuilder__Append_Z721C83C5, StringBuilder_$ctor } from "../../fable_modules/fable-library-js.4.22.0/System.Text.js";
import { toString } from "../../fable_modules/fable-library-js.4.22.0/Types.js";
import { list as list_8, Option, button } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Screen, Size_ISize, Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Route, Router_modifyLocation } from "../../Omnicv.Client.Common/Router.js";
import { append as append_1, collect, min, max, average as average_1, tryFind, length, isEmpty, map as map_1, sortBy, empty, singleton, ofArray } from "../../fable_modules/fable-library-js.4.22.0/List.js";
import { Option as Option_1, icon } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../Omnicv.Client.Common/SharedView.js";
import { Icons } from "../../Omnicv.Client.Common/SharedView.js";
import { Option as Option_2, columns } from "../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { exists, filter, length as length_1, empty as empty_1, map, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.22.0/Seq.js";
import { Option as Option_3, ISize, column } from "../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.22.0/Util.js";
import { getModuleViewAllButtons, getModuleNewNowButtons } from "../../Omnicv.Client.Common/ClientModule.js";
import { unifiedService, bodilyFunctionsService, weightService, physicalModule } from "./PhysicalCommon.js";
import { Fa_i as Fa_i_1, Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { createElement } from "react";
import * as react from "react";
import { reactApi } from "../../fable_modules/Feliz.2.9.0/Interop.fs.js";
import { map as map_2, defaultArg, value as value_10 } from "../../fable_modules/fable-library-js.4.22.0/Option.js";
import { Class, closeable } from "../../Omnicv.Client.Common/Components/Notification.js";
import { init, update, initDateRange, Actions$1, Model$1__CanFetchMore, Msg$1 } from "../../Omnicv.Client.Common/Components/ViewRecords.js";
import { Option as Option_4, h5, h4 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { uncurry2, numberHash, comparePrimitives, dateHash, createObj, equals, curry2 } from "../../fable_modules/fable-library-js.4.22.0/Util.js";
import { format } from "../../fable_modules/fable-library-js.4.22.0/String.js";
import { equals as equals_1, op_Subtraction, compare, utcNow, toUniversalTime } from "../../fable_modules/fable-library-js.4.22.0/Date.js";
import { addDays } from "date-fns";
import { op_UnaryNegation_Int32 } from "../../fable_modules/fable-library-js.4.22.0/Int32.js";
import Decimal from "../../fable_modules/fable-library-js.4.22.0/Decimal.js";
import { DataPoint } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { reactApi as reactApi_1 } from "../../fable_modules/Feliz.2.9.0/Interop.fs.js";
import { ResponsiveContainer } from "recharts";
import { bodilyFunctionsEmojis } from "../../Omnicv.Shared/PhysicalAPI.js";
import { days as days_3 } from "../../fable_modules/fable-library-js.4.22.0/TimeSpan.js";
import { toString as toString_1, fromDateTime, toDateTime } from "../../fable_modules/fable-library-js.4.22.0/DateOnly.js";
import { List_groupBy } from "../../fable_modules/fable-library-js.4.22.0/Seq2.js";
import * as common from "../../../src/javascript/common.js";

const pageSize = 30;

const standardLookBackDays = 30;

function isDuringSleep(eventInfo, record) {
    const matchValue = record.DuringSleep;
    if (matchValue == null) {
        return isNight(eventInfo);
    }
    else {
        return matchValue;
    }
}

function displayEventInfo(eventInfo) {
    const sb = StringBuilder_$ctor();
    StringBuilder__Append_Z721C83C5(sb, getBasicEventInfoData(eventInfo));
    return toString(sb);
}

function displayActions(id, editRoute, deleteFun) {
    return ofArray([button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(5, []), new Option(18, [(_arg) => {
        Router_modifyLocation(editRoute(id));
    }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(4, []))), []))))), button(ofArray([new Option(0, [new Color_IColor(8, [])]), new Option(5, []), new Option(18, [(_arg_1) => {
        if (window.confirm(`${"Confirm deletion"}:
${"Are you sure you want to delete this physical entry?"}`)) {
            (() => {
                deleteFun(id);
            })();
        }
    }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(2, []))), [])))))]);
}

function displaySingleRecord(displayRecordDetails, actions, eventInfo, record) {
    return columns(ofArray([new Option_2(4, []), new Option_2(1, [])]), toList(delay(() => append(singleton_1(column(empty(), singleton(displayEventInfo(eventInfo)))), delay(() => append(singleton_1(column(singleton(new Option_3(0, [new Screen(0, []), new ISize(21, [])])), singleton(displayRecordDetails(eventInfo, record)))), delay(() => {
        const matchValue = actions;
        if (matchValue == null) {
            return singleton_1(defaultOf());
        }
        else {
            const deleteFun = matchValue[1];
            return singleton_1(column(empty(), displayActions(eventInfo.Id, matchValue[0](record), deleteFun)));
        }
    })))))));
}

function displayRecords(records, displayRecordDetails, actions) {
    return map((tupledArg) => displaySingleRecord(displayRecordDetails, actions, tupledArg[0], tupledArg[1]), records);
}

function displayNavigation(isDisabled, inProgress, reloadFun) {
    let children;
    const children_2 = [getModuleNewNowButtons(physicalModule, isDisabled), list_8(empty(), singleton(button(ofArray([new Option(0, [new Color_IColor(5, [])]), new Option(16, [isDisabled]), new Option(18, [reloadFun])]), ofArray([icon(empty(), toList(delay(() => (inProgress ? singleton_1(Fa_i(ofArray([Icons__ToIcon(new Icons(6, [])), new Fa_IconOption(12, [])]), [])) : singleton_1(Fa_i(singleton(Icons__ToIcon(new Icons(5, []))), [])))))), (children = toList(delay(() => (inProgress ? singleton_1("Loading...") : singleton_1("Reload")))), createElement("span", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))])))), getModuleViewAllButtons(physicalModule, isDisabled)];
    return react.createElement("div", {
        className: "block",
        disabled: isDisabled,
    }, ...children_2);
}

function viewCommon(title, subTitle, getEditRoute, displayRecordDetails, session, model, dispatch) {
    const readOnly = value_10(session.UserInfo).ReadOnly;
    const isDisabled = model.InProgress ? true : readOnly;
    const children_4 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't fetch physical data: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append(singleton_1(displayNavigation(isDisabled, model.InProgress, (_arg) => {
            dispatch(new Msg$1(1, []));
        })), delay(() => append(singleton_1(h4(empty())(singleton(title))), delay(() => append(singleton_1(h5(singleton(new Option_4(6, [])))(singleton(subTitle))), delay(() => {
            let children;
            return append(singleton_1((children = displayRecords(model.Records, displayRecordDetails, readOnly ? undefined : [curry2(getEditRoute), (arg) => {
                dispatch(new Msg$1(5, [arg]));
            }]), createElement("div", {
                children: reactApi.Children.toArray(Array.from(children)),
            }))), delay(() => (Model$1__CanFetchMore(model) ? singleton_1(button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [model.InProgress]), new Option(18, [(_arg_1) => {
                dispatch(new Msg$1(0, []));
            }])]), ofArray([icon(empty(), singleton(Fa_i_1(singleton(new Fa_IconOption(11, ["fas fa-download"])), []))), createElement("span", {
                children: reactApi.Children.toArray(["Load more"]),
            })]))) : empty_1())));
        }))))))));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function WeightView_getEditRoute(_arg, Item) {
    return new Route(22, [Item]);
}

export function WeightView_displayRecord(record) {
    return format('{0:' + "F2" + '}', record.Weight) + " kg";
}

const WeightView_service = weightService;

export const WeightView_actions = new Actions$1(WeightView_service.GetRecords);

export function WeightView_init() {
    const now = toUniversalTime(utcNow());
    return initDateRange(WeightView_actions, false, addDays(now, op_UnaryNegation_Int32(standardLookBackDays) * 6), now);
}

export const WeightView_update = (session) => ((msg) => ((model) => update(session, msg, model)));

export function WeightView_viewList(_session, model, dispatch) {
    let value_1;
    const data = sortBy((point) => point.Timestamp, map_1((tupledArg) => {
        const record = tupledArg[1];
        return new DataPoint(record.Id, tupledArg[0].EventTimestamp, new Decimal(record.Weight));
    }, model.Records), {
        Compare: compare,
    });
    let graph;
    if (isEmpty(data)) {
        graph = defaultOf();
    }
    else {
        const properties = ofArray([(value_1 = (100 + "%"), (equals(value_1, 100 + "%") ? true : equals(value_1, 100 + "%")) ? ["width", 99 + "%"] : ["width", value_1]), ["height", 300], ["children", responsiveChart("Weight change over time", data)]]);
        graph = reactApi_1.createElement(ResponsiveContainer, createObj(properties));
    }
    const children = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't fetch data: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append(singleton_1(displayNavigation(model.InProgress, model.InProgress, (_arg) => {
            dispatch(new Msg$1(1, []));
        })), delay(() => append(singleton_1(h4(empty())(singleton("Weight history"))), delay(() => append(singleton_1(h5(singleton(new Option_4(6, [])))(singleton(`Total records: ${length(model.Records)}`))), delay(() => singleton_1(graph)))))))));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

export function BloodPressureView_getEditRoute(_arg, Item) {
    return new Route(26, [Item]);
}

export function BloodPressureView_displayRecord(record) {
    return `${record.Systolic} / ${record.Diastolic} mmHg`;
}

export function TemperatureView_getEditRoute(_arg, Item) {
    return new Route(28, [Item]);
}

export function TemperatureView_displayRecord(record) {
    return format('{0:' + "F2" + '}', record.Temperature) + "℃";
}

export function BodilyFunctionsView_getEditRoute(_arg, Item) {
    return new Route(24, [Item]);
}

const BodilyFunctionsView_service = bodilyFunctionsService;

export const BodilyFunctionsView_actions = new Actions$1(BodilyFunctionsView_service.GetRecords);

export function BodilyFunctionsView_init() {
    const now = toUniversalTime(utcNow());
    return initDateRange(BodilyFunctionsView_actions, false, addDays(now, op_UnaryNegation_Int32(standardLookBackDays)), now);
}

export const BodilyFunctionsView_update = (session) => ((msg) => ((model) => update(session, msg, model)));

function BodilyFunctionsView_getFunctionName(functionType) {
    return defaultArg(map_2((tuple) => tuple[1], tryFind((tupledArg) => (tupledArg[0] === functionType), bodilyFunctionsEmojis)), "<Unknown>");
}

export function BodilyFunctionsView_displayRecord(eventInfo, record) {
    const functionName = BodilyFunctionsView_getFunctionName(record.FunctionType);
    const duration = secondsToHuman(record.DurationSec);
    return `${isDuringSleep(eventInfo, record) ? "🌙 " : ""}${functionName} / ${duration} | ${record.Rating}`;
}

const BodilyFunctionsView_columnsOptions = singleton(new Option_2(4, []));

const BodilyFunctionsView_headerColumOptions = singleton(new Option_3(0, [new Screen(0, []), new ISize(17, [])]));

const BodilyFunctionsView_dataColumOptions = singleton(new Option_3(0, [new Screen(0, []), new ISize(21, [])]));

function BodilyFunctionsView_summaryRow(caption, value) {
    return columns(BodilyFunctionsView_columnsOptions, ofArray([column(BodilyFunctionsView_headerColumOptions, singleton(createElement("strong", {
        children: reactApi.Children.toArray([caption]),
    }))), column(BodilyFunctionsView_dataColumOptions, singleton(value))]));
}

function BodilyFunctionsView_getDays(start, finish) {
    return days_3(op_Subtraction(toDateTime(finish, 0), toDateTime(start, 0)));
}

function BodilyFunctionsView_displaySummaryGroup(bodilyFunctionType, records) {
    return toList(delay(() => {
        const groups = List_groupBy((tupledArg) => fromDateTime(tupledArg[0].EventTimestamp), records, {
            Equals: equals_1,
            GetHashCode: dateHash,
        });
        const counts = map_1((tupledArg_1) => length(tupledArg_1[1]), groups);
        const daysWithSleepEliminations = length_1(filter((tupledArg_2) => exists((tupledArg_3) => isDuringSleep(tupledArg_3[0], tupledArg_3[1]), tupledArg_2[1]), groups)) | 0;
        let average;
        const copyOfStruct = average_1(map_1((value) => value, counts), {
            GetZero: () => 0,
            Add: (x_2, y_1) => (x_2 + y_1),
            DivideByInt: (x_1, i) => (x_1 / i),
        });
        average = format('{0:' + "F2" + '}', copyOfStruct);
        let maximum;
        const copyOfStruct_1 = max(counts, {
            Compare: comparePrimitives,
        }) | 0;
        maximum = format('{0:' + "F2" + '}', copyOfStruct_1);
        const dates = map_1((tuple) => tuple[0], groups);
        const days = BodilyFunctionsView_getDays(min(dates, {
            Compare: compare,
        }), max(dates, {
            Compare: compare,
        })) | 0;
        const func = BodilyFunctionsView_getFunctionName(bodilyFunctionType);
        return append(singleton_1(BodilyFunctionsView_summaryRow(`Average/max ${func}`, `${average} / ${maximum}`)), delay(() => {
            let copyOfStruct_2;
            return ((days > 0) && (daysWithSleepEliminations > 0)) ? singleton_1(BodilyFunctionsView_summaryRow(`During sleep ${func}`, `${(copyOfStruct_2 = ((daysWithSleepEliminations / days) * 100), format('{0:' + "F2" + '}', copyOfStruct_2))}% of days`)) : empty_1();
        }));
    }));
}

function BodilyFunctionsView_displaySummary(model) {
    return collect((tupledArg_1) => BodilyFunctionsView_displaySummaryGroup(tupledArg_1[0], tupledArg_1[1]), List_groupBy((tupledArg) => tupledArg[1].FunctionType, model.Records, {
        Equals: (x, y) => (x === y),
        GetHashCode: numberHash,
    }));
}

function BodilyFunctionsView_displayRecordGroup(actions, date, recordData) {
    let arg;
    return append_1(singleton(createElement("div", {
        className: "divider",
        children: `${toString_1(date)} (${(arg = toDateTime(date, 0), common.getDayName(arg, ""))}): ${length(recordData)} record(s)`,
    })), map_1((tupledArg) => displaySingleRecord(BodilyFunctionsView_displayRecord, actions, tupledArg[0], tupledArg[1]), recordData));
}

function BodilyFunctionsView_displayAllRecords(actions, model) {
    return collect((tupledArg_1) => BodilyFunctionsView_displayRecordGroup(actions, tupledArg_1[0], tupledArg_1[1]), List_groupBy((tupledArg) => fromDateTime(tupledArg[0].EventTimestamp), model.Records, {
        Equals: equals_1,
        GetHashCode: dateHash,
    }));
}

export function BodilyFunctionsView_viewList(session, model, dispatch) {
    const readOnly = value_10(session.UserInfo).ReadOnly;
    const isDisabled = model.InProgress;
    const children_6 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't fetch data: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append(singleton_1(displayNavigation(isDisabled, model.InProgress, (_arg) => {
            dispatch(new Msg$1(1, []));
        })), delay(() => append(singleton_1(h4(empty())(singleton("Elimination history"))), delay(() => append(singleton_1(h5(singleton(new Option_4(6, [])))(singleton(`Total records: ${length(model.Records)}`))), delay(() => {
            let children_2, today, start, finish, days;
            const actions = readOnly ? undefined : [(arg00$0040) => ((Item_1) => BodilyFunctionsView_getEditRoute(arg00$0040, Item_1)), (arg) => {
                dispatch(new Msg$1(5, [arg]));
            }];
            return append(singleton_1((children_2 = append_1(singleton((today = fromDateTime(utcNow()), (start = fromDateTime(value_10(model.DateRange)[0]), (finish = fromDateTime(value_10(model.DateRange)[1]), (days = (BodilyFunctionsView_getDays(start, finish) | 0), columns(BodilyFunctionsView_columnsOptions, toList(delay(() => append(singleton_1(column(BodilyFunctionsView_headerColumOptions, singleton(button(toList(delay(() => {
                const start_1 = value_10(model.DateRange)[0];
                const previous = addDays(start_1, op_UnaryNegation_Int32(standardLookBackDays));
                return append(singleton_1(new Option(0, [new Color_IColor(6, [])])), delay(() => append(singleton_1(new Option(16, [model.InProgress])), delay(() => singleton_1(new Option(18, [(_arg_1) => {
                    dispatch(new Msg$1(2, [previous, start_1]));
                }]))))));
            })), singleton(icon(empty(), singleton(Fa_i_1(singleton(new Fa_IconOption(11, ["fas fa-angle-double-left"])), [])))))))), delay(() => append(singleton_1(column(BodilyFunctionsView_headerColumOptions, singleton(createElement("strong", {
                children: reactApi.Children.toArray(["Period"]),
            })))), delay(() => append(singleton_1(column(BodilyFunctionsView_dataColumOptions, singleton(`${toString_1(start)} - ${toString_1(finish)} / ${days} days`))), delay(() => ((compare(today, finish) > 0) ? singleton_1(column(BodilyFunctionsView_headerColumOptions, singleton(button(toList(delay(() => {
                const finish_1 = value_10(model.DateRange)[1];
                const newFinish_1 = addDays(finish_1, standardLookBackDays);
                return append(singleton_1(new Option(0, [new Color_IColor(6, [])])), delay(() => append(singleton_1(new Option(16, [model.InProgress])), delay(() => singleton_1(new Option(18, [(_arg_2) => {
                    dispatch(new Msg$1(2, [finish_1, newFinish_1]));
                }]))))));
            })), singleton(icon(empty(), singleton(Fa_i_1(singleton(new Fa_IconOption(11, ["fas fa-angle-double-right"])), [])))))))) : empty_1()))))))))))))))), append_1(BodilyFunctionsView_displaySummary(model), BodilyFunctionsView_displayAllRecords(actions, model))), createElement("div", {
                children: reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => (Model$1__CanFetchMore(model) ? singleton_1(button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(16, [isDisabled]), new Option(18, [(_arg_3) => {
                dispatch(new Msg$1(0, []));
            }])]), ofArray([icon(empty(), singleton(Fa_i_1(singleton(new Fa_IconOption(11, ["fas fa-download"])), []))), createElement("span", {
                children: reactApi.Children.toArray(["Load more"]),
            })]))) : empty_1())));
        }))))))));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    });
}

const PhysicalView_service = unifiedService;

export const PhysicalView_actions = new Actions$1(PhysicalView_service.GetRecords);

export function PhysicalView_init() {
    return init(PhysicalView_actions, pageSize, false);
}

export const PhysicalView_update = (session) => ((msg) => ((model) => update(session, msg, model)));

export function PhysicalView_getEditRoute(record) {
    switch (record.tag) {
        case 1:
            return (Item_1) => BloodPressureView_getEditRoute(record.fields[0], Item_1);
        case 2:
            return (Item_2) => BodilyFunctionsView_getEditRoute(record.fields[0], Item_2);
        case 3:
            return (Item_3) => TemperatureView_getEditRoute(record.fields[0], Item_3);
        default:
            return (Item) => WeightView_getEditRoute(record.fields[0], Item);
    }
}

export function PhysicalView_displayRecord(eventInfo, record) {
    switch (record.tag) {
        case 1:
            return BloodPressureView_displayRecord(record.fields[0]);
        case 2:
            return BodilyFunctionsView_displayRecord(eventInfo, record.fields[0]);
        case 3:
            return TemperatureView_displayRecord(record.fields[0]);
        default:
            return WeightView_displayRecord(record.fields[0]);
    }
}

export function PhysicalView_view(session, model, dispatch) {
    return viewCommon("Physical entries", `Total records: ${length(model.Records)}`, uncurry2(PhysicalView_getEditRoute), PhysicalView_displayRecord, session, model, dispatch);
}

