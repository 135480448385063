import { isNullOrWhiteSpace, join, printf, toText, replace } from "../fable-library-js.4.22.0/String.js";
import { mapIndexed as mapIndexed_1, zip, collect, empty, map, delay, tryPick } from "../fable-library-js.4.22.0/Seq.js";
import { equals, getEnumerator, int32ToString, disposeSafe, defaultOf } from "../fable-library-js.4.22.0/Util.js";
import { tryGetValue } from "../fable-library-js.4.22.0/MapUtil.js";
import { toString, Record, FSharpRef } from "../fable-library-js.4.22.0/Types.js";
import { value as value_2, some } from "../fable-library-js.4.22.0/Option.js";
import { record_type, bool_type, tuple_type, option_type, string_type, lambda_type, unit_type, class_type } from "../fable-library-js.4.22.0/Reflection.js";
import { MarkdownUtils_FormattingContext, MarkdownUtils_applySubstitutionsInMarkdown, FableTextWriter_$ctor, FableTextWriter__Write_Z721C83C5, FableTextWriter_$reflection } from "./MarkdownUtils.fs.js";
import { length as length_1, tail, head, isEmpty, mapIndexed, map as map_1, iterate } from "../fable-library-js.4.22.0/List.js";
import { matches } from "../fable-library-js.4.22.0/RegExp.js";
import { MarkdownListKind } from "./MarkdownModel.fs.js";
import { List_iterInterleaved } from "./Collections.fs.js";

/**
 * Basic escaping as done by Markdown
 */
export function htmlEncode(code) {
    return replace(replace(replace(code, "&", "&amp;"), "<", "&lt;"), ">", "&gt;");
}

/**
 * Basic escaping as done by Markdown including quotes
 */
export function htmlEncodeQuotes(code) {
    return replace(htmlEncode(code), "\"", "&quot;");
}

/**
 * Lookup a specified key in a dictionary, possibly
 * ignoring newlines or spaces in the key.
 */
export function $007CLookupKey$007C_$007C(dict, key) {
    return tryPick((key_1) => {
        let matchValue;
        let outArg = defaultOf();
        matchValue = [tryGetValue(dict, key_1, new FSharpRef(() => outArg, (v) => {
            outArg = v;
        })), outArg];
        if (matchValue[0]) {
            return some(matchValue[1]);
        }
        else {
            return undefined;
        }
    }, [key, replace(key, "\r\n", ""), replace(key, "\r\n", " "), replace(key, "\n", ""), replace(key, "\n", " ")]);
}

export class UniqueNameGenerator {
    constructor() {
        this.generated = (new Map([]));
    }
}

export function UniqueNameGenerator_$reflection() {
    return class_type("Fable.Formatting.Markdown.HtmlFormatting.UniqueNameGenerator", undefined, UniqueNameGenerator);
}

export function UniqueNameGenerator_$ctor() {
    return new UniqueNameGenerator();
}

export function UniqueNameGenerator__GetName_Z721C83C5(__, name) {
    let patternInput;
    let outArg = 0;
    patternInput = [tryGetValue(__.generated, name, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    const i = patternInput[1] | 0;
    if (patternInput[0]) {
        __.generated.set(name, i + 1);
        return toText(printf("%s-%d"))(name)(i);
    }
    else {
        __.generated.set(name, 1);
        return name;
    }
}

export class FormattingContext extends Record {
    constructor(LineBreak, Newline, Writer, Links, WrapCodeSnippets, GenerateHeaderAnchors, UniqueNameGenerator, ParagraphIndent) {
        super();
        this.LineBreak = LineBreak;
        this.Newline = Newline;
        this.Writer = Writer;
        this.Links = Links;
        this.WrapCodeSnippets = WrapCodeSnippets;
        this.GenerateHeaderAnchors = GenerateHeaderAnchors;
        this.UniqueNameGenerator = UniqueNameGenerator;
        this.ParagraphIndent = ParagraphIndent;
    }
}

export function FormattingContext_$reflection() {
    return record_type("Fable.Formatting.Markdown.HtmlFormatting.FormattingContext", [], FormattingContext, () => [["LineBreak", lambda_type(unit_type, unit_type)], ["Newline", string_type], ["Writer", FableTextWriter_$reflection()], ["Links", class_type("System.Collections.Generic.IDictionary`2", [string_type, tuple_type(string_type, option_type(string_type))])], ["WrapCodeSnippets", bool_type], ["GenerateHeaderAnchors", bool_type], ["UniqueNameGenerator", UniqueNameGenerator_$reflection()], ["ParagraphIndent", lambda_type(unit_type, unit_type)]]);
}

export function bigBreak(ctx, unitVar) {
    FableTextWriter__Write_Z721C83C5(ctx.Writer, ctx.Newline);
}

export function smallBreak(ctx, unitVar) {
    FableTextWriter__Write_Z721C83C5(ctx.Writer, ctx.Newline);
}

export function noBreak(_ctx, unitVar) {
}

/**
 * Write MarkdownSpan value to a TextWriter
 */
export function formatSpan(ctx, span) {
    let matchResult, body, body_1, id, cmd, str, body_2, link, title;
    switch (span.tag) {
        case 11: {
            matchResult = 0;
            body = span.fields[0];
            break;
        }
        case 10: {
            matchResult = 1;
            body_1 = span.fields[0];
            break;
        }
        case 4: {
            matchResult = 2;
            id = span.fields[0];
            break;
        }
        case 12: {
            matchResult = 3;
            cmd = span.fields[0];
            break;
        }
        case 0: {
            matchResult = 4;
            str = span.fields[0];
            break;
        }
        case 9: {
            matchResult = 5;
            break;
        }
        case 6: {
            const activePatternResult = $007CLookupKey$007C_$007C(ctx.Links, span.fields[2]);
            if (activePatternResult != null) {
                matchResult = 6;
                body_2 = span.fields[0];
                link = activePatternResult[0];
                title = activePatternResult[1];
            }
            else {
                matchResult = 7;
            }
            break;
        }
        case 5: {
            matchResult = 6;
            body_2 = span.fields[0];
            link = span.fields[1];
            title = span.fields[2];
            break;
        }
        default:
            matchResult = 7;
    }
    switch (matchResult) {
        case 0: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ("<span class=\"math\">\\[" + htmlEncode(body)) + "\\]</span>");
            break;
        }
        case 1: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ("<span class=\"math\">\\(" + htmlEncode(body_1)) + "\\)</span>");
            break;
        }
        case 2: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ("<a name=\"" + id) + "\">&#160;</a>");
            break;
        }
        case 3: {
            formatSpans(ctx)(cmd.Render());
            break;
        }
        case 4: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, str);
            break;
        }
        case 5: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<br />" + ctx.Newline);
            break;
        }
        case 6: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<a href=\"");
            FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncode(link));
            if (title != null) {
                const title_1 = title;
                FableTextWriter__Write_Z721C83C5(ctx.Writer, "\" title=\"");
                FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncodeQuotes(title_1));
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "\">");
            formatSpans(ctx)(body_2);
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</a>");
            break;
        }
        case 7: {
            let matchResult_1, body_3, original, body_4, link_1, title_2;
            switch (span.tag) {
                case 6: {
                    matchResult_1 = 0;
                    body_3 = span.fields[0];
                    original = span.fields[1];
                    break;
                }
                case 8: {
                    const activePatternResult_1 = $007CLookupKey$007C_$007C(ctx.Links, span.fields[2]);
                    if (activePatternResult_1 != null) {
                        matchResult_1 = 1;
                        body_4 = span.fields[0];
                        link_1 = activePatternResult_1[0];
                        title_2 = activePatternResult_1[1];
                    }
                    else {
                        matchResult_1 = 2;
                    }
                    break;
                }
                case 7: {
                    matchResult_1 = 1;
                    body_4 = span.fields[0];
                    link_1 = span.fields[1];
                    title_2 = span.fields[2];
                    break;
                }
                default:
                    matchResult_1 = 2;
            }
            switch (matchResult_1) {
                case 0: {
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "[");
                    formatSpans(ctx)(body_3);
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "]");
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, original);
                    break;
                }
                case 1: {
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "<img src=\"");
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncodeQuotes(link_1));
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "\" alt=\"");
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncodeQuotes(body_4));
                    if (title_2 != null) {
                        const title_3 = title_2;
                        FableTextWriter__Write_Z721C83C5(ctx.Writer, "\" title=\"");
                        FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncodeQuotes(title_3));
                    }
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "\" />");
                    break;
                }
                case 2: {
                    switch (span.tag) {
                        case 8: {
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "[");
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, span.fields[0]);
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "]");
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, span.fields[1]);
                            break;
                        }
                        case 2: {
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<strong>");
                            formatSpans(ctx)(span.fields[0]);
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</strong>");
                            break;
                        }
                        case 1: {
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<code>");
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncode(span.fields[0]));
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</code>");
                            break;
                        }
                        case 3: {
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<em>");
                            formatSpans(ctx)(span.fields[0]);
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</em>");
                            break;
                        }
                        default:
                            throw new Error("Match failure: Fable.Formatting.Markdown.MarkdownSpan");
                    }
                    break;
                }
            }
            break;
        }
    }
}

/**
 * Write list of MarkdownSpan values to a TextWriter
 */
export function formatSpans(ctx) {
    return (list) => {
        iterate((span) => {
            formatSpan(ctx, span);
        }, list);
    };
}

/**
 * generate anchor name from Markdown text
 */
export function formatAnchor(ctx, spans) {
    let name;
    const gather = (span) => delay(() => {
        switch (span.tag) {
            case 0:
                return map((m) => m[0], matches(/\w+/gu, span.fields[0]));
            case 2:
                return gathers(span.fields[0]);
            case 3:
                return gathers(span.fields[0]);
            case 5:
                return gathers(span.fields[0]);
            default: {
                return empty();
            }
        }
    });
    const gathers = (spans_1) => collect(gather, spans_1);
    return UniqueNameGenerator__GetName_Z721C83C5(ctx.UniqueNameGenerator, (name = join("-", gathers(spans)), isNullOrWhiteSpace(name) ? "header" : name));
}

export function withInner(ctx, f) {
    const sb = FableTextWriter_$ctor();
    try {
        f(new FormattingContext(ctx.LineBreak, ctx.Newline, sb, ctx.Links, ctx.WrapCodeSnippets, ctx.GenerateHeaderAnchors, ctx.UniqueNameGenerator, ctx.ParagraphIndent));
        return toString(sb);
    }
    finally {
        disposeSafe(sb);
    }
}

/**
 * Write a MarkdownParagraph value to a TextWriter as HTML
 */
export function formatParagraph(ctx, paragraph) {
    switch (paragraph.tag) {
        case 11: {
            formatParagraphs(ctx, paragraph.fields[0].Render());
            break;
        }
        case 0: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ("<h" + int32ToString(paragraph.fields[0])) + ">");
            if (ctx.GenerateHeaderAnchors) {
                const anchorName = formatAnchor(ctx, paragraph.fields[1]);
                FableTextWriter__Write_Z721C83C5(ctx.Writer, toText(printf("<a name=\"%s\" class=\"anchor\" href=\"#%s\">"))(anchorName)(anchorName));
                formatSpans(ctx)(paragraph.fields[1]);
                FableTextWriter__Write_Z721C83C5(ctx.Writer, "</a>");
            }
            else {
                formatSpans(ctx)(paragraph.fields[1]);
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ("</h" + int32ToString(paragraph.fields[0])) + ">");
            break;
        }
        case 1: {
            ctx.ParagraphIndent();
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<p>");
            const enumerator = getEnumerator(paragraph.fields[0]);
            try {
                while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                    formatSpan(ctx, enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]());
                }
            }
            finally {
                disposeSafe(enumerator);
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</p>");
            break;
        }
        case 8: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<hr />");
            break;
        }
        case 2: {
            if (ctx.WrapCodeSnippets) {
                FableTextWriter__Write_Z721C83C5(ctx.Writer, "<table class=\"pre\"><tr><td>");
            }
            if (isNullOrWhiteSpace(paragraph.fields[2])) {
                FableTextWriter__Write_Z721C83C5(ctx.Writer, toText(printf("<pre><code>")));
            }
            else {
                const langCode = toText(printf("language-%s"))(paragraph.fields[2]);
                FableTextWriter__Write_Z721C83C5(ctx.Writer, toText(printf("<pre><code class=\"%s\">"))(langCode));
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncode(paragraph.fields[0]));
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</code></pre>");
            if (ctx.WrapCodeSnippets) {
                FableTextWriter__Write_Z721C83C5(ctx.Writer, "</td></tr></table>");
            }
            break;
        }
        case 13: {
            if (paragraph.fields[1] === "text/html") {
                FableTextWriter__Write_Z721C83C5(ctx.Writer, paragraph.fields[0]);
            }
            else {
                if (ctx.WrapCodeSnippets) {
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "<table class=\"pre\"><tr><td>");
                }
                FableTextWriter__Write_Z721C83C5(ctx.Writer, toText(printf("<pre><code>")));
                FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncode(paragraph.fields[0]));
                FableTextWriter__Write_Z721C83C5(ctx.Writer, "</code></pre>");
                if (ctx.WrapCodeSnippets) {
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "</td></tr></table>");
                }
            }
            break;
        }
        case 9: {
            const aligns = map_1((_arg) => {
                switch (_arg.tag) {
                    case 1:
                        return " align=\"right\"";
                    case 2:
                        return " align=\"center\"";
                    case 3:
                        return "";
                    default:
                        return " align=\"left\"";
                }
            }, paragraph.fields[1]);
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<table>");
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ctx.Newline);
            if (paragraph.fields[0] != null) {
                FableTextWriter__Write_Z721C83C5(ctx.Writer, (("<thead>" + ctx.Newline) + "<tr class=\"header\">") + ctx.Newline);
                const enumerator_1 = getEnumerator(zip(value_2(paragraph.fields[0]), aligns));
                try {
                    while (enumerator_1["System.Collections.IEnumerator.MoveNext"]()) {
                        const forLoopVar = enumerator_1["System.Collections.Generic.IEnumerator`1.get_Current"]();
                        FableTextWriter__Write_Z721C83C5(ctx.Writer, ("<th" + forLoopVar[1]) + ">");
                        const enumerator_2 = getEnumerator(forLoopVar[0]);
                        try {
                            while (enumerator_2["System.Collections.IEnumerator.MoveNext"]()) {
                                formatParagraph(new FormattingContext(() => {
                                    noBreak(ctx, undefined);
                                }, ctx.Newline, ctx.Writer, ctx.Links, ctx.WrapCodeSnippets, ctx.GenerateHeaderAnchors, ctx.UniqueNameGenerator, ctx.ParagraphIndent), enumerator_2["System.Collections.Generic.IEnumerator`1.get_Current"]());
                            }
                        }
                        finally {
                            disposeSafe(enumerator_2);
                        }
                        FableTextWriter__Write_Z721C83C5(ctx.Writer, "</th>" + ctx.Newline);
                    }
                }
                finally {
                    disposeSafe(enumerator_1);
                }
                FableTextWriter__Write_Z721C83C5(ctx.Writer, (("</tr>" + ctx.Newline) + "</thead>") + ctx.Newline);
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<tbody>" + ctx.Newline);
            const enumerator_3 = getEnumerator(mapIndexed((i, r) => [i + 1, r], paragraph.fields[2]));
            try {
                while (enumerator_3["System.Collections.IEnumerator.MoveNext"]()) {
                    const forLoopVar_1 = enumerator_3["System.Collections.Generic.IEnumerator`1.get_Current"]();
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, (("<tr class=\"" + (((forLoopVar_1[0] % 2) === 1) ? "odd" : "even")) + "\">") + ctx.Newline);
                    const enumerator_4 = getEnumerator(zip(forLoopVar_1[1], aligns));
                    try {
                        while (enumerator_4["System.Collections.IEnumerator.MoveNext"]()) {
                            const forLoopVar_2 = enumerator_4["System.Collections.Generic.IEnumerator`1.get_Current"]();
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, ("<td" + forLoopVar_2[1]) + ">");
                            const enumerator_5 = getEnumerator(forLoopVar_2[0]);
                            try {
                                while (enumerator_5["System.Collections.IEnumerator.MoveNext"]()) {
                                    formatParagraph(new FormattingContext(() => {
                                        noBreak(ctx, undefined);
                                    }, ctx.Newline, ctx.Writer, ctx.Links, ctx.WrapCodeSnippets, ctx.GenerateHeaderAnchors, ctx.UniqueNameGenerator, ctx.ParagraphIndent), enumerator_5["System.Collections.Generic.IEnumerator`1.get_Current"]());
                                }
                            }
                            finally {
                                disposeSafe(enumerator_5);
                            }
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</td>" + ctx.Newline);
                        }
                    }
                    finally {
                        disposeSafe(enumerator_4);
                    }
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "</tr>" + ctx.Newline);
                }
            }
            finally {
                disposeSafe(enumerator_3);
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</tbody>" + ctx.Newline);
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</table>");
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ctx.Newline);
            break;
        }
        case 4: {
            const tag = equals(paragraph.fields[0], new MarkdownListKind(0, [])) ? "ol" : "ul";
            FableTextWriter__Write_Z721C83C5(ctx.Writer, (("<" + tag) + ">") + ctx.Newline);
            const enumerator_6 = getEnumerator(paragraph.fields[1]);
            try {
                while (enumerator_6["System.Collections.IEnumerator.MoveNext"]()) {
                    const body_1 = enumerator_6["System.Collections.Generic.IEnumerator`1.get_Current"]();
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "<li>");
                    let matchResult, s_1;
                    if (!isEmpty(body_1)) {
                        if (head(body_1).tag === 1) {
                            if (!isEmpty(head(body_1).fields[0])) {
                                if (head(head(body_1).fields[0]).tag === 0) {
                                    if (isEmpty(tail(head(body_1).fields[0]))) {
                                        if (isEmpty(tail(body_1))) {
                                            if (!(head(head(body_1).fields[0]).fields[0].indexOf(ctx.Newline) >= 0)) {
                                                matchResult = 0;
                                                s_1 = head(head(body_1).fields[0]).fields[0];
                                            }
                                            else {
                                                matchResult = 1;
                                            }
                                        }
                                        else {
                                            matchResult = 1;
                                        }
                                    }
                                    else {
                                        matchResult = 1;
                                    }
                                }
                                else {
                                    matchResult = 1;
                                }
                            }
                            else {
                                matchResult = 1;
                            }
                        }
                        else {
                            matchResult = 1;
                        }
                    }
                    else {
                        matchResult = 1;
                    }
                    switch (matchResult) {
                        case 0: {
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, s_1);
                            break;
                        }
                        case 1: {
                            const inner = withInner(ctx, (ctx_1) => {
                                let ctx_2;
                                List_iterInterleaved((ctx_2 = (new FormattingContext(() => {
                                    noBreak(ctx_1, undefined);
                                }, ctx_1.Newline, ctx_1.Writer, ctx_1.Links, ctx_1.WrapCodeSnippets, ctx_1.GenerateHeaderAnchors, ctx_1.UniqueNameGenerator, ctx_1.ParagraphIndent)), (paragraph_3) => {
                                    formatParagraph(ctx_2, paragraph_3);
                                }), () => {
                                    FableTextWriter__Write_Z721C83C5(ctx_1.Writer, ctx_1.Newline);
                                }, body_1);
                            });
                            FableTextWriter__Write_Z721C83C5(ctx.Writer, (inner.indexOf(ctx.Newline) >= 0) ? ((ctx.Newline + inner) + ctx.Newline) : inner);
                            break;
                        }
                    }
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, "</li>" + ctx.Newline);
                }
            }
            finally {
                disposeSafe(enumerator_6);
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ("</" + tag) + ">");
            break;
        }
        case 5: {
            ctx.ParagraphIndent();
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "<blockquote>" + ctx.Newline);
            formatParagraphs(new FormattingContext(ctx.LineBreak, ctx.Newline, ctx.Writer, ctx.Links, ctx.WrapCodeSnippets, ctx.GenerateHeaderAnchors, ctx.UniqueNameGenerator, () => {
                ctx.ParagraphIndent();
            }), paragraph.fields[0]);
            ctx.ParagraphIndent();
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</blockquote>");
            break;
        }
        case 6: {
            formatSpans(ctx)(paragraph.fields[0]);
            break;
        }
        case 3: {
            FableTextWriter__Write_Z721C83C5(ctx.Writer, paragraph.fields[0]);
            break;
        }
        case 10: {
            if (ctx.WrapCodeSnippets) {
                FableTextWriter__Write_Z721C83C5(ctx.Writer, "<table class=\"pre\"><tr><td>");
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, toText(printf("<pre><code>")));
            const enumerator_7 = getEnumerator(paragraph.fields[0]);
            try {
                while (enumerator_7["System.Collections.IEnumerator.MoveNext"]()) {
                    FableTextWriter__Write_Z721C83C5(ctx.Writer, htmlEncode(enumerator_7["System.Collections.Generic.IEnumerator`1.get_Current"]()[0]));
                }
            }
            finally {
                disposeSafe(enumerator_7);
            }
            FableTextWriter__Write_Z721C83C5(ctx.Writer, "</code></pre>");
            break;
        }
        case 12: {
            break;
        }
        default:
            FableTextWriter__Write_Z721C83C5(ctx.Writer, ("<p><span class=\"math\">\\[" + htmlEncode(join(ctx.Newline, paragraph.fields[1]))) + "\\]</span></p>");
    }
    ctx.LineBreak();
}

/**
 * Write a list of MarkdownParagraph values to a TextWriter
 */
export function formatParagraphs(ctx, paragraphs) {
    const length = length_1(paragraphs) | 0;
    const smallCtx = new FormattingContext(() => {
        smallBreak(ctx, undefined);
    }, ctx.Newline, ctx.Writer, ctx.Links, ctx.WrapCodeSnippets, ctx.GenerateHeaderAnchors, ctx.UniqueNameGenerator, ctx.ParagraphIndent);
    const bigCtx = new FormattingContext(() => {
        bigBreak(ctx, undefined);
    }, ctx.Newline, ctx.Writer, ctx.Links, ctx.WrapCodeSnippets, ctx.GenerateHeaderAnchors, ctx.UniqueNameGenerator, ctx.ParagraphIndent);
    const enumerator = getEnumerator(mapIndexed_1((i, v) => [i === (length - 1), v], paragraphs));
    try {
        while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
            const forLoopVar = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
            formatParagraph(forLoopVar[0] ? smallCtx : bigCtx, forLoopVar[1]);
        }
    }
    finally {
        disposeSafe(enumerator);
    }
}

/**
 * Format Markdown document and write the result to
 * a specified TextWriter. Parameters specify newline character
 * and a dictionary with link keys defined in the document.
 */
export function formatMarkdown(writer, generateAnchors, wrap, links, substitutions, newline, crefResolver, paragraphs) {
    const paragraphs_1 = MarkdownUtils_applySubstitutionsInMarkdown(new MarkdownUtils_FormattingContext(links, newline, substitutions, crefResolver, "HTML"), paragraphs);
    formatParagraphs(new FormattingContext(() => {
    }, newline, writer, links, wrap, generateAnchors, UniqueNameGenerator_$ctor(), () => {
    }), paragraphs_1);
}

