import { ofSeq, empty, tail as tail_6, head, isEmpty, reverse, cons } from "../fable-library-js.4.22.0/List.js";
import { compare, equals } from "../fable-library-js.4.22.0/Util.js";
import { singleton, map, append, empty as empty_1, delay } from "../fable-library-js.4.22.0/Seq.js";
import { Union } from "../fable-library-js.4.22.0/Types.js";
import { union_type, list_type } from "../fable-library-js.4.22.0/Reflection.js";

/**
 * Partitions list into an initial sequence (while the
 * specified predicate returns true) and a rest of the list.
 */
export function List_partitionWhile(p, input) {
    const loop = (acc_mut, _arg_mut) => {
        loop:
        while (true) {
            const acc = acc_mut, _arg = _arg_mut;
            let matchResult, hd_1, tl_1, rest;
            if (!isEmpty(_arg)) {
                if (p(head(_arg))) {
                    matchResult = 0;
                    hd_1 = head(_arg);
                    tl_1 = tail_6(_arg);
                }
                else {
                    matchResult = 1;
                    rest = _arg;
                }
            }
            else {
                matchResult = 1;
                rest = _arg;
            }
            switch (matchResult) {
                case 0: {
                    acc_mut = cons(hd_1, acc);
                    _arg_mut = tl_1;
                    continue loop;
                }
                default:
                    return [reverse(acc), rest];
            }
            break;
        }
    };
    return loop(empty(), input);
}

/**
 * Partitions list into an initial sequence (while the specified predicate
 * returns true) and a rest of the list. The predicate gets the entire
 * tail of the list and can perform lookahead.
 */
export function List_partitionWhileLookahead(p, input) {
    const loop = (acc_mut, _arg_mut) => {
        loop:
        while (true) {
            const acc = acc_mut, _arg = _arg_mut;
            let matchResult, hd_1, tl_1, rest;
            if (!isEmpty(_arg)) {
                if (p(cons(head(_arg), tail_6(_arg)))) {
                    matchResult = 0;
                    hd_1 = head(_arg);
                    tl_1 = tail_6(_arg);
                }
                else {
                    matchResult = 1;
                    rest = _arg;
                }
            }
            else {
                matchResult = 1;
                rest = _arg;
            }
            switch (matchResult) {
                case 0: {
                    acc_mut = cons(hd_1, acc);
                    _arg_mut = tl_1;
                    continue loop;
                }
                default:
                    return [reverse(acc), rest];
            }
            break;
        }
    };
    return loop(empty(), input);
}

/**
 * Partitions list into an initial sequence (while the
 * specified predicate returns 'false') and a rest of the list.
 */
export function List_partitionUntil(p, input) {
    return List_partitionWhile((arg) => !p(arg), input);
}

/**
 * Partitions list into an initial sequence (while the
 * specified predicate returns 'false') and a rest of the list.
 */
export function List_partitionUntilLookahead(p, input) {
    return List_partitionWhileLookahead((arg) => !p(arg), input);
}

/**
 * Iterates over the elements of the list and calls the first function for
 * every element. Between each two elements, the second function is called.
 */
export function List_iterInterleaved(f_mut, g_mut, input_mut) {
    List_iterInterleaved:
    while (true) {
        const f = f_mut, g = g_mut, input = input_mut;
        if (isEmpty(input)) {
        }
        else if (isEmpty(tail_6(input))) {
            f(head(input));
        }
        else {
            f(head(input));
            g();
            f_mut = f;
            g_mut = g;
            input_mut = cons(head(tail_6(input)), tail_6(tail_6(input)));
            continue List_iterInterleaved;
        }
        break;
    }
}

/**
 * Partitions the input list into two parts - the break is added
 * at a point where the list starts with the specified sub-list.
 */
export function List_partitionUntilEquals(endl, input) {
    const loop = (acc_mut, _arg_mut) => {
        let loop_1;
        loop:
        while (true) {
            const acc = acc_mut, _arg = _arg_mut;
            if ((loop_1 = ((start_1_mut, list_1_mut) => {
                loop_1:
                while (true) {
                    const start_1 = start_1_mut, list_1 = list_1_mut;
                    let matchResult, x_1, xs_1, y_1, ys_1;
                    if (isEmpty(start_1)) {
                        matchResult = 1;
                    }
                    else if (!isEmpty(list_1)) {
                        if (equals(head(start_1), head(list_1))) {
                            matchResult = 0;
                            x_1 = head(start_1);
                            xs_1 = tail_6(start_1);
                            y_1 = head(list_1);
                            ys_1 = tail_6(list_1);
                        }
                        else {
                            matchResult = 2;
                        }
                    }
                    else {
                        matchResult = 2;
                    }
                    switch (matchResult) {
                        case 0: {
                            start_1_mut = xs_1;
                            list_1_mut = ys_1;
                            continue loop_1;
                        }
                        case 1:
                            return true;
                        default:
                            return false;
                    }
                    break;
                }
            }), loop_1(endl, _arg))) {
                return [reverse(acc), _arg];
            }
            else if (isEmpty(_arg)) {
                return undefined;
            }
            else {
                acc_mut = cons(head(_arg), acc);
                _arg_mut = tail_6(_arg);
                continue loop;
            }
            break;
        }
    };
    return loop(empty(), input);
}

/**
 * A function that nests items of the input sequence
 * that do not match a specified predicate under the
 * last item that matches the predicate.
 */
export function List_nestUnderLastMatching(f, input) {
    const loop = (input_1) => delay(() => {
        const patternInput = List_partitionUntil(f, input_1);
        const other = patternInput[1];
        const matchValue = reverse(patternInput[0]);
        if (isEmpty(matchValue)) {
            if (equals(other, empty())) {
                return empty_1();
            }
            else {
                throw new Error("Should start with true\\nParameter name: ");
                return empty_1();
            }
        }
        else {
            return append(map((p) => [p, empty()], reverse(tail_6(matchValue))), delay(() => {
                const patternInput_1 = List_partitionUntil((arg) => !f(arg), other);
                return append(singleton([head(matchValue), patternInput_1[0]]), delay(() => loop(patternInput_1[1])));
            }));
        }
    });
    return ofSeq(loop(input));
}

export class Tree$1 extends Union {
    constructor(Item1, Item2) {
        super();
        this.tag = 0;
        this.fields = [Item1, Item2];
    }
    cases() {
        return ["Node"];
    }
}

export function Tree$1_$reflection(gen0) {
    return union_type("Fable.Formatting.Collections.Tree`1", [gen0], Tree$1, () => [[["Item1", gen0], ["Item2", list_type(Tree$1_$reflection(gen0))]]]);
}

function Tree_takeAtLevel(indent, tail) {
    let matchResult, i_1, tail_2, value_1, tail_5;
    if (!isEmpty(tail)) {
        if (compare(head(tail)[0], indent) >= 0) {
            matchResult = 0;
            i_1 = head(tail)[0];
            tail_2 = tail_6(tail);
            value_1 = head(tail)[1];
        }
        else {
            matchResult = 1;
            tail_5 = tail;
        }
    }
    else {
        matchResult = 1;
        tail_5 = tail;
    }
    switch (matchResult) {
        case 0: {
            const patternInput = Tree_takeDeeperThan(i_1, tail_2);
            const patternInput_1 = Tree_takeAtLevel(indent, patternInput[1]);
            return [cons(new Tree$1(value_1, patternInput[0]), patternInput_1[0]), patternInput_1[1]];
        }
        default:
            return [empty(), tail_5];
    }
}

function Tree_takeDeeperThan(indent, tail) {
    let matchResult, i_1, tail_2, value_1, tail_5;
    if (!isEmpty(tail)) {
        if (compare(head(tail)[0], indent) > 0) {
            matchResult = 0;
            i_1 = head(tail)[0];
            tail_2 = tail_6(tail);
            value_1 = head(tail)[1];
        }
        else {
            matchResult = 1;
            tail_5 = tail;
        }
    }
    else {
        matchResult = 1;
        tail_5 = tail;
    }
    switch (matchResult) {
        case 0: {
            const patternInput = Tree_takeDeeperThan(i_1, tail_2);
            const patternInput_1 = Tree_takeAtLevel(i_1, patternInput[1]);
            return [cons(new Tree$1(value_1, patternInput[0]), patternInput_1[0]), patternInput_1[1]];
        }
        default:
            return [empty(), tail_5];
    }
}

/**
 * Turns a list of items with an indentation specified by an integer
 * into a tree where indented items are children.
 */
export function Tree_ofIndentedList(input) {
    const patternInput = Tree_takeAtLevel(0, input);
    if (!equals(patternInput[1], empty())) {
        throw new Error("Wrong indentation");
    }
    return patternInput[0];
}

