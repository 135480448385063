import { Record } from "../fable-library-js.4.22.0/Types.js";
import { record_type, int32_type } from "../fable-library-js.4.22.0/Reflection.js";
import { maxBy, minBy } from "../fable-library-js.4.22.0/List.js";
import { compareArrays } from "../fable-library-js.4.22.0/Util.js";

export class MarkdownRange extends Record {
    constructor(StartLine, StartColumn, EndLine, EndColumn) {
        super();
        this.StartLine = (StartLine | 0);
        this.StartColumn = (StartColumn | 0);
        this.EndLine = (EndLine | 0);
        this.EndColumn = (EndColumn | 0);
    }
}

export function MarkdownRange_$reflection() {
    return record_type("Fable.Formatting.Markdown.MarkdownRange", [], MarkdownRange, () => [["StartLine", int32_type], ["StartColumn", int32_type], ["EndLine", int32_type], ["EndColumn", int32_type]]);
}

export const MarkdownRangeModule_zero = new MarkdownRange(0, 0, 0, 0);

export function MarkdownRangeModule_mergeRanges(ranges) {
    const startRange = minBy((r) => [r.StartLine, r.StartColumn], ranges, {
        Compare: compareArrays,
    });
    const endRange = maxBy((r_1) => [r_1.EndLine, r_1.EndColumn], ranges, {
        Compare: compareArrays,
    });
    return new MarkdownRange(startRange.StartLine, startRange.StartColumn, endRange.EndLine, endRange.EndColumn);
}

