import { Union, Record } from "../fable_modules/fable-library-js.4.22.0/Types.js";
import { Router_modifyLocation, Route_$reflection } from "./Router.js";
import { bool_type, union_type, record_type, int64_type, lambda_type, string_type, option_type } from "../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { Fa_i, Fa_IconOption_$reflection } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { filter, map, empty, ofArray, singleton } from "../fable_modules/fable-library-js.4.22.0/List.js";
import { Session_$reflection } from "./SharedView.js";
import { list as list_3, Option, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Color_IColor, Size_ISize } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { icon as icon_1 } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import * as react from "react";
import { defaultOf } from "../fable_modules/fable-library-js.4.22.0/Util.js";
import { value as value_1 } from "../fable_modules/fable-library-js.4.22.0/Option.js";

export class Actions extends Record {
    constructor(NewNow, NewDate, Edit, View, ViewAll) {
        super();
        this.NewNow = NewNow;
        this.NewDate = NewDate;
        this.Edit = Edit;
        this.View = View;
        this.ViewAll = ViewAll;
    }
}

export function Actions_$reflection() {
    return record_type("Omnicv.Client.Common.ClientModule.Actions", [], Actions, () => [["NewNow", option_type(Route_$reflection())], ["NewDate", option_type(lambda_type(string_type, Route_$reflection()))], ["Edit", option_type(lambda_type(int64_type, Route_$reflection()))], ["View", option_type(lambda_type(int64_type, Route_$reflection()))], ["ViewAll", option_type(Route_$reflection())]]);
}

export class IconType extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["FontAwesome"];
    }
}

export function IconType_$reflection() {
    return union_type("Omnicv.Client.Common.ClientModule.IconType", [], IconType, () => [[["Item", Fa_IconOption_$reflection()]]]);
}

export function IconType__ToIcon(this$) {
    return Fa_i(singleton(this$.fields[0]), []);
}

export class ModuleDescriptor extends Record {
    constructor(Icon, View) {
        super();
        this.Icon = Icon;
        this.View = View;
    }
}

export function ModuleDescriptor_$reflection() {
    return record_type("Omnicv.Client.Common.ClientModule.ModuleDescriptor", [], ModuleDescriptor, () => [["Icon", IconType_$reflection()], ["View", option_type(lambda_type(Session_$reflection(), Route_$reflection()))]]);
}

export class EntryDescriptor extends Record {
    constructor(Name, Caption, Details, Icon, Actions, IsFavourite) {
        super();
        this.Name = Name;
        this.Caption = Caption;
        this.Details = Details;
        this.Icon = Icon;
        this.Actions = Actions;
        this.IsFavourite = IsFavourite;
    }
}

export function EntryDescriptor_$reflection() {
    return record_type("Omnicv.Client.Common.ClientModule.EntryDescriptor", [], EntryDescriptor, () => [["Name", string_type], ["Caption", string_type], ["Details", string_type], ["Icon", IconType_$reflection()], ["Actions", Actions_$reflection()], ["IsFavourite", bool_type]]);
}

export function EntryDescriptor__GetButton(this$, colour, isDisabled, onClick) {
    let children;
    const children_4 = [(children = [button(ofArray([new Option(0, [colour]), new Option(16, [isDisabled]), new Option(18, [onClick]), new Option(1, [new Size_ISize(2, [])])]), singleton(icon_1(empty(), singleton(IconType__ToIcon(this$.Icon)))))], react.createElement("div", {}, ...children)), react.createElement("div", {
        className: "button-caption",
    }, this$.Caption)];
    return react.createElement("div", {
        className: "button-wrapper",
    }, ...children_4);
}

export function EntryDescriptor__GetNewDateButton(this$, isDisabled, param) {
    const matchValue = this$.Actions.NewDate;
    if (matchValue != null) {
        const route = matchValue;
        return EntryDescriptor__GetButton(this$, new Color_IColor(6, []), isDisabled, (_arg) => {
            Router_modifyLocation(route(param));
        });
    }
    else {
        return defaultOf();
    }
}

export function EntryDescriptor__GetNewNowButton_Z1FBCCD16(this$, isDisabled) {
    const matchValue = this$.Actions.NewNow;
    if (matchValue != null) {
        const route = matchValue;
        return EntryDescriptor__GetButton(this$, new Color_IColor(6, []), isDisabled, (_arg) => {
            Router_modifyLocation(route);
        });
    }
    else {
        return defaultOf();
    }
}

export function getModuleNewDateButtons(clientModule, isDisabled, param) {
    const children = [list_3(empty(), map((des) => EntryDescriptor__GetNewDateButton(des, isDisabled, param), clientModule.EntryDescriptors()))];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

export function getModuleNewNowButtons(clientModule, isDisabled) {
    const children = [list_3(empty(), map((des) => EntryDescriptor__GetNewNowButton_Z1FBCCD16(des, isDisabled), clientModule.EntryDescriptors()))];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

export function getModuleViewAllButtons(clientModule, isDisabled) {
    const children = [list_3(empty(), map(value_1, filter((option) => (option != null), map((des) => {
        const matchValue = des.Actions.ViewAll;
        if (matchValue != null) {
            const route = matchValue;
            return EntryDescriptor__GetButton(des, new Color_IColor(5, []), isDisabled, (_arg) => {
                Router_modifyLocation(route);
            });
        }
        else {
            return undefined;
        }
    }, clientModule.EntryDescriptors()))))];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

