import { Fa_IconOption } from "./fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { EntryDescriptor, Actions, ModuleDescriptor, IconType } from "./Omnicv.Client.Common/ClientModule.js";
import { Route } from "./Omnicv.Client.Common/Router.js";
import { ofArray } from "./fable_modules/fable-library-js.4.22.0/List.js";
import { FitnessModule_$reflection, FitnessModule } from "./Omnicv.Shared/FitnessAPI.js";
import { class_type } from "./fable_modules/fable-library-js.4.22.0/Reflection.js";

export class FitnessClientModule extends FitnessModule {
    constructor() {
        super();
    }
    Descriptor() {
        return new ModuleDescriptor(new IconType(new Fa_IconOption(11, ["fas fa-running"])), (_arg) => (new Route(32, [])));
    }
    EntryDescriptors() {
        return ofArray([new EntryDescriptor("RepsWithWeightRecord", "Reps with weight", "Record reps of weight lifting exercises", new IconType(new Fa_IconOption(11, ["fas fa-dumbbell"])), new Actions(undefined, undefined, undefined, undefined, undefined), false), new EntryDescriptor("RepsWithBodyWeightRecord", "Reps with body weight", "Record reps of body weight exercises", new IconType(new Fa_IconOption(11, ["fas fa-walking"])), new Actions(undefined, undefined, undefined, undefined, undefined), false), new EntryDescriptor("EnduranceRecord", "Endurance exercises", "Record workouts for endurance exercises", new IconType(new Fa_IconOption(11, ["fas fa-swimmer"])), new Actions(undefined, undefined, undefined, undefined, undefined), false), new EntryDescriptor("IntervalsDurationRecord", "Interval/HIIT duration exercises", "Record workouts for time-based interval/circuit/HIIT exercises", new IconType(new Fa_IconOption(11, ["fas fa-stopwatch"])), new Actions(undefined, undefined, undefined, undefined, undefined), false), new EntryDescriptor("IntervalsRepsRecord", "Interval/HIIT reps exercises", "Record workouts for reps-based interval/circuit/HIIT exercises", new IconType(new Fa_IconOption(11, ["fas fa-digital-tachograph"])), new Actions(undefined, undefined, undefined, undefined, undefined), false), new EntryDescriptor("WorkoutRecord", "Record a complete workout", "Record all exercises for a single workout", new IconType(new Fa_IconOption(11, ["fas fa-dumbbell"])), new Actions(undefined, undefined, undefined, undefined, undefined), false)]);
    }
}

export function FitnessClientModule_$reflection() {
    return class_type("Omnicv.Client.Fitness.FitnessClientModule", undefined, FitnessClientModule, FitnessModule_$reflection());
}

export function FitnessClientModule_$ctor() {
    return new FitnessClientModule();
}

