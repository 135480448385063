import { split, replace, trimStart, trimEnd, substring, isNullOrWhiteSpace } from "../fable-library-js.4.22.0/String.js";
import { some } from "../fable-library-js.4.22.0/Option.js";
import { takeWhile as takeWhile_1, skipWhile, toArray, empty, reverse, cons, map as map_1, ofArray, tail, head, isEmpty } from "../fable-library-js.4.22.0/List.js";
import { forAll, empty as empty_1, singleton, collect, delay, toList, windowed, exists, min, isEmpty as isEmpty_1, filter, takeWhile, length, map } from "../fable-library-js.4.22.0/Seq.js";
import { isLetter, isWhiteSpace } from "../fable-library-js.4.22.0/Char.js";
import { defaultOf, equals, comparePrimitives } from "../fable-library-js.4.22.0/Util.js";
import { MarkdownRange } from "./Range.fs.js";
import { List_partitionWhile } from "./Collections.fs.js";
import { item } from "../fable-library-js.4.22.0/Array.js";
import { tryGetValue } from "../fable-library-js.4.22.0/MapUtil.js";
import { FSharpRef } from "../fable-library-js.4.22.0/Types.js";

export function Log_warn(s) {
    console.log(s);
}

/**
 * Matches when a string is a whitespace or null
 */
export function String_$007CWhiteSpace$007C_$007C(s) {
    if (isNullOrWhiteSpace(s)) {
        return some(undefined);
    }
    else {
        return undefined;
    }
}

/**
 * Returns a string trimmed from both start and end
 */
export function String_$007CTrimBoth$007C(text) {
    return text.trim();
}

/**
 * Matches when a string starts with the specified sub-string
 */
export function String_$007CStartsWith$007C_$007C(start, text) {
    if (text.startsWith(start)) {
        return substring(text, start.length);
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with the specified sub-string
 * The matched string is trimmed from all whitespace.
 */
export function String_$007CStartsWithTrim$007C_$007C(start, text) {
    if (text.startsWith(start)) {
        return substring(text, start.length).trim();
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with the given value and ends
 * with a given value (and returns the rest of it)
 */
export function String_$007CStartsAndEndsWith$007C_$007C(starts, ends, s) {
    if ((s.startsWith(starts) && s.endsWith(ends)) && (s.length >= (starts.length + ends.length))) {
        return substring(s, starts.length, (s.length - starts.length) - ends.length);
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with the given value and ends
 * with a given value (and returns trimmed body)
 */
export function String_$007CStartsAndEndsWithTrim$007C_$007C(args_, args__1, _arg) {
    let activePatternResult;
    const tupledArg = [args_, args__1];
    activePatternResult = String_$007CStartsAndEndsWith$007C_$007C(tupledArg[0], tupledArg[1], _arg);
    if (activePatternResult != null) {
        const activePatternResult_1 = String_$007CTrimBoth$007C(activePatternResult);
        return activePatternResult_1;
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with a sub-string wrapped using the
 * opening and closing sub-string specified in the parameter.
 * For example "[aa]bc" is wrapped in [ and ] pair. Returns the wrapped
 * text together with the rest.
 */
export function String_$007CStartsWithWrapped$007C_$007C(starts, ends, text) {
    if (text.startsWith(starts)) {
        const id = text.indexOf(ends, starts.length) | 0;
        if (id >= 0) {
            return [substring(text, starts.length, id - starts.length), substring(text, id + ends.length, (text.length - id) - ends.length)];
        }
        else {
            return undefined;
        }
    }
    else {
        return undefined;
    }
}

/**
 * Ignores everything until a end-line character is detected, returns the remaining string.
 */
export function String_$007CSkipSingleLine$007C(text) {
    const tryEol = (eolList_mut) => {
        tryEol:
        while (true) {
            const eolList = eolList_mut;
            if (!isEmpty(eolList)) {
                const h = head(eolList);
                const matchValue = text.indexOf(h) | 0;
                if (matchValue < 0) {
                    eolList_mut = tail(eolList);
                    continue tryEol;
                }
                else {
                    return substring(text, matchValue + h.length);
                }
            }
            else {
                return text;
            }
            break;
        }
    };
    const result = tryEol(ofArray(["\r\n", "\n"]));
    const skipped = substring(text, 0, text.length - result.length);
    if (!isNullOrWhiteSpace(skipped)) {
        Log_warn(`skipped '${skipped}' which contains non-whitespace character!`);
    }
    if (result === text) {
        Log_warn(`could not skip a line of ${text}, because no line-ending character was found!`);
    }
    return result;
}

/**
 * Given a list of lines indented with certan number of whitespace
 * characters (spaces), remove the spaces from the beginning of each line
 * and return the string as a list of lines
 */
export function String_removeSpaces(lines) {
    let spaces;
    const xs = map((line) => length(takeWhile(isWhiteSpace, line.split(""))), filter((arg) => !isNullOrWhiteSpace(arg), lines));
    spaces = (isEmpty_1(xs) ? 0 : min(xs, {
        Compare: comparePrimitives,
    }));
    return map_1((line_1) => {
        if (isNullOrWhiteSpace(line_1)) {
            return "";
        }
        else {
            return substring(line_1, spaces);
        }
    }, lines);
}

/**
 * Matches when a string is a whitespace or null
 */
export function StringPosition_$007CWhiteSpace$007C_$007C(s, _n) {
    if (isNullOrWhiteSpace(s)) {
        return some(undefined);
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string does starts with non-whitespace
 */
export function StringPosition_$007CUnindented$007C_$007C(s, _n) {
    if (!isNullOrWhiteSpace(s) && (s.trimStart() === s)) {
        return some(undefined);
    }
    else {
        return undefined;
    }
}

/**
 * Returns a string trimmed from both start and end
 */
export function StringPosition_$007CTrimBoth$007C(text, n) {
    let inputRecord;
    const trimmedStart = text.trimStart();
    const trimmed = trimmedStart.trimEnd();
    return [trimmed, (inputRecord = n, new MarkdownRange(inputRecord.StartLine, (n.StartColumn + text.length) - trimmedStart.length, inputRecord.EndLine, (n.EndColumn - trimmedStart.length) + trimmed.length))];
}

/**
 * Returns a string trimmed from the end
 */
export function StringPosition_$007CTrimEnd$007C(text, n) {
    let inputRecord;
    const trimmed = text.trimEnd();
    return [trimmed, (inputRecord = n, new MarkdownRange(inputRecord.StartLine, inputRecord.StartColumn, inputRecord.EndLine, (n.EndColumn - text.length) + trimmed.length))];
}

/**
 * Returns a string trimmed from the start
 */
export function StringPosition_$007CTrimStart$007C(text, n) {
    let inputRecord;
    const trimmed = text.trimStart();
    return [trimmed, (inputRecord = n, new MarkdownRange(inputRecord.StartLine, (n.StartColumn + text.length) - trimmed.length, inputRecord.EndLine, inputRecord.EndColumn))];
}

/**
 * Returns a string trimmed from the end using characters given as a parameter
 */
export function StringPosition_$007CTrimEndUsing$007C(chars, text, n) {
    let inputRecord;
    const trimmed = trimEnd(text, ...Array.from(chars));
    return [trimmed, (inputRecord = n, new MarkdownRange(inputRecord.StartLine, inputRecord.StartColumn, inputRecord.EndLine, (n.EndColumn - text.length) + trimmed.length))];
}

/**
 * Returns a string trimmed from the start together with
 * the number of skipped whitespace characters
 */
export function StringPosition_$007CTrimStartAndCount$007C(text, n) {
    let inputRecord;
    const trimmed = trimStart(text, " ", "\t");
    const len = (text.length - trimmed.length) | 0;
    return [len, replace(substring(text, 0, len), "\t", "    ").length, [trimmed, (inputRecord = n, new MarkdownRange(inputRecord.StartLine, (n.StartColumn + text.length) - trimmed.length, inputRecord.EndLine, inputRecord.EndColumn))]];
}

/**
 * Matches when a string starts with any of the specified sub-strings
 */
export function StringPosition_$007CStartsWithAny$007C_$007C(starts, text, _n) {
    if (exists((value) => text.startsWith(value), starts)) {
        return some(undefined);
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with the specified sub-string
 */
export function StringPosition_$007CStartsWith$007C_$007C(start, text, n) {
    let inputRecord;
    if (text.startsWith(start)) {
        return [substring(text, start.length), (inputRecord = n, new MarkdownRange(inputRecord.StartLine, (n.StartColumn + text.length) - start.length, inputRecord.EndLine, inputRecord.EndColumn))];
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with the specified sub-string
 * The matched string is trimmed from all whitespace.
 */
export function StringPosition_$007CStartsWithTrim$007C_$007C(start, text, n) {
    let inputRecord;
    if (text.startsWith(start)) {
        return [substring(text, start.length).trim(), (inputRecord = n, new MarkdownRange(inputRecord.StartLine, (n.StartColumn + text.length) - start.length, inputRecord.EndLine, inputRecord.EndColumn))];
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with the specified sub-string (ignoring whitespace at the start)
 * The matched string is trimmed from all whitespace.
 */
export function StringPosition_$007CStartsWithNTimesTrimIgnoreStartWhitespace$007C_$007C(start, text, _n) {
    if (text.indexOf(start) >= 0) {
        const beforeStart = substring(text, 0, text.indexOf(start));
        if (isNullOrWhiteSpace(beforeStart)) {
            const startAndRest = substring(text, beforeStart.length);
            const startNum = length(takeWhile((y) => (start === y), map((chars) => (chars.join('')), windowed(start.length, startAndRest.split(""))))) | 0;
            return [startNum, beforeStart.length, substring(text, beforeStart.length + (start.length * startNum)).trim()];
        }
        else {
            return undefined;
        }
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with the given value and ends
 * with a given value (and returns the rest of it)
 */
export function StringPosition_$007CStartsAndEndsWith$007C_$007C(starts, ends, s, n) {
    let inputRecord;
    if ((s.startsWith(starts) && s.endsWith(ends)) && (s.length >= (starts.length + ends.length))) {
        return [substring(s, starts.length, (s.length - starts.length) - ends.length), (inputRecord = n, new MarkdownRange(inputRecord.StartLine, (n.StartColumn + s.length) - starts.length, inputRecord.EndLine, (n.EndColumn - s.length) + ends.length))];
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with the given value and ends
 * with a given value (and returns trimmed body)
 */
export function StringPosition_$007CStartsAndEndsWithTrim$007C_$007C(args_, args__1, _arg1_, _arg1__1) {
    const _arg = [_arg1_, _arg1__1];
    let activePatternResult;
    const tupledArg = [args_, args__1];
    activePatternResult = StringPosition_$007CStartsAndEndsWith$007C_$007C(tupledArg[0], tupledArg[1], _arg[0], _arg[1]);
    if (activePatternResult != null) {
        let activePatternResult_1;
        const tupledArg_1 = activePatternResult;
        activePatternResult_1 = StringPosition_$007CTrimBoth$007C(tupledArg_1[0], tupledArg_1[1]);
        return activePatternResult_1;
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string starts with a non-zero number of complete
 * repetitions of the specified parameter (and returns the number
 * of repetitions, together with the rest of the string)
 * 
 * let (StartsWithRepeated "/\" (2, " abc")) = "/\/\ abc"
 */
export function StringPosition_$007CStartsWithRepeated$007C_$007C(repeated, text, ln) {
    let inputRecord;
    const loop = (i_mut) => {
        loop:
        while (true) {
            const i = i_mut;
            if (i === text.length) {
                return i | 0;
            }
            else if (text[i] !== repeated[i % repeated.length]) {
                return i | 0;
            }
            else {
                i_mut = (i + 1);
                continue loop;
            }
            break;
        }
    };
    const n = loop(0) | 0;
    if ((n === 0) ? true : ((n % repeated.length) !== 0)) {
        return undefined;
    }
    else {
        return [~~(n / repeated.length), [substring(text, n, text.length - n), (inputRecord = ln, new MarkdownRange(inputRecord.StartLine, n, inputRecord.EndLine, inputRecord.EndColumn))]];
    }
}

/**
 * Matches when a string starts with a sub-string wrapped using the
 * opening and closing sub-string specified in the parameter.
 * For example "[aa]bc" is wrapped in [ and ] pair. Returns the wrapped
 * text together with the rest.
 */
export function StringPosition_$007CStartsWithWrapped$007C_$007C(starts, ends, text, n) {
    let inputRecord;
    if (text.startsWith(starts)) {
        const id = text.indexOf(ends, starts.length) | 0;
        if (id >= 0) {
            return [substring(text, starts.length, id - starts.length), [substring(text, id + ends.length, (text.length - id) - ends.length), (inputRecord = n, new MarkdownRange(inputRecord.StartLine, id + ends.length, inputRecord.EndLine, inputRecord.EndColumn))]];
        }
        else {
            return undefined;
        }
    }
    else {
        return undefined;
    }
}

/**
 * Matches when a string consists of some number of
 * complete repetitions of a specified sub-string.
 */
export function StringPosition_$007CEqualsRepeated$007C_$007C(repeated, _n, _arg1_, _arg1__1) {
    const _arg = [_arg1_, _arg1__1];
    let matchResult, _n_1;
    const activePatternResult = StringPosition_$007CStartsWithRepeated$007C_$007C(repeated, _arg[0], _arg[1]);
    if (activePatternResult != null) {
        if (activePatternResult[1][0] === "") {
            matchResult = 0;
            _n_1 = activePatternResult[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return some(undefined);
        default:
            return undefined;
    }
}

/**
 * Matches a list if it starts with a bracketed list. Nested brackets
 * are skipped (by counting opening and closing brackets) and can be
 * escaped using the '\' symbol.
 */
export function List_$007CBracketDelimited$007C_$007C(startc, endc, input) {
    const loop = (acc_mut, count_mut, _arg_mut) => {
        loop:
        while (true) {
            const acc = acc_mut, count = count_mut, _arg = _arg_mut;
            let matchResult, x_10, xs_10, x_11, xs_11, x_12, xs_12, x_13, xs_13, x_14, xs_14;
            if (isEmpty(_arg)) {
                matchResult = 5;
            }
            else if (head(_arg) === "\\") {
                if (!isEmpty(tail(_arg))) {
                    if (head(tail(_arg)) === endc) {
                        matchResult = 0;
                        x_10 = head(tail(_arg));
                        xs_10 = tail(tail(_arg));
                    }
                    else if ((head(_arg) === endc) && (count === 0)) {
                        matchResult = 1;
                        x_11 = head(_arg);
                        xs_11 = tail(_arg);
                    }
                    else if (head(_arg) === endc) {
                        matchResult = 2;
                        x_12 = head(_arg);
                        xs_12 = tail(_arg);
                    }
                    else if (head(_arg) === startc) {
                        matchResult = 3;
                        x_13 = head(_arg);
                        xs_13 = tail(_arg);
                    }
                    else {
                        matchResult = 4;
                        x_14 = head(_arg);
                        xs_14 = tail(_arg);
                    }
                }
                else if ((head(_arg) === endc) && (count === 0)) {
                    matchResult = 1;
                    x_11 = head(_arg);
                    xs_11 = tail(_arg);
                }
                else if (head(_arg) === endc) {
                    matchResult = 2;
                    x_12 = head(_arg);
                    xs_12 = tail(_arg);
                }
                else if (head(_arg) === startc) {
                    matchResult = 3;
                    x_13 = head(_arg);
                    xs_13 = tail(_arg);
                }
                else {
                    matchResult = 4;
                    x_14 = head(_arg);
                    xs_14 = tail(_arg);
                }
            }
            else if ((head(_arg) === endc) && (count === 0)) {
                matchResult = 1;
                x_11 = head(_arg);
                xs_11 = tail(_arg);
            }
            else if (head(_arg) === endc) {
                matchResult = 2;
                x_12 = head(_arg);
                xs_12 = tail(_arg);
            }
            else if (head(_arg) === startc) {
                matchResult = 3;
                x_13 = head(_arg);
                xs_13 = tail(_arg);
            }
            else {
                matchResult = 4;
                x_14 = head(_arg);
                xs_14 = tail(_arg);
            }
            switch (matchResult) {
                case 0: {
                    acc_mut = cons(x_10, acc);
                    count_mut = count;
                    _arg_mut = xs_10;
                    continue loop;
                }
                case 1:
                    return [reverse(acc), xs_11];
                case 2: {
                    acc_mut = cons(x_12, acc);
                    count_mut = (count - 1);
                    _arg_mut = xs_12;
                    continue loop;
                }
                case 3: {
                    acc_mut = cons(x_13, acc);
                    count_mut = (count + 1);
                    _arg_mut = xs_13;
                    continue loop;
                }
                case 4: {
                    acc_mut = cons(x_14, acc);
                    count_mut = count;
                    _arg_mut = xs_14;
                    continue loop;
                }
                default:
                    return undefined;
            }
            break;
        }
    };
    let matchResult_1, x_16, xs_16;
    if (!isEmpty(input)) {
        if (head(input) === startc) {
            matchResult_1 = 0;
            x_16 = head(input);
            xs_16 = tail(input);
        }
        else {
            matchResult_1 = 1;
        }
    }
    else {
        matchResult_1 = 1;
    }
    switch (matchResult_1) {
        case 0:
            return loop(empty(), 0, xs_16);
        default:
            return undefined;
    }
}

/**
 * Returns a list of characters as a string.
 */
export function List_$007CAsString$007C(chars) {
    return toArray(chars).join('');
}

/**
 * Removes blank lines from the start and the end of a list
 */
export function Lines_$007CTrimBlank$007C(lines) {
    return reverse(skipWhile((tupledArg_1) => isNullOrWhiteSpace(tupledArg_1[0]), reverse(skipWhile((tupledArg) => isNullOrWhiteSpace(tupledArg[0]), lines))));
}

/**
 * Matches when there are some lines at the beginning that are
 * either empty (or whitespace) or start with the specified string.
 * Returns all such lines from the beginning until a different line.
 */
export function Lines_$007CTakeStartingWithOrBlank$007C_$007C(start, input) {
    const matchValue = List_partitionWhile((s) => {
        if (isNullOrWhiteSpace(s)) {
            return true;
        }
        else {
            return s.startsWith(start);
        }
    }, input);
    if (!equals(matchValue[0], empty())) {
        return [matchValue[0], matchValue[1]];
    }
    else {
        return undefined;
    }
}

/**
 * Matches when there are some lines at the beginning that are
 * either empty (or whitespace) or start with at least 4 spaces (a tab counts as 4 spaces here).
 * Returns all such lines from the beginning until a different line and
 * the number of spaces the first line started with.
 */
export function Lines_$007CTakeCodeBlock$007C_$007C(input) {
    const matchValue = List_partitionWhile((tupledArg) => {
        const s_1 = tupledArg[0];
        if (isNullOrWhiteSpace(s_1)) {
            return true;
        }
        else {
            const normalized = replace(s_1, "\t", "    ");
            return (normalized.length >= 4) && (substring(normalized, 0, 4) === (Array(4 + 1).join(" ")));
        }
    }, input);
    if (!equals(matchValue[0], empty()) && (4 >= 4)) {
        return [4, matchValue[0], matchValue[1]];
    }
    else {
        return undefined;
    }
}

/**
 * Removes whitespace lines from the beginning of the list
 */
export function Lines_$007CTrimBlankStart$007C(lines) {
    return [takeWhile_1((tupledArg) => isNullOrWhiteSpace(tupledArg[0]), lines), skipWhile((tupledArg_1) => isNullOrWhiteSpace(tupledArg_1[0]), lines)];
}

/**
 * Trims all lines of the current paragraph
 */
export function Lines_$007CTrimParagraphLines$007C(lines) {
    return map_1((tupledArg_1) => {
        let inputRecord;
        const s = tupledArg_1[0];
        const n_1 = tupledArg_1[1];
        const endsWithTwoSpaces = s.endsWith("  ");
        const trimmed = trimEnd(s, " ") + (endsWithTwoSpaces ? "  " : "");
        return [trimmed, (inputRecord = n_1, new MarkdownRange(inputRecord.StartLine, inputRecord.StartColumn, inputRecord.EndLine, (n_1.EndColumn - s.length) + trimmed.length))];
    }, map_1((tupledArg) => StringPosition_$007CTrimStart$007C(tupledArg[0], tupledArg[1]), lines));
}

/**
 * Parameterized pattern that assigns the specified value to the
 * first component of a tuple. Usage:
 * 
 * match str with
 * | Let 1 (n, "one") | Let 2 (n, "two") -> n
 */
export function $007CLet$007C(a, b) {
    return [a, b];
}

/**
 * Utility for parsing commands. Commands can be used in different places. We
 * recognize `key1=value, key2=value` and also `key1:value, key2:value`
 * The key of the command should be identifier with just
 * characters in it - otherwise, the parsing fails.
 */
export function $007CParseCommands$007C_$007C(str) {
    const kvs = toList(delay(() => collect((cmd) => {
        const kv = split(cmd, ["=", ":"]);
        return (kv.length === 2) ? singleton([item(0, kv).trim(), item(1, kv).trim()]) : ((kv.length === 1) ? singleton([item(0, kv).trim(), ""]) : empty_1());
    }, split(str, [","], undefined, 0))));
    if (forAll((arg) => forAll((c) => {
        if (isLetter(c) ? true : (c === "_")) {
            return true;
        }
        else {
            return c === "-";
        }
    }, arg[0].split("")), kvs) && !equals(kvs, empty())) {
        return new Map(kvs);
    }
    else {
        return undefined;
    }
}

/**
 * Utility for parsing commands - this deals with a single command.
 * The key of the command should be identifier with just
 * characters in it - otherwise, the parsing fails.
 */
export function $007CParseCommand$007C_$007C(cmd) {
    const kv = split(cmd, ["=", ":"]);
    if ((kv.length >= 1) && !forAll(isLetter, item(0, kv).split(""))) {
        return undefined;
    }
    else {
        switch (kv.length) {
            case 2:
                return [item(0, kv).trim(), item(1, kv).trim()];
            case 1:
                return [item(0, kv).trim(), ""];
            default:
                return undefined;
        }
    }
}

/**
 * Lookup in a dictionary
 */
export function $007CCommand$007C_$007C(k, d) {
    let matchValue;
    let outArg = defaultOf();
    matchValue = [tryGetValue(d, k, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return some(matchValue[1]);
    }
    else {
        return undefined;
    }
}

