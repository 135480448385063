import { map, defaultArg } from "../../../fable_modules/fable-library-js.4.22.0/Option.js";
import { EditHelperData$1__GetFrequentTags } from "../../Omnicv.Diary.Shared/DiaryAPI.js";
import { empty } from "../../../fable_modules/fable-library-js.4.22.0/List.js";
import { class_type } from "../../../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { dateToStrForDiary, dateStrToDateTime } from "../../../Omnicv.Client.Common/Utils.js";
import { Model$2, Msg$2, view, Model$2__IsEditing, update, initEditWithHelperData, initNew } from "../../../Omnicv.Client.Common/Components/AddEditRecordFlexFormBase.js";
import { Router_modifyLocation, Route, Router_newUrl } from "../../../Omnicv.Client.Common/Router.js";
import { div } from "../../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.22.0/Seq.js";
import { ofList } from "../../../fable_modules/fable-library-js.4.22.0/Set.js";
import { toClientTreeView, addTag as addTag_1, toClientListView } from "../../../Omnicv.Shared.Common/TagUtils.js";
import { comparePrimitives } from "../../../fable_modules/fable-library-js.4.22.0/Util.js";
import { renderTagTree } from "../DiaryClientUtils.js";
import { empty as empty_1 } from "../../../fable_modules/fable-library-js.4.22.0/Map.js";
import { date } from "../../../fable_modules/fable-library-js.4.22.0/Date.js";
import { PagingOptionsModule_defaultPagingOptions, EventInfo } from "../../../Omnicv.Shared.Common/CoreEventInfo.js";
import { Msg, Model } from "../../../Omnicv.Client.Common/Components/EventInfoManager.js";
import { Session__GetCurrentProfileId } from "../../../Omnicv.Client.Common/SharedView.js";
import { FlexFormFields$1__AddSelection_Z70244BEA, FlexFormFields$1__AddDurationSecs_ZAAC24, FlexFormFields$1__AddRating_Z716E223C, FlexFormBase$1__set_SubmitCaption_Z721C83C5, FlexFormTuple$2__get_FieldsSnd, FlexFormFields$1__AddText_Z44011C05, FlexFormFields$1__AddTextArea_Z44011C05, noValidation, FlexFormFields$1__AddDateTimeOpt_C9F34CC, FlexFormFields$1__AddTextAreaOpt_Z44636648, FlexFormFields$1__AddTags_Z63D88B1E, getSuggestions, addEventInfoToFlexFormEim, FlexFormTuple$2_$ctor_5AE4FFF0 } from "../../../Omnicv.Client.Common/Components/FlexForm.js";
import { interactionService, reviewService, diaryService } from "../Server.js";
import { interactionTypes, InteractionRecordModule_validateParticipants, InteractionRecord, InteractionRecord_Default, validateRating, ReviewRecord, ReviewRecord_Default, validateTagline, validateNotes, validateFollowupNotes, validateTags, DiaryRecord, DiaryRecord_Default } from "../../Omnicv.Diary.Shared/Data.js";

function extractFrequentTags(model) {
    return defaultArg(map(EditHelperData$1__GetFrequentTags, model.EditHelperData), empty());
}

export class DiaryEditingView$1 {
    constructor(service, getDefault, tagsGetter, tagsSetter, addTitle, editTitle, buildFormMain) {
        this.service = service;
        this.getDefault = getDefault;
        this.tagsGetter = tagsGetter;
        this.tagsSetter = tagsSetter;
        this.addTitle = addTitle;
        this.editTitle = editTitle;
        this.buildFormMain = buildFormMain;
    }
}

export function DiaryEditingView$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Pages.Diary.AddEditDiaryView.Component.Diary.DiaryEditingView`1", [gen0], DiaryEditingView$1);
}

export function DiaryEditingView$1_$ctor_6E4FAFB9(service, getDefault, tagsGetter, tagsSetter, addTitle, editTitle, buildFormMain) {
    return new DiaryEditingView$1(service, getDefault, tagsGetter, tagsSetter, addTitle, editTitle, buildFormMain);
}

export function DiaryEditingView$1__InitNew(this$, session, dateStr) {
    const dateTime = dateStrToDateTime(dateStr);
    const patternInput = initNew(this$.getDefault(), () => DiaryEditingView$1__fetchAddHelperData(this$, session, undefined));
    return [DiaryEditingView$1__setStartingDate(this$, patternInput[0], dateTime), patternInput[1]];
}

export function DiaryEditingView$1__InitEdit(this$, session, id) {
    return initEditWithHelperData(id, this$.getDefault(), (id_1) => DiaryEditingView$1__fetchData(this$, session, id_1), () => DiaryEditingView$1__fetchAddHelperData(this$, session, undefined));
}

export function DiaryEditingView$1__Update(this$, session, msg, model) {
    return update(session, (eventInfo, record) => {
        if (Model$2__IsEditing(model)) {
            return this$.service.Edit(eventInfo, record);
        }
        else {
            return this$.service.Add(eventInfo, record);
        }
    }, () => Router_newUrl(DiaryEditingView$1__getViewUrl_Z50E92BB6(this$, model)), msg, model);
}

export function DiaryEditingView$1__View(this$, model, dispatch) {
    const editing = Model$2__IsEditing(model);
    const title = editing ? this$.editTitle : this$.addTitle;
    return div(empty(), toList(delay(() => append(singleton(view((model_1, dispatch_1) => DiaryEditingView$1__buildForm(this$, editing, model_1, dispatch_1), title, model, dispatch)), delay(() => {
        let record;
        const currentTags = ofList(toClientListView(this$.tagsGetter(model.Record)), {
            Compare: comparePrimitives,
        });
        return singleton(renderTagTree((record = model.Record, (tag) => {
            dispatch(new Msg$2(1, [this$.tagsSetter(addTag_1(this$.tagsGetter(record), tag), record)]));
        }), toClientTreeView(defaultArg(map((data) => data.FrequentTags, model.EditHelperData), empty_1({
            Compare: comparePrimitives,
        }))), currentTags));
    })))));
}

export function DiaryEditingView$1__getRecentEntries_Z50E92BB6(this$, model) {
    return defaultArg(map((data) => data.RecentRecords, model.EditHelperData), empty());
}

export function DiaryEditingView$1__getStartingDate_Z50E92BB6(this$, model) {
    return dateToStrForDiary(date(model.EIMModel.EventInfo.EventTimestamp));
}

export function DiaryEditingView$1__setStartingDate(this$, model, dateTime) {
    let bind$0040, bind$0040_1;
    return new Model$2((bind$0040 = model.EIMModel, new Model((bind$0040_1 = model.EIMModel.EventInfo, new EventInfo(bind$0040_1.Id, bind$0040_1.ContentTypeId, bind$0040_1.ProfileId, dateTime, bind$0040_1.CreatedAt, bind$0040_1.UpdatedAt, bind$0040_1.PlaceId, bind$0040_1.PlaceName, bind$0040_1.Latitude, bind$0040_1.Longitude, bind$0040_1.LocationTz, bind$0040_1.LocationTzOffsetMin, bind$0040_1.ClientTz, bind$0040_1.ClientTzOffsetMin)), bind$0040.AutocompleteService, bind$0040.Position, bind$0040.StrictBounds)), model.Record, model.EditHelperData, model.InProgress, model.Error, model.DataExpected);
}

export function DiaryEditingView$1__getViewUrl_Z50E92BB6(this$, model) {
    return new Route(9, [DiaryEditingView$1__getStartingDate_Z50E92BB6(this$, model)]);
}

export function DiaryEditingView$1__fetchData(this$, session, id) {
    return this$.service.GetRecord(Session__GetCurrentProfileId(session), id);
}

export function DiaryEditingView$1__fetchAddHelperData(this$, session, _arg) {
    return this$.service.GetEditHelperData(Session__GetCurrentProfileId(session), PagingOptionsModule_defaultPagingOptions);
}

export function DiaryEditingView$1__buildForm(this$, editing, model, dispatch) {
    const form = FlexFormTuple$2_$ctor_5AE4FFF0((arg) => {
        dispatch(new Msg$2(0, [arg]));
    }, (arg_1) => {
        dispatch(new Msg$2(1, [arg_1]));
    }, (_arg) => {
        dispatch(new Msg$2(5, []));
    }, () => {
        Router_modifyLocation(DiaryEditingView$1__getViewUrl_Z50E92BB6(this$, model));
    });
    addEventInfoToFlexFormEim(form, model.EIMModel, (arg_3) => {
        dispatch(new Msg$2(7, [new Msg(6, [arg_3])]));
    }, getSuggestions(model.EIMModel.Position, DiaryEditingView$1__getRecentEntries_Z50E92BB6(this$, model)));
    return this$.buildFormMain(form, editing, model);
}

export const diaryEditing = DiaryEditingView$1_$ctor_6E4FAFB9(diaryService, DiaryRecord_Default, (record) => record.Tags, (newTags, record_1) => (new DiaryRecord(record_1.Id, record_1.Tagline, record_1.Notes, record_1.FollowupTime, record_1.FollowupNotes, record_1.FollowupDismissed, newTags)), "Add new diary entry", "Edit diary", (form, editing, model) => {
    FlexFormFields$1__AddTags_Z63D88B1E(FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddDateTimeOpt_C9F34CC(FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddText_Z44011C05(FlexFormTuple$2__get_FieldsSnd(form), "Tagline", validateTagline), "Notes", validateNotes), "FollowupTime", noValidation), "FollowupNotes", validateFollowupNotes), "Tags", validateTags, extractFrequentTags(model));
    if (editing) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update diary");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add diary");
    }
    return form;
});

export const reviewEditing = DiaryEditingView$1_$ctor_6E4FAFB9(reviewService, ReviewRecord_Default, (record) => record.Tags, (newTags, record_1) => (new ReviewRecord(record_1.Id, record_1.Tagline, record_1.Notes, newTags, record_1.Rating)), "Add review", "Edit review", (form, editing, model) => {
    FlexFormFields$1__AddTags_Z63D88B1E(FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddText_Z44011C05(FlexFormFields$1__AddRating_Z716E223C(FlexFormTuple$2__get_FieldsSnd(form), "Rating", validateRating), "Tagline", validateTagline), "Notes", validateNotes), "Tags", validateTags, extractFrequentTags(model));
    if (editing) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update review");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add review");
    }
    return form;
});

export const interactionEditing = DiaryEditingView$1_$ctor_6E4FAFB9(interactionService, InteractionRecord_Default, (record) => record.Tags, (newTags, record_1) => (new InteractionRecord(record_1.Id, record_1.Tagline, record_1.Participants, record_1.DurationSec, record_1.InteractionType, record_1.Notes, record_1.FollowupTime, record_1.FollowupNotes, record_1.FollowupDismissed, newTags)), "Add interaction", "Edit interaction", (form, editing, model) => {
    FlexFormFields$1__AddTags_Z63D88B1E(FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddDateTimeOpt_C9F34CC(FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddDurationSecs_ZAAC24(FlexFormFields$1__AddTextArea_Z44011C05(FlexFormFields$1__AddText_Z44011C05(FlexFormFields$1__AddSelection_Z70244BEA(FlexFormTuple$2__get_FieldsSnd(form), "InteractionType", interactionTypes, noValidation), "Tagline", validateTagline), "Participants", InteractionRecordModule_validateParticipants), "DurationSec", noValidation), "Notes", validateNotes), "FollowupTime", noValidation), "FollowupNotes", validateFollowupNotes), "Tags", validateTags, extractFrequentTags(model));
    if (editing) {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update interaction");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Add interaction");
    }
    return form;
});

