import { Fa_IconOption } from "./fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { EntryDescriptor, Actions, ModuleDescriptor, IconType } from "./Omnicv.Client.Common/ClientModule.js";
import { Route } from "./Omnicv.Client.Common/Router.js";
import { ofArray } from "./fable_modules/fable-library-js.4.22.0/List.js";
import { PhysicalModule_$reflection, PhysicalModule } from "./Omnicv.Shared/PhysicalAPI.js";
import { class_type } from "./fable_modules/fable-library-js.4.22.0/Reflection.js";

export class PhysicalClientModule extends PhysicalModule {
    constructor() {
        super();
    }
    Descriptor() {
        return new ModuleDescriptor(new IconType(new Fa_IconOption(11, ["fas fa-ambulance"])), (_arg) => (new Route(18, [])));
    }
    EntryDescriptors() {
        return ofArray([new EntryDescriptor("WeightRecord", "Weight", "Track your weight", new IconType(new Fa_IconOption(11, ["fas fa-balance-scale-left"])), new Actions(new Route(21, []), undefined, (Item) => (new Route(22, [Item])), undefined, new Route(20, [])), false), new EntryDescriptor("BloodPressureRecord", "Blood Pressure", "Track your blood pressure", new IconType(new Fa_IconOption(11, ["fas fa-heartbeat"])), new Actions(new Route(25, []), undefined, (Item_1) => (new Route(26, [Item_1])), undefined, undefined), false), new EntryDescriptor("BodilyFunctionsRecord", "Elimination", "Track your bodily functions", new IconType(new Fa_IconOption(11, ["fas fa-toilet"])), new Actions(new Route(23, []), undefined, (Item_2) => (new Route(24, [Item_2])), undefined, new Route(19, [])), true), new EntryDescriptor("TemperatureRecord", "Temperature", "Track your temperature", new IconType(new Fa_IconOption(11, ["fas fa-temperature-high"])), new Actions(new Route(27, []), undefined, (Item_3) => (new Route(28, [Item_3])), undefined, undefined), false)]);
    }
}

export function PhysicalClientModule_$reflection() {
    return class_type("Omnicv.Client.Physical.PhysicalClientModule", undefined, PhysicalClientModule, PhysicalModule_$reflection());
}

export function PhysicalClientModule_$ctor() {
    return new PhysicalClientModule();
}

