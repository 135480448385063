import { PageBoundary, PagingOptions_$reflection, EventInfo_$reflection, PagingOptions } from "../../../Omnicv.Shared.Common/CoreEventInfo.js";
import { Record, Union } from "../../../fable_modules/fable-library-js.4.22.0/Types.js";
import { class_type, option_type, int32_type, bool_type, list_type, tuple_type, record_type, union_type, string_type } from "../../../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { UnifiedRecord_$reflection } from "../../Omnicv.Diary.Shared/DiaryAPI.js";
import { curry3 } from "../../../fable_modules/fable-library-js.4.22.0/Util.js";
import { unifiedService } from "../Server.js";
import { isEmpty, ofArray, singleton as singleton_1, last as last_1, empty, length } from "../../../fable_modules/fable-library-js.4.22.0/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Icons, Session__GetCurrentProfileId } from "../../../Omnicv.Client.Common/SharedView.js";
import { exnToDisplayString } from "../../../Omnicv.Client.Common/Utils.js";
import { isNullOrWhiteSpace } from "../../../fable_modules/fable-library-js.4.22.0/String.js";
import { FlexFormBase$1__Render_2B595, FlexFormBase$1__SetState, FlexForm$1__get_Fields, FlexFormFields$1__AddText_Z44011C05, FlexForm$1_$ctor_2ABDDB55 } from "../../../Omnicv.Client.Common/Components/FlexForm.js";
import { empty as empty_1, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.22.0/Seq.js";
import { Option, h3, h1 } from "../../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { Option as Option_1, button, list } from "../../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Color_IColor } from "../../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { icon } from "../../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../../Omnicv.Client.Common/SharedView.js";
import { createElement } from "react";
import { reactApi } from "../../../fable_modules/Feliz.2.9.0/Interop.fs.js";
import { defaultOf } from "../../../fable_modules/fable-library-js.4.22.0/Util.js";
import { displayUnifiedEntries } from "../DiaryClientUtils.js";

const pageSize = 30;

const initPagingOptions = new PagingOptions(undefined, pageSize, false, undefined, undefined);

export class SearchType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ByTags", "General"];
    }
}

export function SearchType_$reflection() {
    return union_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.SearchType", [], SearchType, () => [[["Item", string_type]], []]);
}

export class SearchQuery extends Record {
    constructor(Query) {
        super();
        this.Query = Query;
    }
}

export function SearchQuery_$reflection() {
    return record_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.SearchQuery", [], SearchQuery, () => [["Query", string_type]]);
}

export class Model extends Record {
    constructor(SearchType, SearchQuery, SearchResults, IsLoading, PagingOptions, TotalCount, Error$) {
        super();
        this.SearchType = SearchType;
        this.SearchQuery = SearchQuery;
        this.SearchResults = SearchResults;
        this.IsLoading = IsLoading;
        this.PagingOptions = PagingOptions;
        this.TotalCount = (TotalCount | 0);
        this.Error = Error$;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.Model", [], Model, () => [["SearchType", SearchType_$reflection()], ["SearchQuery", SearchQuery_$reflection()], ["SearchResults", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))], ["IsLoading", bool_type], ["PagingOptions", PagingOptions_$reflection()], ["TotalCount", int32_type], ["Error", option_type(string_type)]]);
}

export function Model__FetchFunc_Z524259A4(this$, profileId) {
    const matchValue = this$.SearchType;
    if (matchValue.tag === 0) {
        return curry3(unifiedService.SearchByTags)(profileId)(matchValue.fields[0]);
    }
    else {
        return curry3(unifiedService.SearchGeneral)(profileId)(this$.SearchQuery.Query);
    }
}

export function Model__CanLoadMore(this$) {
    if (length(this$.SearchResults) > 0) {
        return length(this$.SearchResults) === this$.PagingOptions.PageSize;
    }
    else {
        return false;
    }
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RecordUpdated", "Submit", "Reload", "LoadMore", "Success", "SuccessMore", "Failure"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.Msg", [], Msg, () => [[["Item", SearchQuery_$reflection()]], [], [], [], [["Item", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]], [["Item", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]], [["Item", class_type("System.Exception")]]]);
}

export function init(session, searchType) {
    if (searchType.tag === 1) {
        return [new Model(searchType, new SearchQuery(""), empty(), false, initPagingOptions, 0, undefined), Cmd_none()];
    }
    else {
        const model = new Model(searchType, new SearchQuery(""), empty(), true, initPagingOptions, 0, undefined);
        return [model, Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, Model__FetchFunc_Z524259A4(model, Session__GetCurrentProfileId(session)), model.PagingOptions, (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(6, [Item_1])))];
    }
}

export function update(session, msg, model) {
    const processSuccess = (finalRecords) => [new Model(model.SearchType, model.SearchQuery, finalRecords, false, model.PagingOptions, model.TotalCount + length(finalRecords), undefined), Cmd_none()];
    switch (msg.tag) {
        case 1: {
            const updatedModel = new Model(model.SearchType, model.SearchQuery, model.SearchResults, true, initPagingOptions, 0, model.Error);
            return [updatedModel, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, Model__FetchFunc_Z524259A4(updatedModel, Session__GetCurrentProfileId(session)), updatedModel.PagingOptions, (Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(6, [Item_1])))];
        }
        case 3: {
            const profileId_1 = Session__GetCurrentProfileId(session) | 0;
            const last = last_1(model.SearchResults)[0];
            let pagingOptions;
            const bind$0040 = model.PagingOptions;
            pagingOptions = (new PagingOptions(new PageBoundary(last.Id, last.EventTimestamp), bind$0040.PageSize, bind$0040.Ascending, bind$0040.From, bind$0040.To));
            return [new Model(model.SearchType, model.SearchQuery, model.SearchResults, true, pagingOptions, model.TotalCount, model.Error), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, Model__FetchFunc_Z524259A4(model, profileId_1), pagingOptions, (Item_2) => (new Msg(5, [Item_2])), (Item_3) => (new Msg(6, [Item_3])))];
        }
        case 2:
            return [new Model(model.SearchType, model.SearchQuery, model.SearchResults, true, initPagingOptions, 0, undefined), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, Model__FetchFunc_Z524259A4(model, Session__GetCurrentProfileId(session)), initPagingOptions, (Item_4) => (new Msg(4, [Item_4])), (Item_5) => (new Msg(6, [Item_5])))];
        case 4:
            return processSuccess(msg.fields[0]);
        case 5:
            return processSuccess(msg.fields[0]);
        case 6:
            return [new Model(model.SearchType, model.SearchQuery, model.SearchResults, false, model.PagingOptions, model.TotalCount, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default:
            return [new Model(model.SearchType, msg.fields[0], model.SearchResults, model.IsLoading, model.PagingOptions, model.TotalCount, model.Error), Cmd_none()];
    }
}

function _validateSearchQuery(query) {
    if (isNullOrWhiteSpace(query) ? true : (query.trim().length < 3)) {
        return "Search query too short";
    }
    else {
        return undefined;
    }
}

function viewForm(model, dispatch) {
    const form = FlexForm$1_$ctor_2ABDDB55((arg) => {
        dispatch(new Msg(0, [arg]));
    }, (_arg) => {
        dispatch(new Msg(1, []));
    }, undefined);
    FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "Query", _validateSearchQuery, "Search query");
    FlexFormBase$1__SetState(form, model.IsLoading, model.Error);
    return FlexFormBase$1__Render_2B595(form, model.SearchQuery);
}

export function view(model, dispatch) {
    const elms = toList(delay(() => append((model.TotalCount > 0) ? append(singleton(h1(empty())(singleton_1("Search results"))), delay(() => append(singleton(h3(singleton_1(new Option(6, [])))(singleton_1(`${length(model.SearchResults)} of ${model.TotalCount} records loaded`))), delay(() => singleton(list(empty(), toList(delay(() => append(Model__CanLoadMore(model) ? singleton(button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(16, [model.IsLoading]), new Option_1(18, [(_arg) => {
        dispatch(new Msg(3, []));
    }])]), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(7, []))), []))), createElement("span", {
        children: reactApi.Children.toArray(["Load more"]),
    })]))) : empty_1(), delay(() => (!isEmpty(model.SearchResults) ? singleton(button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(16, [model.IsLoading]), new Option_1(18, [(_arg_1) => {
        dispatch(new Msg(2, []));
    }])]), ofArray([icon(empty(), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(5, []))), []))), createElement("span", {
        children: reactApi.Children.toArray(["Reload"]),
    })]))) : empty_1()))))))))))) : empty_1(), delay(() => append((model.SearchType.tag === 1) ? singleton(viewForm(model, dispatch)) : singleton(defaultOf()), delay(() => {
        let children_4;
        return singleton((children_4 = displayUnifiedEntries(model.SearchResults, model.IsLoading, (value) => {
        }), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        })));
    }))))));
    return createElement("div", {
        className: "container",
        children: reactApi.Children.toArray(Array.from(elms)),
    });
}

