import { map, defaultArg } from "../../fable_modules/fable-library-js.4.22.0/Option.js";
import { empty } from "../../fable_modules/fable-library-js.4.22.0/List.js";
import { fitnessService } from "./FitnessCommon.js";
import { class_type } from "../../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { Msg$2, view, update, Model$2__IsEditing, initEdit, initNew } from "../../Omnicv.Client.Common/Components/AddEditRecordFlexFormBase.js";
import { validateNotes, WorkoutRecord_Default_Z524259C1 } from "../../Omnicv.Shared/FitnessAPI.js";
import { fromInt32, toInt64 } from "../../fable_modules/fable-library-js.4.22.0/BigInt.js";
import { tryUpdateLocationFromSuggestions } from "../../Omnicv.Client.Common/Components/EventInfoManager.js";
import { FlexFormBase$1__set_SubmitCaption_Z721C83C5, noValidation, FlexFormFields$1__AddDateTimeOpt_C9F34CC, FlexFormTuple$2__get_FieldsSnd, FlexFormFields$1__AddTextAreaOpt_Z44636648, addEventInfoToFlexForm, FlexFormTuple$2_$ctor_5AE4FFF0, getSuggestions } from "../../Omnicv.Client.Common/Components/FlexForm.js";
import { singleton } from "../../fable_modules/fable-library-js.4.22.0/AsyncBuilder.js";
import { Router_modifyLocation, Route, Router_newUrl } from "../../Omnicv.Client.Common/Router.js";
import { Session__GetCurrentProfileId } from "../../Omnicv.Client.Common/SharedView.js";

export function Workout_getEditInfos(model) {
    return defaultArg(map((x) => x.WorkoutRecords, model.EditHelperData), empty());
}

export class Workout_WorkoutEditView {
    constructor() {
        this.service = fitnessService;
    }
}

export function Workout_WorkoutEditView_$reflection() {
    return class_type("Omnicv.Client.Pages.Fitness.AddEditWorkoutRecord.Workout.WorkoutEditView", undefined, Workout_WorkoutEditView);
}

export function Workout_WorkoutEditView_$ctor() {
    return new Workout_WorkoutEditView();
}

export function Workout_WorkoutEditView__InitNew(this$, session, workoutId) {
    return initNew(WorkoutRecord_Default_Z524259C1(workoutId), () => Workout_WorkoutEditView__fetchAddHelperData(this$, workoutId, session, undefined));
}

export function Workout_WorkoutEditView__InitEdit(this$, session, id) {
    return initEdit(id, WorkoutRecord_Default_Z524259C1(toInt64(fromInt32(0))), (id_1) => Workout_WorkoutEditView__fetchData(this$, session, id_1));
}

export function Workout_WorkoutEditView__Update(this$, session, msg, model) {
    if (!Model$2__IsEditing(model)) {
        tryUpdateLocationFromSuggestions(() => getSuggestions(model.EIMModel.Position, Workout_getEditInfos(model)), model.EIMModel);
    }
    return update(session, (eventInfo, record) => {
        if (Model$2__IsEditing(model)) {
            return this$.service.EditWorkoutRecord(eventInfo, record);
        }
        else {
            return singleton.Delay(() => singleton.Bind(this$.service.StartActiveWorkoutRecord(eventInfo, record), (_arg) => singleton.Return(_arg[0])));
        }
    }, () => Router_newUrl(Workout_WorkoutEditView__getRoute_4B9C34E5(this$, model)), msg, model);
}

export function Workout_WorkoutEditView__View(this$, model, dispatch) {
    const editing = Model$2__IsEditing(model);
    return view((model_1, dispatch_1) => Workout_WorkoutEditView__buildForm(this$, editing, model_1, dispatch_1), editing ? "Update workout record" : "Start workout record", model, dispatch);
}

export function Workout_WorkoutEditView__fetchData(this$, session, id) {
    return this$.service.GetWorkoutRecord(Session__GetCurrentProfileId(session), id);
}

export function Workout_WorkoutEditView__fetchAddHelperData(this$, workoutId, session, _arg) {
    return fitnessService.GetWorkoutRecords(Session__GetCurrentProfileId(session), workoutId);
}

export function Workout_WorkoutEditView__getRoute_4B9C34E5(this$, model) {
    return new Route(38, [model.Record.WorkoutId]);
}

export function Workout_WorkoutEditView__buildForm(this$, editing, model, dispatch) {
    const form = FlexFormTuple$2_$ctor_5AE4FFF0((arg) => {
        dispatch(new Msg$2(0, [arg]));
    }, (arg_1) => {
        dispatch(new Msg$2(1, [arg_1]));
    }, (_arg) => {
        dispatch(new Msg$2(5, []));
    }, () => {
        Router_modifyLocation(Workout_WorkoutEditView__getRoute_4B9C34E5(this$, model));
    });
    addEventInfoToFlexForm(form, model.EIMModel.EventInfo, getSuggestions(model.EIMModel.Position, Workout_getEditInfos(model)));
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormTuple$2__get_FieldsSnd(form), "Notes", validateNotes);
    if (editing) {
        FlexFormFields$1__AddDateTimeOpt_C9F34CC(FlexFormTuple$2__get_FieldsSnd(form), "FinishTime", noValidation);
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Update workout record");
    }
    else {
        FlexFormBase$1__set_SubmitCaption_Z721C83C5(form, "Start workout record");
    }
    return form;
}

export const workoutRecordEditor = Workout_WorkoutEditView_$ctor();

