import { Union } from "../../fable-library-js.4.22.0/Types.js";
import { Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Color_ofColor, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Color_IColor_$reflection, Size_ISize_$reflection } from "../Common.fs.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library-js.4.22.0/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library-js.4.22.0/MapUtil.js";
import { some } from "../../fable-library-js.4.22.0/Option.js";
import { Reflection_getCaseName } from "../Common.fs.js";
import { cons } from "../../fable-library-js.4.22.0/List.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Size", "Color", "is-delete", "is-light", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Tag.Option", [], Option, () => [[["Item", Size_ISize_$reflection()]], [["Item", Color_IColor_$reflection()]], [], [], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <span class="tag"></span>
 */
export function tag(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        let matchResult, size, color, props, customClass, modifiers;
        switch (option.tag) {
            case 2:
            case 3: {
                matchResult = 2;
                break;
            }
            case 1: {
                matchResult = 3;
                color = option.fields[0];
                break;
            }
            case 4: {
                matchResult = 4;
                props = option.fields[0];
                break;
            }
            case 5: {
                matchResult = 5;
                customClass = option.fields[0];
                break;
            }
            case 6: {
                matchResult = 6;
                modifiers = option.fields[0];
                break;
            }
            default:
                if (option.fields[0].tag === 0) {
                    matchResult = 0;
                }
                else {
                    matchResult = 1;
                    size = option.fields[0];
                }
        }
        switch (matchResult) {
            case 0: {
                console.warn(some("`is-small` is not a valid size for the tag element"));
                return result;
            }
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(size));
            case 2:
                return Common_GenericOptions__AddCaseName_1505(result, option);
            case 3:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(color));
            case 4:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, props);
            case 5:
                return Common_GenericOptions__AddClass_Z721C83C5(result, customClass);
            default:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, modifiers);
        }
    }, "tag"), (props_1, children_1) => react.createElement("span", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <span class="tag is-delete"></span>
 */
export function delete$(options, children) {
    return tag(cons(new Option(2, []), options), children);
}

export class List_Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["has-addons", "is-centered", "is-right", "are-small", "are-medium", "are-large", "Props", "CustomClass", "Modifiers"];
    }
}

export function List_Option_$reflection() {
    return union_type("Fulma.Tag.List.Option", [], List_Option, () => [[], [], [], [], [], [], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="tags"></div>
 */
export function list(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        switch (option.tag) {
            case 6:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 7:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            case 8:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddCaseName_1505(result, option);
        }
    }, "tags"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

