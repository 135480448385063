import { Constraints_isValidCurrencyOpt, Constraints_isValidCurrency, Constraints_greaterThanConstraint, Constraints_minConstraint, Constraints_minMaxOptConstraint, Constraints_minMaxOptLengthConstraint, Constraints_collectConstraintsErrors, Constraints_applyConstraintsGetErrors, Constraints_minMaxLengthConstraint } from "../Omnicv.Shared.Common/Validation.js";
import { head, isEmpty, append, sum, filter as filter_2, map, forAll, empty, ofArray, singleton } from "../fable_modules/fable-library-js.4.22.0/List.js";
import { FSharpMap__get_Count, FSharpMap__ContainsKey, FSharpMap__get_Item, ofList, tryFind } from "../fable_modules/fable-library-js.4.22.0/Map.js";
import { op_Addition, get_MinusOne, get_One, op_Division, op_Multiply, op_UnaryNegation, get_Zero, fromParts } from "../fable_modules/fable-library-js.4.22.0/Decimal.js";
import Decimal from "../fable_modules/fable-library-js.4.22.0/Decimal.js";
import { parse, compare } from "../fable_modules/fable-library-js.4.22.0/Date.js";
import { defaultArg, value as value_10 } from "../fable_modules/fable-library-js.4.22.0/Option.js";
import { Union, Record } from "../fable_modules/fable-library-js.4.22.0/Types.js";
import { array_type, uint8_type, union_type, unit_type, list_type, tuple_type, lambda_type, bool_type, class_type, option_type, enum_type, int32_type, int64_type, record_type, decimal_type, string_type } from "../fable_modules/fable-library-js.4.22.0/Reflection.js";
import { printf, toFail } from "../fable_modules/fable-library-js.4.22.0/String.js";
import { curry2, bigintHash, compare as compare_1, structuralHash, equals, comparePrimitives, compareArrays } from "../fable_modules/fable-library-js.4.22.0/Util.js";
import { DataPoint_$reflection, PagingOptions_$reflection, EventInfo_$reflection } from "../Omnicv.Shared.Common/CoreEventInfo.js";
import { exists } from "../fable_modules/fable-library-js.4.22.0/Seq.js";
import { List_groupBy } from "../fable_modules/fable-library-js.4.22.0/Seq2.js";
import { compare as compare_2, equals as equals_1 } from "../fable_modules/fable-library-js.4.22.0/BigInt.js";
import { ChangelogEntry, Version, ProductInformation, Changelog, defaultWeb, defaultContacts, defaultAuthors } from "../Omnicv.Shared.Common/ProductDetails.js";

const nameConstraints = singleton((name) => ((value) => Constraints_minMaxLengthConstraint(1, 256, name, value)));

export const nameName = "Name";

function getNameErrors(name) {
    return Constraints_applyConstraintsGetErrors(nameConstraints, nameName, name);
}

export function validateName(name) {
    return tryFind(nameName, Constraints_collectConstraintsErrors(singleton(getNameErrors(name))));
}

const notesConstraints = singleton((name) => ((value) => Constraints_minMaxOptLengthConstraint(5, 1000, name, value)));

export const notesName = "Notes";

function getNotesErrors(notes) {
    return Constraints_applyConstraintsGetErrors(notesConstraints, notesName, notes);
}

export function validateNotes(notes) {
    return tryFind(notesName, Constraints_collectConstraintsErrors(singleton(getNotesErrors(notes))));
}

const instrumentConstraints = singleton((name) => ((value) => Constraints_minMaxOptLengthConstraint(1, 50, name, value)));

export const instrumentName = "Instrument";

function getInstrumentErrors(instrument) {
    return Constraints_applyConstraintsGetErrors(instrumentConstraints, instrumentName, instrument);
}

export function validateInstrument(instrument) {
    return tryFind(instrumentName, Constraints_collectConstraintsErrors(singleton(getInstrumentErrors(instrument))));
}

const interestRateConstraints = singleton((() => {
    const minValue = fromParts(1000, 0, 0, true, 0);
    const maxValue = fromParts(1000, 0, 0, false, 0);
    return (name) => ((value) => Constraints_minMaxOptConstraint(minValue, maxValue, name, value));
})());

export const interestRateName = "InterestRate";

function getInterestRateErrors(interestRate) {
    return Constraints_applyConstraintsGetErrors(interestRateConstraints, interestRateName, interestRate);
}

export function validateInterestRate(interestRate) {
    return tryFind(interestRateName, Constraints_collectConstraintsErrors(singleton(getInterestRateErrors(interestRate))));
}

const identifierConstraints = singleton((name) => ((value) => Constraints_minMaxOptLengthConstraint(1, 256, name, value)));

export const identifierName = "Identifier";

function getIdentifierErrors(identifier) {
    return Constraints_applyConstraintsGetErrors(identifierConstraints, identifierName, identifier);
}

export function validateIdentifier(identifier) {
    return tryFind(identifierName, Constraints_collectConstraintsErrors(singleton(getIdentifierErrors(identifier))));
}

const quantityConstraints = singleton((name) => ((value) => Constraints_minConstraint(get_Zero, name, value)));

export const quantityName = "Quantity";

function getQuantityErrors(quantity) {
    return Constraints_applyConstraintsGetErrors(quantityConstraints, quantityName, quantity);
}

export function validateQuantity(quantity) {
    return tryFind(quantityName, Constraints_collectConstraintsErrors(singleton(getQuantityErrors(quantity))));
}

const unitPriceConstraints = singleton((name) => ((value) => Constraints_greaterThanConstraint(get_Zero, name, value)));

export const unitPriceName = "UnitPrice";

function getUnitPriceErrors(unitPrice) {
    return Constraints_applyConstraintsGetErrors(unitPriceConstraints, unitPriceName, unitPrice);
}

export function validateUnitPrice(unitPrice) {
    return tryFind(unitPriceName, Constraints_collectConstraintsErrors(singleton(getUnitPriceErrors(unitPrice))));
}

const currencyConstraints = singleton((name) => ((value) => Constraints_isValidCurrency(name, value)));

export const currencyName = "Currency";

function getCurrencyErrors(currency) {
    return Constraints_applyConstraintsGetErrors(currencyConstraints, currencyName, currency);
}

export function validateCurrency(currency) {
    return tryFind(currencyName, Constraints_collectConstraintsErrors(singleton(getCurrencyErrors(currency))));
}

const displayCurrencyConstraints = singleton((name) => ((value) => Constraints_isValidCurrencyOpt(name, value)));

export const displayCurrencyName = "DisplayCurrency";

function getDisplayCurrencyErrors(displayCurrency) {
    return Constraints_applyConstraintsGetErrors(displayCurrencyConstraints, displayCurrencyName, displayCurrency);
}

export function validateDisplayCurrency(displayCurrency) {
    return tryFind(displayCurrencyName, Constraints_collectConstraintsErrors(singleton(getDisplayCurrencyErrors(displayCurrency))));
}

export function isActive(activeFrom, activeTo, date) {
    if ((activeFrom != null) && (compare(value_10(activeFrom), date) > 0)) {
        return false;
    }
    else {
        return !((activeTo != null) && (compare(value_10(activeTo), date) < 0));
    }
}

export class CurrencyValue extends Record {
    constructor(Currency, Value) {
        super();
        this.Currency = Currency;
        this.Value = Value;
    }
}

export function CurrencyValue_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.CurrencyValue", [], CurrencyValue, () => [["Currency", string_type], ["Value", decimal_type]]);
}

export function CurrencyValue_op_UnaryNegation_4EF17D2F(value) {
    return new CurrencyValue(value.Currency, op_UnaryNegation(value.Value));
}

export function CurrencyValue_op_Multiply_1FE6124A(value, other) {
    return new CurrencyValue(value.Currency, op_Multiply(value.Value, other));
}

export class Group extends Record {
    constructor(Id, GroupType, ProfileId, ParentId, CreatedAt, UpdatedAt, Name, Notes, Weight, DisplayCurrency) {
        super();
        this.Id = Id;
        this.GroupType = (GroupType | 0);
        this.ProfileId = (ProfileId | 0);
        this.ParentId = ParentId;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.Name = Name;
        this.Notes = Notes;
        this.Weight = (Weight | 0);
        this.DisplayCurrency = DisplayCurrency;
    }
}

export function Group_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.Group", [], Group, () => [["Id", int64_type], ["GroupType", enum_type("Omnicv.Shared.FinancialAPI.GroupType", int32_type, [["Assets", 1], ["Cashflows", 2]])], ["ProfileId", int32_type], ["ParentId", option_type(int64_type)], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["Name", string_type], ["Notes", option_type(string_type)], ["Weight", int32_type], ["DisplayCurrency", option_type(string_type)]]);
}

export function Group_Default_7F9DDECF(timestamp) {
    return new Group(0n, 1, 0, undefined, timestamp, timestamp, "", undefined, 0, undefined);
}

function Group_Validator_4FDF7BFC(record) {
    return Constraints_collectConstraintsErrors(ofArray([getNameErrors(record.Name), getNotesErrors(record.Notes), getDisplayCurrencyErrors(record.DisplayCurrency)]));
}

export function Group__Validate(this$) {
    return Group_Validator_4FDF7BFC(this$);
}

export function needsUnitPrice(assetType) {
    return assetType === 2;
}

export function isLiability(assetType) {
    switch (assetType) {
        case 0:
        case 1:
        case 2:
        case 7:
        case 9:
            return false;
        case 100:
            return true;
        default:
            return toFail(printf("Unknown account type %A"))(assetType);
    }
}

const frequencyFactors = ofList(ofArray([[[1, 5], fromParts(3650, 0, 0, false, 1)], [[2, 5], op_Division(fromParts(3650, 0, 0, false, 1), fromParts(70, 0, 0, false, 1))], [[3, 5], fromParts(120, 0, 0, false, 1)], [[4, 5], fromParts(40, 0, 0, false, 1)], [[5, 5], get_One]]), {
    Compare: compareArrays,
});

export function getConversionFactor(this$, other) {
    if (this$ === other) {
        return get_One;
    }
    else if (this$ === 5) {
        return op_Division(get_One, FSharpMap__get_Item(frequencyFactors, [other, this$]));
    }
    else if (other === 5) {
        return FSharpMap__get_Item(frequencyFactors, [this$, other]);
    }
    else {
        return op_Multiply(getConversionFactor(this$, 5), getConversionFactor(5, other));
    }
}

export function valueToFrequency(value, this$, other) {
    return CurrencyValue_op_Multiply_1FE6124A(value, getConversionFactor(this$, other));
}

export function adjustForFrequency(value, this$, other) {
    return valueToFrequency(value, this$, other);
}

export class AssetValueRecord extends Record {
    constructor(Id, AssetId, Quantity, UnitPrice, Notes) {
        super();
        this.Id = Id;
        this.AssetId = AssetId;
        this.Quantity = Quantity;
        this.UnitPrice = UnitPrice;
        this.Notes = Notes;
    }
}

export function AssetValueRecord_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.AssetValueRecord", [], AssetValueRecord, () => [["Id", int64_type], ["AssetId", int64_type], ["Quantity", decimal_type], ["UnitPrice", decimal_type], ["Notes", option_type(string_type)]]);
}

function AssetValueRecord_Validator_47F190F5(record) {
    return Constraints_collectConstraintsErrors(ofArray([getQuantityErrors(record.Quantity), getUnitPriceErrors(record.UnitPrice), getNotesErrors(record.Notes)]));
}

export function AssetValueRecord_Default() {
    return new AssetValueRecord(0n, 0n, get_Zero, get_Zero, undefined);
}

export function AssetValueRecord__Validate(this$) {
    return AssetValueRecord_Validator_47F190F5(this$);
}

export class AssetValueService extends Record {
    constructor(Add, Edit, GetRecord, GetRecords, GetRecordsForChart, DeleteRecord) {
        super();
        this.Add = Add;
        this.Edit = Edit;
        this.GetRecord = GetRecord;
        this.GetRecords = GetRecords;
        this.GetRecordsForChart = GetRecordsForChart;
        this.DeleteRecord = DeleteRecord;
    }
}

export function AssetValueService_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.AssetValueService", [], AssetValueService, () => [["Add", lambda_type(EventInfo_$reflection(), lambda_type(AssetValueRecord_$reflection(), lambda_type(bool_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [EventInfo_$reflection()]))))], ["Edit", lambda_type(EventInfo_$reflection(), lambda_type(AssetValueRecord_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [EventInfo_$reflection()])))], ["GetRecord", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [tuple_type(EventInfo_$reflection(), AssetValueRecord_$reflection())])))], ["GetRecords", lambda_type(int32_type, lambda_type(int64_type, lambda_type(PagingOptions_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), AssetValueRecord_$reflection()))]))))], ["GetRecordsForChart", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(DataPoint_$reflection())])))], ["DeleteRecord", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type])))]]);
}

export class AutomaticCashflow extends Record {
    constructor(Name, CashflowType, Quantity, Frequency, AssetId, ActiveFrom, ActiveTo, InterestRate, Currency) {
        super();
        this.Name = Name;
        this.CashflowType = (CashflowType | 0);
        this.Quantity = Quantity;
        this.Frequency = (Frequency | 0);
        this.AssetId = AssetId;
        this.ActiveFrom = ActiveFrom;
        this.ActiveTo = ActiveTo;
        this.InterestRate = InterestRate;
        this.Currency = Currency;
    }
}

export function AutomaticCashflow_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.AutomaticCashflow", [], AutomaticCashflow, () => [["Name", string_type], ["CashflowType", enum_type("Omnicv.Shared.FinancialAPI.CashflowType", int32_type, [["Income", 1], ["Expense", 2]])], ["Quantity", decimal_type], ["Frequency", enum_type("Omnicv.Shared.FinancialAPI.Frequency", int32_type, [["Daily", 1], ["Weekly", 2], ["Monthly", 3], ["Quarterly", 4], ["Yearly", 5]])], ["AssetId", option_type(int64_type)], ["ActiveFrom", option_type(class_type("System.DateTime"))], ["ActiveTo", option_type(class_type("System.DateTime"))], ["InterestRate", option_type(decimal_type)], ["Currency", string_type]]);
}

export function AutomaticCashflow__GetValue_7F9DDECF(this$, date) {
    const value = new CurrencyValue(this$.Currency, this$.Quantity);
    const matchValue = this$.CashflowType | 0;
    switch (matchValue) {
        case 1:
            return value;
        case 2:
            return CurrencyValue_op_UnaryNegation_4EF17D2F(value);
        default:
            throw new Error("Unsupported cashflow type");
    }
}

export function AutomaticCashflow__IsActive_7F9DDECF(this$, date) {
    return isActive(this$.ActiveFrom, this$.ActiveTo, date);
}

export class AssetInfo extends Record {
    constructor(Id, ProfileId, GroupId, AssetType, CreatedAt, UpdatedAt, Name, Notes, Weight, Identifier, LatestAssetValueId, Currency, Instrument, InterestRate, ActiveFrom, ActiveTo) {
        super();
        this.Id = Id;
        this.ProfileId = (ProfileId | 0);
        this.GroupId = GroupId;
        this.AssetType = (AssetType | 0);
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.Name = Name;
        this.Notes = Notes;
        this.Weight = (Weight | 0);
        this.Identifier = Identifier;
        this.LatestAssetValueId = LatestAssetValueId;
        this.Currency = Currency;
        this.Instrument = Instrument;
        this.InterestRate = InterestRate;
        this.ActiveFrom = ActiveFrom;
        this.ActiveTo = ActiveTo;
    }
}

export function AssetInfo_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.AssetInfo", [], AssetInfo, () => [["Id", int64_type], ["ProfileId", int32_type], ["GroupId", int64_type], ["AssetType", enum_type("Omnicv.Shared.FinancialAPI.AssetType", int32_type, [["Cash", 0], ["CashAccount", 1], ["FinancialInstrument", 2], ["RealEstate", 7], ["Loan", 9], ["Debt", 100]])], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["Name", string_type], ["Notes", option_type(string_type)], ["Weight", int32_type], ["Identifier", option_type(string_type)], ["LatestAssetValueId", option_type(int64_type)], ["Currency", string_type], ["Instrument", option_type(string_type)], ["InterestRate", option_type(decimal_type)], ["ActiveFrom", option_type(class_type("System.DateTime"))], ["ActiveTo", option_type(class_type("System.DateTime"))]]);
}

export function AssetInfo_Default_7F9DDECF(timestamp) {
    return new AssetInfo(0n, 0, 0n, 0, timestamp, timestamp, "", undefined, 0, undefined, undefined, "", undefined, undefined, undefined, undefined);
}

function AssetInfo_Validator_Z6FD9FBA3(record) {
    return Constraints_collectConstraintsErrors(ofArray([getNameErrors(record.Name), getNotesErrors(record.Notes), getInstrumentErrors(record.Instrument), getInterestRateErrors(record.InterestRate), getIdentifierErrors(record.Identifier), getCurrencyErrors(record.Currency)]));
}

export function AssetInfo__Validate(this$) {
    return AssetInfo_Validator_Z6FD9FBA3(this$);
}

export function AssetInfo__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, AssetInfo__Validate(this$));
}

export function AssetInfo__GetValue_Z33CCF5CA(this$, value) {
    if (value == null) {
        return new CurrencyValue(this$.Currency, get_Zero);
    }
    else {
        const value_1 = value[1];
        return new CurrencyValue(this$.Currency, op_Multiply(op_Multiply(isLiability(this$.AssetType) ? get_MinusOne : get_One, value_1.Quantity), value_1.UnitPrice));
    }
}

export function AssetInfo__GetAutomaticCashflows_Z33CCF5CA(this$, value) {
    if (value == null) {
        return empty();
    }
    else {
        const value_1 = value[1];
        const date = value[0];
        const matchValue = this$.AssetType | 0;
        switch (matchValue) {
            case 1:
                return singleton(new AutomaticCashflow(`Cashflow from ${this$.Name}`, 1, value_1.Quantity, 3, this$.Id, date, this$.ActiveTo, this$.InterestRate, this$.Currency));
            case 9:
                return empty();
            case 100:
                return empty();
            default:
                return empty();
        }
    }
}

export class CashflowInfo extends Record {
    constructor(Id, ProfileId, CashflowType, GroupId, CreatedAt, UpdatedAt, Name, Notes, Weight, Currency, Quantity, Frequency, AssetId, ActiveFrom, ActiveTo) {
        super();
        this.Id = Id;
        this.ProfileId = (ProfileId | 0);
        this.CashflowType = (CashflowType | 0);
        this.GroupId = GroupId;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.Name = Name;
        this.Notes = Notes;
        this.Weight = (Weight | 0);
        this.Currency = Currency;
        this.Quantity = Quantity;
        this.Frequency = (Frequency | 0);
        this.AssetId = AssetId;
        this.ActiveFrom = ActiveFrom;
        this.ActiveTo = ActiveTo;
    }
}

export function CashflowInfo_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.CashflowInfo", [], CashflowInfo, () => [["Id", int64_type], ["ProfileId", int32_type], ["CashflowType", enum_type("Omnicv.Shared.FinancialAPI.CashflowType", int32_type, [["Income", 1], ["Expense", 2]])], ["GroupId", int64_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["Name", string_type], ["Notes", option_type(string_type)], ["Weight", int32_type], ["Currency", string_type], ["Quantity", decimal_type], ["Frequency", enum_type("Omnicv.Shared.FinancialAPI.Frequency", int32_type, [["Daily", 1], ["Weekly", 2], ["Monthly", 3], ["Quarterly", 4], ["Yearly", 5]])], ["AssetId", option_type(int64_type)], ["ActiveFrom", option_type(class_type("System.DateTime"))], ["ActiveTo", option_type(class_type("System.DateTime"))]]);
}

export function CashflowInfo_Default_7F9DDECF(timestamp) {
    return new CashflowInfo(0n, 0, 1, 0n, timestamp, timestamp, "", undefined, 0, "", get_Zero, 3, undefined, undefined, undefined);
}

function CashflowInfo_Validator_13C44C26(record) {
    return Constraints_collectConstraintsErrors(ofArray([getNameErrors(record.Name), getNotesErrors(record.Notes), getCurrencyErrors(record.Currency)]));
}

export function CashflowInfo__Validate(this$) {
    return CashflowInfo_Validator_13C44C26(this$);
}

export function CashflowInfo__GetValue(this$) {
    const value = new CurrencyValue(this$.Currency, this$.Quantity);
    const matchValue = this$.CashflowType | 0;
    switch (matchValue) {
        case 1:
            return value;
        case 2:
            return CurrencyValue_op_UnaryNegation_4EF17D2F(value);
        default:
            throw new Error("Unsupported cashflow type");
    }
}

export function CashflowInfo__IsActive_7F9DDECF(this$, date) {
    return isActive(this$.ActiveFrom, this$.ActiveTo, date);
}

export class GroupNode extends Record {
    constructor(Data, Children) {
        super();
        this.Data = Data;
        this.Children = Children;
    }
}

export function GroupNode_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.GroupNode", [], GroupNode, () => [["Data", Group_$reflection()], ["Children", list_type(ChildNode$_$reflection())]]);
}

export class ChildNode$ extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Group", "Asset", "Cashflow"];
    }
}

export function ChildNode$_$reflection() {
    return union_type("Omnicv.Shared.FinancialAPI.ChildNode", [], ChildNode$, () => [[["Item1", GroupNode_$reflection()], ["Item2", CurrencyValue_$reflection()]], [["Item1", AssetInfo_$reflection()], ["Item2", CurrencyValue_$reflection()]], [["Item1", Cashflow_$reflection()], ["Item2", CurrencyValue_$reflection()]]]);
}

export class Cashflow extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Standard", "Automatic"];
    }
}

export function Cashflow_$reflection() {
    return union_type("Omnicv.Shared.FinancialAPI.Cashflow", [], Cashflow, () => [[["Item", CashflowInfo_$reflection()]], [["Item", AutomaticCashflow_$reflection()]]]);
}

function noneOrPred(pred, value) {
    if (value == null) {
        return true;
    }
    else {
        return pred(value_10(value));
    }
}

function stringFilter(str, filter) {
    return noneOrPred((value) => (str.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0), filter);
}

function stringsFilter(strs, filter) {
    return exists((str) => stringFilter(str, filter), strs);
}

export class EntryFilter extends Record {
    constructor(Currency, Content, AssetType, CashflowType) {
        super();
        this.Currency = Currency;
        this.Content = Content;
        this.AssetType = AssetType;
        this.CashflowType = CashflowType;
    }
}

export function EntryFilter_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.EntryFilter", [], EntryFilter, () => [["Currency", option_type(string_type)], ["Content", option_type(string_type)], ["AssetType", option_type(enum_type("Omnicv.Shared.FinancialAPI.AssetType", int32_type, [["Cash", 0], ["CashAccount", 1], ["FinancialInstrument", 2], ["RealEstate", 7], ["Loan", 9], ["Debt", 100]]))], ["CashflowType", option_type(enum_type("Omnicv.Shared.FinancialAPI.CashflowType", int32_type, [["Income", 1], ["Expense", 2]]))]]);
}

export function EntryFilter_get_Empty() {
    return new EntryFilter(undefined, undefined, undefined, undefined);
}

export function EntryFilter__Match_4FDF7BFC(this$, node) {
    return forAll((x) => x, singleton(stringsFilter([node.Name, defaultArg(node.Notes, "")], this$.Content)));
}

export function EntryFilter__Match_Z1534E064(this$, node) {
    return EntryFilter__Match_4FDF7BFC(this$, node.Data);
}

export function EntryFilter__Match_Z6FD9FBA3(this$, asset) {
    return forAll((x) => x, ofArray([stringFilter(asset.Currency, this$.Currency), stringsFilter([asset.Name, defaultArg(asset.Notes, "")], this$.Content), noneOrPred((value_1) => (value_1 === asset.AssetType), this$.AssetType)]));
}

export function EntryFilter__Match_13C44C26(this$, cashflow) {
    return forAll((x) => x, ofArray([stringFilter(cashflow.Currency, this$.Currency), stringsFilter([cashflow.Name, defaultArg(cashflow.Notes, "")], this$.Content), noneOrPred((value_1) => (value_1 === cashflow.CashflowType), this$.CashflowType)]));
}

export function EntryFilter__Match_Z2451782B(this$, cashflow) {
    return forAll((x) => x, ofArray([stringFilter(cashflow.Currency, this$.Currency), stringFilter(cashflow.Name, this$.Content), noneOrPred((value) => (value === cashflow.CashflowType), this$.CashflowType)]));
}

export function EntryFilter__Match_6E47A7A8(this$, cashflow) {
    if (cashflow.tag === 1) {
        return EntryFilter__Match_Z2451782B(this$, cashflow.fields[0]);
    }
    else {
        return EntryFilter__Match_13C44C26(this$, cashflow.fields[0]);
    }
}

export class FinancialHierarchy extends Record {
    constructor(Groups, Assets, Cashflows, LatestValues, FxRates, DisplayCurrency) {
        super();
        this.Groups = Groups;
        this.Assets = Assets;
        this.Cashflows = Cashflows;
        this.LatestValues = LatestValues;
        this.FxRates = FxRates;
        this.DisplayCurrency = DisplayCurrency;
    }
}

export function FinancialHierarchy_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.FinancialHierarchy", [], FinancialHierarchy, () => [["Groups", list_type(Group_$reflection())], ["Assets", list_type(AssetInfo_$reflection())], ["Cashflows", list_type(CashflowInfo_$reflection())], ["LatestValues", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int64_type, tuple_type(class_type("System.DateTime"), AssetValueRecord_$reflection())])], ["FxRates", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [tuple_type(string_type, string_type), decimal_type])], ["DisplayCurrency", string_type]]);
}

export function FinancialHierarchy__BuildMapTree_Z59A20369(this$, filter) {
    return ofList(map((t) => [t, FinancialHierarchy__BuildTree(this$, t, filter)], ofArray([1, 2])), {
        Compare: comparePrimitives,
    });
}

export function FinancialHierarchy__BuildTree(this$, groupType, filter) {
    const hasFilter = !equals(filter, EntryFilter_get_Empty());
    const groupMapping = ofList(List_groupBy((group_1) => group_1.ParentId, filter_2((cashflow) => EntryFilter__Match_4FDF7BFC(filter, cashflow), filter_2((group) => (group.GroupType === groupType), this$.Groups)), {
        Equals: equals,
        GetHashCode: structuralHash,
    }), {
        Compare: compare_1,
    });
    const assetMappings = ofList(List_groupBy((asset) => asset.GroupId, filter_2((cashflow_1) => EntryFilter__Match_Z6FD9FBA3(filter, cashflow_1), this$.Assets), {
        Equals: equals_1,
        GetHashCode: bigintHash,
    }), {
        Compare: compare_2,
    });
    const cashflowMappings = ofList(List_groupBy((cashflow_3) => cashflow_3.GroupId, filter_2((cashflow_2) => EntryFilter__Match_13C44C26(filter, cashflow_2), this$.Cashflows), {
        Equals: equals_1,
        GetHashCode: bigintHash,
    }), {
        Compare: compare_2,
    });
    const sumCurrencyValues = (targetCurrency, values) => sum(map((value) => {
        if (targetCurrency === value.Currency) {
            return value.Value;
        }
        else {
            return op_Multiply(value.Value, FSharpMap__get_Item(this$.FxRates, [value.Currency, targetCurrency]));
        }
    }, values), {
        GetZero: () => (new Decimal("0")),
        Add: op_Addition,
    });
    const build = (parentDisplayCurrency, groupNode) => {
        let group_2, matchValue, list_12;
        const group_3 = groupNode.Data;
        const displayCurrency = defaultArg(group_3.DisplayCurrency, parentDisplayCurrency);
        const children = append((group_2 = group_3, (matchValue = (group_2.GroupType | 0), (matchValue === 1) ? map((asset_1) => (new ChildNode$(1, [asset_1, AssetInfo__GetValue_Z33CCF5CA(asset_1, tryFind(asset_1.Id, this$.LatestValues))])), FSharpMap__ContainsKey(assetMappings, group_2.Id) ? FSharpMap__get_Item(assetMappings, group_2.Id) : empty()) : ((matchValue === 2) ? map((cashflow_4) => (new ChildNode$(2, [new Cashflow(0, [cashflow_4]), adjustForFrequency(CashflowInfo__GetValue(cashflow_4), cashflow_4.Frequency, 3)])), FSharpMap__ContainsKey(cashflowMappings, group_2.Id) ? FSharpMap__get_Item(cashflowMappings, group_2.Id) : empty()) : toFail(printf("Unsupported group type %A"))(matchValue)))), map((tupledArg_1) => (new ChildNode$(0, [tupledArg_1[0], tupledArg_1[1]])), filter_2((tupledArg) => !(isEmpty(tupledArg[0].Children) && hasFilter), (list_12 = map((group_4) => (new GroupNode(group_4, empty())), FSharpMap__ContainsKey(groupMapping, group_3.Id) ? FSharpMap__get_Item(groupMapping, group_3.Id) : empty()), map(curry2(build)(displayCurrency), list_12)))));
        return [new GroupNode(groupNode.Data, children), new CurrencyValue(displayCurrency, sumCurrencyValues(displayCurrency, map((child) => {
            switch (child.tag) {
                case 1:
                    return child.fields[1];
                case 2:
                    return child.fields[1];
                default:
                    return child.fields[1];
            }
        }, children)))];
    };
    let finalTL;
    const list_17 = map((group_5) => (new GroupNode(group_5, empty())), (FSharpMap__get_Count(groupMapping) > 0) ? FSharpMap__get_Item(groupMapping, undefined) : empty());
    finalTL = map(curry2(build)(this$.DisplayCurrency), list_17);
    return [finalTL, new CurrencyValue(this$.DisplayCurrency, sumCurrencyValues(this$.DisplayCurrency, map((tuple) => tuple[1], finalTL)))];
}

export class FinancialService extends Record {
    constructor(GetHierarchy, ImportHierarchyData, GetAssetValuesForChart, AddGroup, EditGroup, DeleteGroup, AddAsset, EditAsset, DeleteAsset, GetAsset, AddCashflow, EditCashflow, DeleteCashflow) {
        super();
        this.GetHierarchy = GetHierarchy;
        this.ImportHierarchyData = ImportHierarchyData;
        this.GetAssetValuesForChart = GetAssetValuesForChart;
        this.AddGroup = AddGroup;
        this.EditGroup = EditGroup;
        this.DeleteGroup = DeleteGroup;
        this.AddAsset = AddAsset;
        this.EditAsset = EditAsset;
        this.DeleteAsset = DeleteAsset;
        this.GetAsset = GetAsset;
        this.AddCashflow = AddCashflow;
        this.EditCashflow = EditCashflow;
        this.DeleteCashflow = DeleteCashflow;
    }
}

export function FinancialService_$reflection() {
    return record_type("Omnicv.Shared.FinancialAPI.FinancialService", [], FinancialService, () => [["GetHierarchy", lambda_type(int32_type, lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [FinancialHierarchy_$reflection()])))], ["ImportHierarchyData", lambda_type(int32_type, lambda_type(array_type(uint8_type), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type])))], ["GetAssetValuesForChart", lambda_type(int32_type, lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(DataPoint_$reflection())])))], ["AddGroup", lambda_type(Group_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int64_type]))], ["EditGroup", lambda_type(Group_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Group_$reflection()]))], ["DeleteGroup", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type])))], ["AddAsset", lambda_type(AssetInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int64_type]))], ["EditAsset", lambda_type(AssetInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [AssetInfo_$reflection()]))], ["DeleteAsset", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type])))], ["GetAsset", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [AssetInfo_$reflection()])))], ["AddCashflow", lambda_type(CashflowInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [int64_type]))], ["EditCashflow", lambda_type(CashflowInfo_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [CashflowInfo_$reflection()]))], ["DeleteCashflow", lambda_type(int32_type, lambda_type(int64_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type])))]]);
}

export class FinancialModule {
    constructor() {
        this.initialTimestamp = parse("2021-12-14T21:51:00");
        this.latestTimestamp = this.initialTimestamp;
    }
    get Details() {
        const this$ = this;
        return new ProductInformation("Omnicv.Financial", this$.initialTimestamp, this$.latestTimestamp, "Financial", "Track your financial data: expenses, wealth, pensions etc.", empty(), FinancialModule__get_CurrentVersion(this$), singleton(defaultAuthors), defaultContacts, defaultWeb, undefined, undefined, new Changelog(1, [FinancialModule__get_Changelog(this$)]));
    }
    RegisteredTypes() {
        return singleton(AssetValueRecord_$reflection());
    }
}

export function FinancialModule_$reflection() {
    return class_type("Omnicv.Shared.FinancialAPI.FinancialModule", undefined, FinancialModule);
}

export function FinancialModule_$ctor() {
    return new FinancialModule();
}

export function FinancialModule__get_Changelog(this$) {
    return singleton(new ChangelogEntry(this$.initialTimestamp, new Version(1, 0, 1, undefined), "Initial release"));
}

export function FinancialModule__get_CurrentVersion(this$) {
    return head(FinancialModule__get_Changelog(this$)).Version;
}

